import React, { useEffect, useState, SVGProps, useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { getFormatterFunction } from 'dct-component-library/utils/formatterMapping';
import styled from '@emotion/styled'; // Import styled from emotion

type AxisInterval = number | 'preserveStart' | 'preserveEnd' | 'preserveStartEnd' | 'equidistantPreserveStart';
type CurveType = 'linear' | 'smooth';

interface DataItem {
    [key: string]: number;
}

interface AxisConfig {
    key: string;
    startLabel?: string | number;
    endLabel?: string | number;
    showLine?: boolean | SVGProps<SVGLineElement>;
    showTickLine?: boolean;
    interval?: AxisInterval;
    tickMargin?: number;
    prefix?: string; // Prop to add a prefix to the value
    suffix?: string; // Prop to add a suffix to the value
    formatterKey?: string; // Key to identify the formatter function
    showAllLabels?: boolean; // Prop to show all labels
}

interface GridConfig {
    strokeColor?: string;
    strokeDashArray?: string;
}

interface LineConfig {
    curveType?: CurveType;
    dataKey: string;
    strokeColor?: string;
    strokeWidth?: number;
    showDots?: boolean;
    strokeDashArray?: string;
    colorBelowThreshold?: string; // Color for line below threshold
    colorAboveThreshold?: string; // Color for line above threshold
}

interface ReferenceLineConfig {
    position?: number;
    strokeColor?: string;
    strokeDashArray?: string;
}

interface TrendChartProps {
    data: DataItem[];
    dimensions: {
        width: string | number;
        height?: string | number;
    };
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
    xAxis: AxisConfig;
    yAxis: AxisConfig;
    grid?: GridConfig;
    line: LineConfig;
    maxLimitReferenceLine?: ReferenceLineConfig;
    thresholdReferenceLine?: ReferenceLineConfig;
    showAllLabels?: boolean;
    tooltipContent?: string;
}

const defaultColors = Object.freeze({
    green: '#2CC84D',
    red: '#E01029',
    blue: '#0063C3', // Default to blue
    black: '#00000026',
    lightGray: '#cccccc'
});

const xAxisConfig: AxisConfig = {
    key: 'name',
    showLine: { stroke: defaultColors.black },
    showTickLine: false,
    interval: 0,
    formatterKey: 'defaultAxisLabelFormatter',
    showAllLabels: false // Default to true
};

const yAxisConfig: AxisConfig = {
    key: 'value',
    showLine: false,
    showTickLine: false,
    tickMargin: 12,
    startLabel: 0,
    endLabel: 100,
    interval: 0,
    formatterKey: 'defaultAxisLabelFormatter',
    showAllLabels: false // Default to true
};

const defaultMargin = {
    top: 16,
    bottom: 4,
    left: 0,
    right: 16,
};

const defaultDimensions = {
    width: '100%', // Reduce the width of the graph
    height: '100%',
};

const defaultLineConfig: LineConfig = {
    curveType: 'linear',
    dataKey: yAxisConfig.key,
    strokeColor: defaultColors.blue,
    strokeWidth: 2,
    showDots: false,
    colorBelowThreshold: defaultColors.green,
    colorAboveThreshold: defaultColors.red
};

const defaultThresholdReferenceLine: ReferenceLineConfig = {
    strokeColor: defaultColors.black,
    strokeDashArray: '3 3',
};

const defaultMaxLimitReferenceLine: ReferenceLineConfig = {
    strokeColor: defaultColors.black
};

const TipHeaderDesign = styled.div`
    font-weight: 700;
    font-size: 12px;
    font-family: 'Inter';
    line-height: 18px;
    color: #1F1F1F;
`;

const TipContentDesign = styled.div`
    font-weight: 400;
    font-size: 12px;
    font-family: 'Inter';
    line-height: 18px;
    color: #1F1F1F;
`;

const StyledTrendLineGraph: React.FC<TrendChartProps> = ({
    data,
    dimensions = defaultDimensions,
    margin = defaultMargin,
    xAxis = xAxisConfig,
    yAxis = yAxisConfig,
    grid,
    line = defaultLineConfig,
    maxLimitReferenceLine = defaultMaxLimitReferenceLine,
    thresholdReferenceLine = defaultThresholdReferenceLine,
    tooltipContent,
}) => {

    const xAxisFormatterFunction = getFormatterFunction(xAxis.formatterKey ?? 'defaultAxisLabelFormatter');
    const yAxisFormatterFunction = getFormatterFunction(yAxis.formatterKey ?? 'defaultAxisLabelFormatter');

    const getVisualMapPieces = (thresholdReferenceLine: any, line: any, defaultColors: any,maxLimitReferenceLine: any) => {
        if (line?.dataKey === "adherence_rate" && maxLimitReferenceLine?.position > 100) {
            return [
                {
                    gt: Number.MIN_SAFE_INTEGER, // Use the highest negative number
                    lte: Number(thresholdReferenceLine?.position) - 1, // Color below threshold
                    color: line?.colorBelowThreshold ?? defaultColors.green
                },
                {
                    gte: Number(thresholdReferenceLine?.position), // Color above threshold
                    lte: Number.MAX_SAFE_INTEGER, // Use the highest positive number
                    color: line?.colorAboveThreshold ?? defaultColors.red
                }
            ];
        }
        
        return [
            {
                gt: Number.MIN_SAFE_INTEGER, // Use the highest negative number
                lte: Number(thresholdReferenceLine?.position) - 1, // Color below threshold
                color: line?.colorBelowThreshold ?? defaultColors.red
            },
            {
                gte: Number(thresholdReferenceLine?.position), // Color above threshold
                lte: Number.MAX_SAFE_INTEGER, // Use the highest positive number
                color: line?.colorAboveThreshold ?? defaultColors.green
            }
        ];
    };

    useEffect(() => {
        const handleScroll = () => {
            const tooltipElement = document.querySelector('.echarts-tooltip');
            if (tooltipElement) {
                (tooltipElement as HTMLElement).style.display = 'none';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const chartOptions = useMemo(() => {
        const options: any = {
            grid: {
                top: margin.top,
                bottom: margin.bottom,
                left: margin.left,
                right: margin.right,
                containLabel: true,
                show: false, // Hide grid lines
            },
            xAxis: {
                type: 'category',
                data: data.map(item => (item as any)[xAxis.key] ?? item.name),
                boundaryGap: true, // Ensure the graph starts from the beginning of the x-axis
                axisLine: {
                    show: xAxis.showLine,
                },
                axisTick: {
                    show: xAxis.showTickLine,
                },
                axisLabel: {
                    interval: 0, // Ensure all labels are processed
                    align: 'center', // Align labels to the center
                    textStyle: {
                        fill: '#000000',
                        fontSize: 10,
                        fontWeight: 700,
                        lineHeight: 14,
                        textTransform: 'uppercase',
                    },
                    formatter: (value: any) => {
                        const formattedValue = xAxisFormatterFunction(value, xAxis?.startLabel, xAxis?.endLabel, xAxis?.prefix, xAxis?.suffix, xAxis?.showAllLabels);
                        return formattedValue.toUpperCase(); // Capitalize the xAxis labels
                    }
                },
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: yAxis.showLine,
                },
                axisTick: {
                    show: yAxis.showTickLine,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: 'solid',
                        color: '#00000026',
                    },
                    interval: 0
                },
                interval: !yAxis.showAllLabels ? yAxis.endLabel : 0,
                axisLabel: {
                    formatter: (value: any, index: number) => {
                        const formattedValue = yAxisFormatterFunction(value, yAxis?.startLabel, yAxis?.endLabel, yAxis?.prefix, yAxis?.suffix, yAxis?.showAllLabels, index, data?.length);
                        return formattedValue;
                    },
                    textStyle: {
                        fill: '#000000',
                        fontSize: 10,
                        fontWeight: 700,
                        lineHeight: 14,
                        textTransform: 'uppercase'
                    },
                    align: 'left', // Align labels to the left
                    padding: [0, 0, 0, -24], // Further reduce padding to the right
                },
                max: yAxis.endLabel ?? 100, // Ensure endLabel is shown even if higher than max value
            },
            series: [
                {
                    type: 'line',
                    data: data.map(item => item[line.dataKey as keyof DataItem] ?? item.value),
                    smooth: line.curveType === 'smooth',
                    lineStyle: {
                        width: 1,
                        type: line.strokeDashArray ? 'dashed' : 'solid',
                    },
                    showSymbol: line.showDots ?? false,
                },
                {
                    type: 'line',
                    markLine: {
                        silent: true,
                        symbol: 'none',
                        label: {
                            show: false, // Hide the label
                        },
                        data: [
                            ...(thresholdReferenceLine?.position ? [{
                                yAxis: thresholdReferenceLine?.position,
                                lineStyle: {
                                    type: 'dashed',
                                    color: thresholdReferenceLine?.strokeColor ?? defaultColors.black,
                                },
                                symbol: 'none', // Remove the arrow
                            }] : []),
                            {
                                yAxis: yAxis?.startLabel ?? 0,
                                lineStyle: {
                                    type: 'solid',
                                    color: defaultColors.black,
                                },
                                symbol: 'none', // Remove the arrow
                            },
                            {
                                yAxis: yAxis?.endLabel ?? 100,
                                lineStyle: {
                                    type: 'solid',
                                    color: defaultColors.black,
                                },
                                symbol: 'none', // Remove the arrow
                            },
                        ],
                    },
                },
            ],
            tooltip: {
                trigger: 'axis',
                backgroundColor: '#fff',
                borderColor: '#ccc',
                borderWidth: 1,
                textStyle: {
                    color: '#000',
                },
                extraCssText: `
                    padding: 12px;
                    border-radius: 8px;
                    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
                    white-space: normal;
                    z-index: 9999; // Set a high z-index
                `,
                renderMode: 'html',
                position: 'bottom', // Set the tooltip position to the bottom
                formatter: (params: any) => {
                    const header = `<div style="font-weight: 700; font-size: 12px; font-family: 'Inter'; line-height: 18px; color: #1F1F1F;"><strong>Trend graph</strong></div>`;
                    const customTooltip = tooltipContent ? `<div style="font-weight: 400; font-size: 12px; font-family: 'Inter'; line-height: 18px; color: #1F1F1F;">${tooltipContent}</div>` : '';
                    const thresholdInfo = thresholdReferenceLine?.position ? `<div style="font-weight: 400; font-size: 12px; font-family: 'Inter'; line-height: 18px; color: #1F1F1F;">Threshold: ${thresholdReferenceLine.position}${yAxis.suffix ?? ''}</div>` : '';
                    const dataPoints = params.map((param: any) => {
                        const value = param.data[line.dataKey as keyof DataItem] ?? param.value;
                        const formattedValue = `${value}${yAxis.suffix ?? ''}`; // Append y-axis suffix if available
                        return `<div style="font-weight: 400; font-size: 12px; font-family: 'Inter'; line-height: 18px; color: #1F1F1F;"><strong>${param.name}</strong>: ${formattedValue}</div>`;
                    }).join('');
                    return `${header}${customTooltip}${thresholdInfo}${dataPoints}`;
                },
            },
            ...(thresholdReferenceLine?.position && {
                visualMap: {
                    show: false,
                    pieces: getVisualMapPieces(thresholdReferenceLine, line, defaultColors,maxLimitReferenceLine),
                    outOfRange: {
                        color: line.strokeColor ?? defaultColors.blue
                    }
                }
            }),
        };

        return options;
    }, [data, dimensions, margin, xAxis, yAxis, grid, line, maxLimitReferenceLine, thresholdReferenceLine, xAxis.showAllLabels, xAxis?.endLabel, tooltipContent]);
    return <ReactECharts option={chartOptions} style={{ height: dimensions.height, width: dimensions.width }} />;
};

StyledTrendLineGraph.defaultProps = {
    dimensions: defaultDimensions,
    margin: defaultMargin,
    xAxis: xAxisConfig,
    yAxis: yAxisConfig,
    line: defaultLineConfig,
    thresholdReferenceLine: defaultThresholdReferenceLine,
};

export default StyledTrendLineGraph;
