import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { MAJOR_EQ_DOWNTIME_URL } from 'utils/constants';
import { StringKeyAnyDataProps } from 'utils/data-types';

interface MajorEqDowntimeDdvInfoState {
  majorEqDowntimeDdvInfo: APIResponseDataType[] | null;
  majorEqDownTimeFilterData: StringKeyAnyDataProps[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: MajorEqDowntimeDdvInfoState = {
  majorEqDowntimeDdvInfo: null,
  majorEqDownTimeFilterData: null,
  loading: false,
  error: false,
}

const fetchmajorEqDowntimeDdv = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
  MAJOR_EQ_DOWNTIME_URL,
  async (params) => {
    const filteredParams = filterParams(params);
    const token: string = getOktaToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}${MAJOR_EQ_DOWNTIME_URL}?${new URLSearchParams(filteredParams).toString()}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch Major Eq Downtime');
    }
    const data = await response.json();
    data.forEach((filter: any) => {
      if (filter.extraFilterData) {
        filter.extraFilterData = [{ line: 'All' }, ...filter.extraFilterData];
      }
    });
    return data;
  }
);

const fetchmajorEqDowntimeFilterData = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
  `${MAJOR_EQ_DOWNTIME_URL}filter`,
  async (params) => {
    const filteredParams = filterParams(params);
    const token: string = getOktaToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}${MAJOR_EQ_DOWNTIME_URL}?${new URLSearchParams(filteredParams).toString()}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch  Major Eq Downtime');
    }
    const data = await response.json();

    data.forEach((filter: any) => {
      if (filter.extraFilterData) {
        filter.extraFilterData = [{ line: 'All' }, ...filter.extraFilterData];
      }
    });
    return data;
  }
);

const handleMajorEqDowntimeFilterData = (oldState: { majorEqDowntimeDdvInfo: any[]; }, filerData: { filterType: any; data: any; }) => {
  const state = oldState.majorEqDowntimeDdvInfo
  state?.map((item: { filterType: any; data: any; }) => {
      if (filerData?.filterType === item.filterType) {
          item.data = filerData.data
      }
      return item
  });
  return state

}

const majorEqDowntimeDdvReducer = createSlice({
  name: 'majorEqDowntimeDdv',
  initialState,
  reducers: {
    clearMajorEqDowntimeDdvData(state) {
      state.majorEqDowntimeDdvInfo = null
      state.majorEqDownTimeFilterData = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchmajorEqDowntimeDdv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchmajorEqDowntimeDdv.fulfilled, (state, action) => {
        state.loading = false;
        state.majorEqDowntimeDdvInfo = action.payload
      })
      .addCase(fetchmajorEqDowntimeDdv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(fetchmajorEqDowntimeFilterData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchmajorEqDowntimeFilterData.fulfilled, (state, action) => {
        state.loading = false;
        state.majorEqDowntimeDdvInfo = handleMajorEqDowntimeFilterData(state as any, action.payload as any);
      })
      .addCase(fetchmajorEqDowntimeFilterData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
  }
})

export { fetchmajorEqDowntimeDdv, fetchmajorEqDowntimeFilterData };

export const { clearMajorEqDowntimeDdvData } = majorEqDowntimeDdvReducer.actions

export default majorEqDowntimeDdvReducer.reducer;