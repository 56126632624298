import AohTable from "../../common/table/AohTable";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Quality.scss";
import { GXPTableColumnsType, minorDeviationColumns, rftColumnsTier1 } from "utils/wctConstants";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { RadioFilters } from "components/resuable/radio-filters/RadioFiltersQuality";
import { useState, useEffect } from "react";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import HorizontalBarChart from "components/common/HorizontalBar/HorizontalBarChart";
import { downloadCSV, generateCSV } from "utils/common-methods";
import { StringKeyDataProps } from "utils/data-types";
import { title } from "process";
import Legend from "components/common/StackedBar/Legend";
import AohDropdown from "components/common/dropdown/aohdropdown";

interface QualityTier1Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const QualityTier1: React.FC<QualityTier1Props> = ({
    data: { summary, heatmapData, data, tableDataAll, tableData, tableDataAdherence, tableDataAging, tableDataOpen },
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const [minDevTableTimePeriod, setMinDevTableTimePeriod] = useState("All");
    const [filterBy, setFilterBy] = useState("All");
    const minorDeviationTableData = (
        minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Adherence")
        ? (minDevTableTimePeriod === "All"
            ? tableDataAll
            : tableDataAdherence)
        : ((minDevTableTimePeriod === "Open")
            ? tableDataOpen
            :  tableDataAging
        )
        
        const avgPendingException =  data?.graphAllData.reduce((acc: any, item: any) => item.avg_pending_exception, 0);
        
    const configRftNumberOfPendingExcp = {
        isConditionalColor: true,
        conditionalColor: (item: any) => ({
            value: item.value || 0,
            itemStyle: {
                emphasis: {
                    color: item.value > 10 ? "#E42E44" : "#0063C3",
                    borderColor: item.value > 10 ? "#E42E44" : "#0063C3",
                },
                color: item.value > 10 ? "#E42E44" : "#0063C3",
            },
        }),
        markLine: {
            symbol: "none",
            lineStyle: {
                color: "#E42E44",
                type: "solid",
            },
            data: [
                {
                    name: "AVERAGE LINE",
                    lineStyle: {
                        color: "#2FBCB6",
                        type: "dashed",
                    },
                    xAxis: avgPendingException ? avgPendingException : 0,
                },
                {
                    name: "TARGET LINE",
                    xAxis: 10,
                },
            ],
        },
        width: 1120,
        height: 340,
        xAxisKey: "value",
        yAxisKey: "name",
        hideXAxisTicks: true,
        hideYAxisTicks: true,
        showYAxisLine: false,
        xAxisLabel: "NUMBER OF PENDING EXCEPTION",
        gridBottom: 85,
        gridLeft: 30,
        gridRight: 5,
        yAxisLabel: "LINES",
        yAxisMax: 15
    };

    const rftChartLegend = [
        { statusValue: "TARGET LINE", circleColor: "#E42E44" },
        { statusValue: "NUMBER OF PENDING EXCEPTION", circleColor: "#0063C3" },
        { statusValue: "AVERAGE", circleColor: "#2FBCB6" },
    ]

    const rftFilterDropdown = [{ key: 'All', label: 'All' }, ...(tableData?.allLineFilterData ? tableData.allLineFilterData?.map((lineData: Record<string, string>) => {
        return {
            key: lineData.line,
            label: lineData.line
        }
    }) : [])];

    const qualityDeepDive: any = {
        "Minor Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            title: "Minor Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                />
            ),
            button: (
                <Button
                    className={(!minorDeviationTableData || minorDeviationTableData.length === 0) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    label="Download"
                    iconPosition="right"
                    onClick={() => {
                        if (minorDeviationTableData && minorDeviationColumns) {
                            const csvContent = generateCSV(minorDeviationTableData, minorDeviationColumns);
                            downloadCSV(csvContent, "Minor_Deviation_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={(!minorDeviationTableData || minorDeviationTableData.length === 0) ? true : false}
                />
            ),
            table: <AohTable
                tableData={minorDeviationTableData}
                tableColumns={minorDeviationColumns} />,
        },
        "GXP Training": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            heatmapTitle: "Compliance Rate (%)",
            heatmap: (
                <ColorCodedTable
                    data={heatmapData}
                    metricKey={"gxp"}
                />
            ),
            title: "Upcoming Due",
            subtitle: "In 7 days",
            button: (
                <Button
                    label="Download"
                    className={(!tableData || tableData.length === 0) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (tableData && GXPTableColumnsType) {
                            const csvContent = generateCSV(tableData, GXPTableColumnsType);
                            downloadCSV(csvContent, "unclosed_due.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={(!tableData || tableData.length === 0) ? true : false}
                />
            ),
            table: <AohTable tableData={tableData} tableColumns={GXPTableColumnsType} />,
        },
        "Right First Time": {
            title: "Number of Pending Exception in Each Line",
            component: (
                <HorizontalBarChart
                    data={data?.graphAllData ?? [] as StringKeyDataProps[]}
                    config={configRftNumberOfPendingExcp} />
            ),
            legend: (
                <Legend
                    legendItems={rftChartLegend}
                />
            ),
            title1: "Exception",
            radioFilters: (
                <div className="tier-dropdown" style={{ marginBottom: "10px" }}>
                    <AohDropdown
                        items={rftFilterDropdown}
                        title="FILTER BY "
                        dropOptions={filterBy}
                        handleMenuClick={(evt) => {
                            setFilterBy(evt.key);
                        }}
                    />
                </div>
            ),
            button1: (
                <Button
                  label="Download"
                  className="download-btn"
                  icon={<DownloadOutlined />}
                  iconPosition="right"
                  onClick={() => {
                    if (tableData && rftColumnsTier1) {
                      const csvContent = generateCSV(tableData ? tableData[filterBy] : [], rftColumnsTier1);
                      downloadCSV(csvContent, "Right First Time Data.csv");
                    } else {
                      console.error("No data or columns available for download");
                    }
                  }}
                  type="secondary"
                  disabled={((!tableData) || (tableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable tableData={tableData ? tableData[filterBy] : []} tableColumns={rftColumnsTier1} />,
        }
    }

    // keep the download button to the left of the modal window
    useEffect(() => {
        const tierButtons = document.getElementsByClassName('tier-buttons')?.[0]
        const radioFilters = qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters;

        if (tierButtons && !radioFilters && !tierButtons?.classList.contains('flex-end')) {
            tierButtons?.classList.add('flex-end');
        } else if (tierButtons && radioFilters && tierButtons?.classList.contains('flex-end')) {
            tierButtons?.classList.remove('flex-end');
        }
    }, [qualityDeepDive, activeKeyDeepDiveTab])

    return (
        <div className="quality-tier-main">
            {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle && <div className="tier-container">
                <h2 className="tier-title">
                    {/* Summary card */}
                    {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                </h2>
            </div>}
            {qualityDeepDive[activeKeyDeepDiveTab]?.summary}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                }
            ></div>
            {/* Heatmap Card */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle && <div className="tier0-container">
                <h2 className="tier0-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle}
                </h2>
            </div>}
            {/* main heatmap component */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmap}
            <div
                className={
                    (qualityDeepDive[activeKeyDeepDiveTab]?.heatmap ||
                        qualityDeepDive[activeKeyDeepDiveTab]?.table) && activeKeyDeepDiveTab !== "Right First Time"
                        ? "mr-bottom-20"
                        : ""
                }
            ></div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.title && <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button}
            </div>}
            {qualityDeepDive[activeKeyDeepDiveTab]?.component}
            {qualityDeepDive[activeKeyDeepDiveTab]?.legend}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title1 && <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title1}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button1}
            </div>}
            {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle && (
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            )}
            <div className="tier-buttons">
                {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.table}
        </div>
    );
};
export default QualityTier1;
