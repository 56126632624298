import styled from '@emotion/styled';
import { Card } from 'antd';

interface StyledCardProps {
  style?: React.CSSProperties;
}

const StyledCard = styled(Card)<StyledCardProps>`
  .ant-card-head {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: unset;
  }
  .ant-card-body {
    padding: 8px 14px;
    border-radius: 0 0 8px 8px;
    ${(props) => props.style?.minHeight && `min-height: ${props.style.minHeight};`} // Apply min-height from props.style
  }
  .ant-alert {
    margin: 0;
    border-radius: 8px 8px 0 0;
    border: none;
  }
  .ant-typography{
    margin-bottom: 0em;
    font-family: 'Inter';
  }
  ${(props) => props.style && { ...props.style }}
`;

export default StyledCard;
