import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, CHANGE_CONTROL, METRICS, QUALITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface QualityChangeControlInfoState {
  qualityChangeControlInfo: APIResponseDataType[] | null;
  status: string;
  error: boolean;
}

const initialState: QualityChangeControlInfoState = {
  qualityChangeControlInfo: null,
  status: 'idle',
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, QUALITY, BASE_VIEW, CHANGE_CONTROL];
const fetchQualityChangeControl = fetchApi(segments);

const qualityChangeControlSlice = createSlice({
    name: 'qualityChangeControl',
    initialState,
    reducers: {
        clearQualityChangeControlData(state) {
            state.qualityChangeControlInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQualityChangeControl.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchQualityChangeControl.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.qualityChangeControlInfo = action.payload
            })
            .addCase(fetchQualityChangeControl.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchQualityChangeControl }

export const { clearQualityChangeControlData } = qualityChangeControlSlice.actions

export default qualityChangeControlSlice.reducer