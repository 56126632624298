import { clearIncaData, fetchInca } from "reducers/safety-base-view/incaReducer";
import { clearcapaData, fetchCapa } from "reducers/safety-base-view/capaReducer";
import { clearEhssData, fetchEhss } from "reducers/safety-base-view/ehssReducer";
import { clearSeeCardsData, fetchSeeCards } from "reducers/safety-base-view/seeCardsReducer";
import { clearMinorDeviationData, fetchMinorDeviation } from "reducers/quality-base-view/minorDeviation";
import { clearMajorDeviationData, fetchMajorDeviation } from "reducers/quality-base-view/majorDeviation";
import { clearGmpTrainingData, fetchGmpTraining } from "reducers/quality-base-view/gmpReducer";
import { AppDispatch } from "../store";
import { clearworkOrderData, fetchWorkOrder } from "reducers/safety-base-view/workOrderReducer";
import { fetchCapaDeepDive } from "reducers/safety-deep-dive/capaDeepDiveReducer";
import { clearSafetyIncaDeepDiveData, fetchIncaDeepDive } from "reducers/safety-deep-dive/incaDeepDiveReducer";
import { fetchWorkOrderDeepDive } from "reducers/safety-deep-dive/workOrderReducer";
import { fetchEhssDeepDive } from "reducers/safety-deep-dive/ehssReducer";
import { fetchSeeCardsDeepDive } from "reducers/safety-deep-dive/seeCardsDeepDiveReducer";
import { clearQualityCAPAData, fetchQualityCAPA } from "reducers/quality-base-view/qualityCAPAReducer";
import { clearQualityChangeControlData, fetchQualityChangeControl } from "reducers/quality-base-view/qualityChangeControlReducer";
import { clearQCCDD, fetchChangeControlDeepDive } from "reducers/quality-deep-dive/changeControlDeepDiveReducer";
import { clearQualityRFTData, fetchQualityRFT } from "reducers/quality-base-view/qualityRFTReducers";
import { clearQualityMajorDevData, fetchMajorDeviationDeepDive } from "reducers/quality-deep-dive/majorDevDeepDiveReducer";
import { clearQualityGXPData, fetchGxpDeepDive } from "reducers/quality-deep-dive/gxpReducer";
import { clearQualityMinorDevData, fetchMinorDeviationDeepDive } from "reducers/quality-deep-dive/minorDevDeepDiveReducer";
import { clearQCSDD, fetchCapaStatusDeepDive } from "reducers/quality-deep-dive/qualityCapaDeepDiveReducer";
import { clearAgingWorkorderData, fetchAgingWorkorder } from "reducers/delivery-base-view/agingWorkorder";
import { clearPmAdherenceData, fetchPmAdherence } from "reducers/delivery-base-view/pmAdherence";
import { clearDeliveryCalibrationData, fetchDeliveryCalibration } from "reducers/delivery-base-view/deliveryCalibrationReducers";
import { clearBatchDispositionData, fetchBatchDisposition } from "reducers/delivery-base-view/batchDispositionReducer";
import { clearPmAdherenceDeepDiveData, fetchPmAdherenceDeepDive } from "reducers/delivery-deep-dive/pmAdherenceDeepDiveReducer";
import { clearOnTimeScheduleAdherenceBVData, fetchOnTimeScheduleAdherenceBV } from "reducers/delivery-base-view/onTimeScheduleAdherenceReducer";
import { clearAgingWoDeepDiveData, fetchAgingWoDeepDive } from "reducers/delivery-deep-dive/agingWoDeepDiveReducer";
import { clearCalClosureAdherenceDeepDiveData, fetchCalClosureAdherenceDeepDive } from "reducers/delivery-deep-dive/calClosureAdherenceDeepDiveReducer";
import { FetchDataParams } from "types/wctTypes";
import { fetchQualityDeepdiveRFT } from "reducers/quality-deep-dive/qualityRFTReducers";
import { clearTurnOverData, fetchTurnOver } from "reducers/inventory-base-view/turnOver";
import { clearCriticalMfcData, fetchCriticalMfc } from "reducers/inventory-base-view/criticalMfc";
import { clearKanBanHealthBVData, fetchKanBanHealthBV } from "reducers/inventory-base-view/kanbanHealthBVReducer";
import { clearBatchDispositionDeepDiveData, fetchBatchDispositionDeepDive } from "reducers/delivery-deep-dive/batchDispositionDeepDiveReducer";
import { clearonTimeScheduleAdherenceDeepDiveData, fetchonTimeScheduleAdherenceDeepDive } from "reducers/delivery-deep-dive/onTimeScheduleAdherenceDeepDiveReducer";
import { clearCriticalMfcDeepDiveData, fetchCriticalMfcDeepDive } from "reducers/inventory-deep-dive/criticalMfc";
import { clearKanbanHealthDdvData, fetchKanbanHealthDdv } from "reducers/inventory-deep-dive/kanbanHealthDdvReducer";
import { fetchoverallEquipmentEfficiencyAllBv, clearoverallEquipmentEfficiencyAllBvData } from "reducers/productivity-base-view/overallEquipmentEfficiencyAllReducer";
import { clearMajorEqDowntimeBvData, fetchMajorEqDowntimeBv } from "reducers/productivity-base-view/majorEqDowntimeBvReducer";
import { clearMajorEqDowntimeDdvData, fetchmajorEqDowntimeDdv } from "reducers/productivity-deep-dive/majorEqDowntimeDdvReducer";
import { clearnormalScrapDdvData, fetchnormalScrapDdv } from "reducers/productivity-deep-dive/normalScarpDdvReducer";
import { clearscrapBvData, fetchscrapBv } from "reducers/productivity-base-view/scrapReducer";
import { clearabNormalScrapDdvData, fetchabNormalScrapDdv } from "reducers/productivity-deep-dive/abnormalScrapDdvReducer";
import { clearOverallQuipmentEfficiencyDeepDiveData, fetchOverallQuipmentEfficiencyDeepDive, fetchOverallQuipmentEfficiencyFilterData } from "reducers/productivity-deep-dive/overallEquipmentEfficiencyAllDeepDiveReducer";
import { clearSetupClosedownAdherenceBvData, fetchSetupClosedownAdherenceBv } from "reducers/productivity-base-view/setupClosedownAdherenceBvReducer";
import { clearOeeThreeBvData, fetchOeeThreeBv } from "reducers/productivity-base-view/oeeThreeReducer";
import { clearOeeThreeDdvData, fetchOeeThreeDdv } from "reducers/productivity-deep-dive/oee3DdvReducer";
import { clearSetupClosedownAdherenceDdvData, fetchSetupClosedownAdherenceDdv } from "reducers/productivity-deep-dive/setupClosedownAdherenceDdvReducer";

export const actionMap = {
  inca: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearIncaData())
    dispatch(clearSafetyIncaDeepDiveData())
    dispatch(fetchInca(params));
    dispatch(fetchIncaDeepDive(params));
  },
  capa: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearcapaData())
    dispatch(fetchCapa(params));
    dispatch(fetchCapaDeepDive(params));
  },
  ehss: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearEhssData())
    dispatch(fetchEhss(params));
    dispatch(fetchEhssDeepDive(params));
  },
  seeCards: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearSeeCardsData())
    dispatch(fetchSeeCards(params));
    dispatch(fetchSeeCardsDeepDive(params));
  },
  workorder: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearworkOrderData())
    dispatch(fetchWorkOrder(params));
    dispatch(fetchWorkOrderDeepDive(params));
  },
  minorDeviation: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearMinorDeviationData())
    dispatch(clearQualityMinorDevData())
    dispatch(fetchMinorDeviation(params))
    dispatch(fetchMinorDeviationDeepDive(params))
  },
  majorDeviation: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearMajorDeviationData())
    dispatch(clearQualityMajorDevData())
    dispatch(fetchMajorDeviation(params))
    dispatch(fetchMajorDeviationDeepDive(params))
  },
  gxpTraining: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearGmpTrainingData())
    dispatch(clearQualityGXPData())
    dispatch(fetchGmpTraining(params))
    dispatch(fetchGxpDeepDive(params))
  },
  changecontrol: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearQualityChangeControlData())
    dispatch(clearQCCDD())
    dispatch(fetchQualityChangeControl(params))
    dispatch(fetchChangeControlDeepDive(params));
  },
  capastatus: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearQualityCAPAData())
    dispatch(clearQCSDD())
    dispatch(fetchQualityCAPA(params))
    dispatch(fetchCapaStatusDeepDive(params))
  },
  rft: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearQualityRFTData())
    dispatch(fetchQualityRFT(params))
    dispatch(fetchQualityDeepdiveRFT(params))
  },
  agingWorkOrders: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearAgingWorkorderData())
    dispatch(clearAgingWoDeepDiveData())
    dispatch(fetchAgingWorkorder(params))
    dispatch(fetchAgingWoDeepDive(params))
  },
  pmAdherence: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearPmAdherenceData())
    dispatch(clearPmAdherenceDeepDiveData())
    dispatch(fetchPmAdherence(params))
    dispatch(fetchPmAdherenceDeepDive(params))
  },
  calClosureAdherence: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearDeliveryCalibrationData())
    dispatch(clearCalClosureAdherenceDeepDiveData())
    dispatch(fetchDeliveryCalibration(params))
    dispatch(fetchCalClosureAdherenceDeepDive(params))
  },
  batchDisposition: async (dispatch: AppDispatch, params: FetchDataParams) => {
    const newParams = {
      ...params,
      line: "All",
    }
    dispatch(clearBatchDispositionData())
    dispatch(fetchBatchDisposition(params))
    dispatch(clearBatchDispositionDeepDiveData())
    dispatch(fetchBatchDispositionDeepDive(newParams))
  },
  onTimeScheduleAdherence: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearOnTimeScheduleAdherenceBVData())
    dispatch(fetchOnTimeScheduleAdherenceBV(params))
    dispatch(clearonTimeScheduleAdherenceDeepDiveData())
    dispatch(fetchonTimeScheduleAdherenceDeepDive(params))
  },
  inventoryTurnOver: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearTurnOverData())
    dispatch(fetchTurnOver(params))
  },
  criticalMfc: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearCriticalMfcData())
    dispatch(fetchCriticalMfc(params))
    dispatch(fetchCriticalMfcDeepDive(params))
    dispatch(clearCriticalMfcDeepDiveData())
  },
  kanbanHealth: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearKanBanHealthBVData())
    dispatch(fetchKanBanHealthBV(params))
    dispatch(clearKanbanHealthDdvData())
    dispatch(fetchKanbanHealthDdv(params))
  },
  overallEquipmentEfficiencyAll: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearoverallEquipmentEfficiencyAllBvData())
    dispatch(fetchoverallEquipmentEfficiencyAllBv(params))
    dispatch(clearOverallQuipmentEfficiencyDeepDiveData())
    dispatch(fetchOverallQuipmentEfficiencyDeepDive(params))

  },
  majorEqDowntime: async (dispatch: AppDispatch, params: FetchDataParams) => {
    const newParams = {
      ...params,
      line: "All",
    }
    dispatch(clearMajorEqDowntimeBvData())
    dispatch(fetchMajorEqDowntimeBv(params))
    dispatch(clearMajorEqDowntimeDdvData())
    dispatch(fetchmajorEqDowntimeDdv(newParams))
  },
  scrap: async (dispatch: AppDispatch, params: FetchDataParams) => {
    const newParams = {
      ...params,
      line: "All",
    }
    dispatch(clearscrapBvData())
    dispatch(fetchscrapBv(params))
    dispatch(clearnormalScrapDdvData())
    dispatch(fetchnormalScrapDdv(newParams))
  },
  abnormalScrap: async (dispatch: AppDispatch, params: FetchDataParams) => {
    dispatch(clearabNormalScrapDdvData())
    dispatch(fetchabNormalScrapDdv(params))
  },
  setupClosedownAdherence: async (dispatch: AppDispatch, params: FetchDataParams) => {
    const newParams = {
      ...params,
      line: "All",
    }
    dispatch(clearSetupClosedownAdherenceBvData())
    dispatch(fetchSetupClosedownAdherenceBv(params))
    dispatch(clearSetupClosedownAdherenceDdvData())
    if (params?.tierNumber === "tier 1") {
      dispatch(fetchSetupClosedownAdherenceDdv(newParams))
    } else {
      dispatch(fetchSetupClosedownAdherenceDdv(params))
    }
  },
  oee3: async (dispatch: AppDispatch, params: FetchDataParams) => {
    const newParams = {
      ...params,
      line: "All",
    }
    dispatch(clearOeeThreeBvData())
    dispatch(fetchOeeThreeBv(params))
    dispatch(clearOeeThreeDdvData())
    if (params?.tierNumber === "tier 1") {
      dispatch(fetchOeeThreeDdv(newParams))
    } else {
      dispatch(fetchOeeThreeDdv(params))
    }
  }
}

/**
 * Checks if any key in an object has a non -empty . non-undifined value.
 * @param {Object} obj - the object to check
 * @returns {boolean}
 */
export const hasValidValues = (obj: Record<string, any>): boolean => {
  return Object.values(obj).some((val) => val !== "" && val !== undefined);
}