import React from 'react';
import Calendar from './Calendar';
import styled from '@emotion/styled';
import { Card } from 'antd';
import StyledCard from '../metric-card/StyledCard';

// Define the styled components
const CalendarTitle = styled.div`
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
    margin-bottom: 10px;
    padding: 0;
    display: flex;
    justify-content: start;
    min-height: 28px;
`;

const CalendarWrapper: React.FC<{ calendarOptions: { title: string, greendates: string[], reddates: string[] } }> = (props) => {
    return (
        <StyledCard>
            <CalendarTitle>{props.calendarOptions.title}</CalendarTitle> {/* Updated to use calendarOptions.title */}
            <Calendar options={props.calendarOptions} />
        </StyledCard>
    );
};

export default CalendarWrapper;