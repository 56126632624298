export const COLORS = {
    default: "#0063C3",
    serious: "#E01029",
    potentiallySerious: "#FDB81E",
    major: "#0063C3",
    minor: "#9FE7E2",
    atRiskBehavior: "#0063C3",
    atRiskCondition: "#FDB81E",
    safeBehavior: "#2FBCB6",
    nonEHSIssue: "#0063C3",
    aging: "#E01029",
    adherence: "#0063C3",
    trends: "#FDB81E",
    // dashedLine: "#00BCE4",
    dashedLine: "#2FBCB6",
    blueDash: "#00BCE4",
    ahead: "#2CC84D",
    OEE3: "#0063C3",
    OEE2: "#E01029",
    OEE1: "#2FBCB6",
    Chromatography_HPLC: "#0063C3",
    Elisa_Immuno: "#FDB81E",
    Chromatography_UPLC: "#2FBCB6",
    RAMAN: "#E377C2",
    pm_percentage_6m: "#2FBCB6",
    no_of_work_orders: "#0063C3",
    closure_adherence_percentage: "#2FBCB6",
};

export interface DataItem {
    [material: string]: any;
    start_date?: string;
    no_of_work_orders?: number;
    item?: string;
    test?: string;
    oee3?: number;
    oee2?: number;
    oee1?: number;
    on_time_disposition_batches?: number;
    not_on_time_disposition_batches?: number;
    percent_batch_disposition_within_target?: number;
    avg_turn_around_time?: number;
    total_completed?: number;
    avg_dlt_per_batch?: number;
    percent_brr_completed_within_target?: number;
    not_on_time_batches_mtd?: number;
    on_time_batches_mtd?: number;
    month?: string;
    closed_workorders?: number;
    closure_adherence_percentage?: number;
    total_workorders?: number;
    GMCAL?: number;
    CMCAL?: number;
    PMCAL?: number;
    value?: number;
    line?: string;
    date?: string
    serious?: number
    potentiallySerious?: number
    major?: number
    minor?: number
    atRiskBehavior?: number
    atRiskCondition?: number
    safeBehavior?: number
    nonEHSIssue?: number
    aging?: number
    adherence?: number
    trends?: number
    last_7d_date?: string
    Month?: number
    Year?: number
    open_aging_count_7d?: number
    open_count_7d?: number
    mtd_date?: string
    not_on_time_review_mtd?: number
    on_time_review_mtd?: number
    deviation_percentage_mtd?: number
    deviation_percentage_6m?: number
    not_on_time_review_6m?: number
    on_time_review_6m?: number
    aging_open_percentage_6m?: number
    open_aging_count_6m?: number
    open_count_6m?: number
    open_count_mtd?: number
    open_aging_count_mtd?: number
    aging_open_percentage_mtd?: number
    coming_due_last_7d_count?: number
    aging_last_7days?: number
    normal_last_7days?: number
    compliance_date?: string
    compliance_rate?: number
    not_on_time_cc?: number
    on_time_cc?: number
    aging_cc?: number
    non_aging_cc?: number
    non_aging_flag?: number
    aging_flag?: number
    coming_due?: number
    on_time?: number
    not_on_time?: number
    aging_percentage?: number
    non_aging?: number
    closedWorkOrders?: number
    openWorkOrders?: number
    woClosureAdherence?: number
    pm_vs_cm_gm_ratio?: number
    pm_percentage_6m?: number
    labor_group?: string
    open?: number
    closed?: number
    pre_closure_over_weeks?: number
    quarter?: number
    ytd_actual?: number
    ytd_planned?: number
    quarterly_actual?: number
    year?: number | string
    actual_units?: number
    planned_units?: number
    material_number?: number | string
    lag_ahead_hours?: number
    name?: string
    hour?: string
}

export interface NameStyleConfig {
    fontSize?: number;
    fontWeight?: number;
    fontFamily?: string;
    lineHeight?: number;
    color?: string;
    align?: string;
    verticalAlign?: string;
    rotate?: number
}

export interface StackedBarChartProps {
    data: DataItem[]
    timePeriod?:
    | "Last Week"
    | "Last Month"
    | "Last 3 Months"
    | "MTD"
    | "Last6Months";
    chartType: "see-cards" | "inca" | "minor-deviation" | "minor-deviation-adherence-mtd" | "minor-deviation-adherence-6m" | "minor-deviation-aging-mtd" | "minor-deviation-aging-6m"
    | "major-deviation" | "major-deviation-adherence-mtd" | "major-deviation-adherence-6m" | "major-deviation-aging-mtd" | "major-deviation-aging-6m" | "change-control-status"
    | "change-control-adherence-6m" | "change-control-aging-mtd" | "change-control-aging-6m" | "change-control-adherence-mtd" | "capa-status" |
    "capa-status-mtd" | "capa-status-adherence-6m" | "capa-status-aging-mtd" | "capa-status-aging-6m" | "ratio-pm" | "ratio-pm-vs" | "percent-closure-pm" | "aging-work-orders" | "rft"
    | "wo-closure-adherence" | "wo-calibration-dis" | "per-calibration-closure" | "on-time-schedule-bv" | "batch-disposition" | "trend-graph-adherence" | "trend-graph-units" | "on-time-ddv-actual-vs-planned-weekly" |
    "on-time-ddv-actual-vs-planned-weekly-current" | "on-time-ddv-actual-vs-planned-six-months" | "on-time-ddv-actual-and-planned-by-product" | "global";

    xAxisData?: string[]
    yAxisNames?: YAxisNamesProps[]
    nameGapCustom?: number
    chartConfig?: ChartConfigProps[]
    yAxisMax?: number
    showSlider?: boolean
    grid?: EchartsGridProps
    chartWidth?: string,
    yAxisNameStyleConfig?: NameStyleConfig,
    xAxisNames?: { name: string; position: string, max?: number | undefined, min?: number | undefined, nameGap?: number | undefined }[]
    xAxisNameStyleConfig?: NameStyleConfig,
}

export interface YAxisNamesProps { 
    name: string; 
    position: string, 
    max: number | undefined, 
    min?: number | undefined, 
    nameGap?: number | undefined 
    nameRotate?: number
    showPercentageLabel?: boolean
}

export interface EchartsGridProps {
    top?: number | string
    right?: number | string
    bottom?: number | string
    left?: number | string
    containLabel?: boolean
}

export interface ChartConfigProps extends LegendProps {
    dataKey: string;
    nameKey: string;
}

export interface LegendProps {
    statusValue: string;
    circleColor: string;
}

export interface SeriesData {
    name?: string;
    data: number[];
    type: string;
    stack?: string;
    barWidth?: number;
    itemStyle: { color: string };
    emphasis?: {
        focus: string;
    };
}

export interface LegendData {
    [key: string]: string;
}

export const excludedKeys = ['last_7d_date', 'Month', 'Year', 'deviation_percentage_6m',
    'deviation_percentage_mtd', 'mtd_date', 'aging_open_percentage_6m', 'aging_open_percentage_mtd',
    'compliance_date', 'compliance_rate', 'aging_percentage', 'date', 'weekend', 'week', 'date_of_incident', 'priority',
    'year_of_incident', 'line', 'percent_brr_completed_within_target', 'percent_batch_disposition_within_target',
    'pm_percentage_6m', 'week_start_date', 'start_date', 'material_number', 'month', 'closure_adherence_percentage'];
