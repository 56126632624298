/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import type { MenuProps } from 'antd';

interface AohDropdownType {
    items: MenuProps['items']
    handleMenuClick?: MenuProps['onClick']
    title: string
    dropOptions?: string
    buttonHeight?: string
    buttonMinWidth?: string
    downOutlinedColor?: string
    horizontalItemHoverColor?: string
    downOutlinedMarginLeft?: string
    labelMarginRight?: string
    dropMarginLeft?: string
}

const AohDropdownStyled: React.FC<AohDropdownType> = ({ 
    items, 
    handleMenuClick, 
    title, 
    dropOptions, 
    buttonHeight = '28px', 
    buttonMinWidth = '132px', 
    downOutlinedColor = '#BFBFBF', 
    horizontalItemHoverColor = '#1F1F1F',
    downOutlinedMarginLeft = '50px',
    labelMarginRight = '4px',
    dropMarginLeft = '15px'
}) => {
    const menuProps = {
        items,
        onClick: handleMenuClick,
        horizontalItemHoverColor
    };

    const Label = styled.span`
        font-size: 12px;
        font-weight: 700;
        color: #595959;
        margin-right: ${labelMarginRight};
    `;

    const Drop = styled.div`
        margin-left: ${dropMarginLeft};
    `;

    const CustomDropdown = styled(Dropdown)`
        .ant-dropdown-menu-item:hover {
            background-color: #E6F7FF !important;  
            color: #1F1F1F !important;            
            font-weight: 700 !important;
        }
        .ant-dropdown-menu-item {
            font-size: 12px !important;
        }
    `;

    const CustomSpaceItem = styled(Space)`
        .ant-space-item {
            font-size: 12px;
        }
    `;

    return (
        <Drop>
            <Label>{title} : </Label>
            <Space wrap>
                <CustomDropdown menu={menuProps}>
                    <Button style={{ height: buttonHeight, minWidth: buttonMinWidth }}>
                        <CustomSpaceItem>                           
                            {dropOptions}
                            <DownOutlined style={{ marginLeft: downOutlinedMarginLeft, color: downOutlinedColor }}/>                                
                        </CustomSpaceItem>
                    </Button>
                </CustomDropdown>
            </Space>
        </Drop>
    );
}

export default AohDropdownStyled;
