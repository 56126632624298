import AohTable from "../../common/table/AohTable";
import AohTableWithLabels from "../../common/table/AohTableWithLabels";
import AohDropdown from "components/common/dropdown/aohdropdown";
import { Button } from "@gitlab-rtsensing/component-library";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Safety.scss";
import { useState } from "react";
import { columns, ehssColumns, incaColumns, incaGlobalEhssColumns, seecardColumns, workOrderColumns } from "utils/constants";
import { DownloadOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { downloadCSV, generateCSV } from "utils/common-methods";
import { RadioFilters } from "components/resuable/radio-filters/RadioFilters";

interface SafetyTierProps {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

interface HeaderDropDownType {
  key: string;
  label: string;
}

const SafetyTier: React.FC<SafetyTierProps> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  // const [filteredData, setFilteredData] = useState(data?.inca?.inca_open_incidents);
  // const [currentStatus, setCurrentStatus] = useState<HeaderDropDownType>({
  //   key: "All",
  //   label: "All",
  // });

  const [FilterStatus,setFilterStatus]= useState("All");

  const statusDropDownData: HeaderDropDownType[] = [
    { key: "All", label: "All" }, 
    ...Array.from(new Set(data?.inca?.inca_open_incidents?.map((item: { status: string }) => item.status))) // Get unique status values
      .filter((status): status is string => status !== undefined && status !== null) // Filter out undefined or null values
      .map((uniqueStatus: string) => ({
          key: uniqueStatus,
          label: uniqueStatus,
      })),
  ];

  let tableData: any;

  switch (FilterStatus) {
    case 'All':
      tableData = data?.inca?.tableDataAll;
      break;
    case 'Overdue':
      tableData = data?.inca?.tableDataOverdue;
      break;
    case 'Coming Due':
      tableData = data?.inca?.tableDataComingDue;
      break;
    default:
      tableData = data?.inca?.tableDataAll; // Fallback to last 30 days if the condition is invalid
  }
  
  const safetyDeepDive: any = {
    "SEE Cards": {
      title: "New SEE Cards",
      subtitle: "In Last 24 Hours",
      button: (
        <Button
          label="Download"
          className={(!data?.seeCards?.data || data?.seeCards?.data.length === 0) ? "" : "download-btn"}
          icon={<DownloadOutlined />}
          iconPosition="right"
          onClick={() => {
            if (data?.seeCards?.data && seecardColumns) {
              const csvContent = generateCSV(data.seeCards.data, seecardColumns);
              downloadCSV(csvContent, "SEE_Cards_Data.csv");
            } else {
              console.error("No data or columns available for download");
            }
          }}
          type="secondary"
          disabled={(!data?.seeCards?.data || data?.seeCards?.data.length === 0) ? true : false}
        />
      ),
      table: <AohTable tableData={data?.seeCards?.data} tableColumns={seecardColumns} />,
    },
    "INCA Incidents": {
      title: "Open Incident List",
      title2: "Global EHSS Awareness",
      subtitle2: "In Last 5 Days",
      radioFilters: (
        <RadioFilters
            timePeriod={FilterStatus}
            setTimePeriod={setFilterStatus}
            activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      button: (
        <Button
            label="Download"
            className={(!tableData || tableData.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (tableData && incaColumns) {
                    const csvContent = generateCSV(tableData, incaColumns);
                    downloadCSV(csvContent, "inca_incredients.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!tableData || tableData.length === 0) ? true : false}
        />
      ),
      table: (
        <AohTableWithLabels
          tableData={tableData}
          tableColumns={incaColumns}
        />
      ),
      button2: (
        <Button
            label="Download"
            className={(!data?.inca?.inca_global_ehss || data?.inca?.inca_global_ehss.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.inca?.inca_global_ehss && incaGlobalEhssColumns) {
                    const csvContent = generateCSV(data?.inca?.inca_global_ehss, incaGlobalEhssColumns);
                    downloadCSV(csvContent, "global_ehss_awarness.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.inca?.inca_global_ehss || data?.inca?.inca_global_ehss.length === 0) ? true : false}
        />
      ),
      table2: <AohTable tableData={data?.inca?.inca_global_ehss} tableColumns={incaGlobalEhssColumns}/>,
    },
    "Safety Critical Work Orders": {
      title: "Summary",
      title2: "Open Safety Critical Work Orders",
      summary: (
        <SummaryCard
          data={data?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      button2: (
        <Button
            label="Download"
            className={(!data?.workOrderDeepDive || data?.workOrderDeepDive.length === 0) ? "" : "sownload-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.workOrderDeepDive && workOrderColumns) {
                    const csvContent = generateCSV(data?.workOrderDeepDive, workOrderColumns);
                    downloadCSV(csvContent, "safety_critical_work_orders.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.workOrderDeepDive || data?.workOrderDeepDive.length === 0) ? true : false}
        />
      ),
      table2: (
        <AohTableWithLabels
          tableData={data?.workOrderDeepDive}
          tableColumns={workOrderColumns}
        />
      ),
    },
    "EHSS Training Compliance": {
      title: "Upcoming Due Tasks",
      subtitle: "Within 5 Days",
      button: (
        <Button
            label="Download"
            className={(!data?.workOrderDeepDive || data?.ehss?.data.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.ehss?.data && ehssColumns) {
                    const csvContent = generateCSV(data?.ehss?.data, ehssColumns);
                    downloadCSV(csvContent, "ehss_training_compliance.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.workOrderDeepDive || data?.ehss?.data.length === 0) ? true : false}
        />
      ),
      table: (
        <AohTable tableData={data?.ehss?.data} tableColumns={ehssColumns} />
      ),
    },
  };

  return (
    <div className="safety-tier-main">
      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.radioFilters}
      {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle && (
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle}
      </div>
      )}
      {safetyDeepDive[activeKeyDeepDiveTab]?.summary}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
        }
      ></div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.table}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.heatmap ||
            safetyDeepDive[activeKeyDeepDiveTab]?.table ||
            safetyDeepDive[activeKeyDeepDiveTab]?.stackedBar
            ? "mr-bottom-20"
            : ""
        }
      ></div>

      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title2}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button2}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2 && (
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2}
      </div>
      )}
      {safetyDeepDive[activeKeyDeepDiveTab]?.table2}
    </div>
  );
};
export default SafetyTier;
