import AohTable from "../../common/table/AohTable";
import AohTableWithLabels from "../../common/table/AohTableWithLabels";
import ColorCodedTable from "../../common/table/ColorCodedTable";
import AohDropdown from "components/common/dropdown/aohdropdown";
import { Button } from "@gitlab-rtsensing/component-library";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Safety.scss";
import { useState } from "react";
import { capaColumns, ehssColumns, incaColumns, incaGlobalEhssColumns, seecardColumns, workOrderColumns } from "utils/constants";
import { DownloadOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { downloadCSV, generateCSV } from "utils/common-methods";
import { RadioFilters } from "components/resuable/radio-filters/RadioFilters";

interface SafetyTier1Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

interface HeaderDropDownType {
  key: string;
  label: string;
}

const SafetyTier1: React.FC<SafetyTier1Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const [FilterStatus,setFilterStatus]= useState("All");

  const statusDropDownData: HeaderDropDownType[] = [
    { key: "All", label: "All" }, 
    ...Array.from(new Set(data?.inca?.inca_open_incidents?.map((item: { status: string }) => item.status))) // Get unique status values
      .filter((status): status is string => status !== undefined && status !== null) // Filter out undefined or null values
      .map((uniqueStatus: string) => ({
          key: uniqueStatus,
          label: uniqueStatus,
      })),
  ];

  let tableData: any;

  switch (FilterStatus) {
    case 'All':
      tableData = data?.inca?.tableDataAll;
      break;
    case 'Overdue':
      tableData = data?.inca?.tableDataOverdue;
      break;
    case 'Coming Due':
      tableData = data?.inca?.tableDataComingDue;
      break;
    default:
      tableData = data?.inca?.tableDataAll; // Fallback to last 30 days if the condition is invalid
  }

  const safetyDeepDive: any = {
    "SEE Cards": {
      title: "Summary",
      summary: (
        <SummaryCard
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={data?.seeCards?.summary}
        />
      ),
      title2: "Open SEE Cards",
      button2: (
        <Button
          label="Download"
          className={(!data?.seeCards?.open_see_data || data?.seeCards?.open_see_data.length === 0) ? "" : "download-btn"}
          icon={<DownloadOutlined />}
          iconPosition="right"
          onClick={() => {
            if (data?.seeCards?.open_see_data && seecardColumns) {
              const csvContent = generateCSV(data?.seeCards?.open_see_data, seecardColumns);
              downloadCSV(csvContent, "Open_SEE_Cards_Data.csv");
            } else {
              console.error("No data or columns available for download");
            }
          }}
          type="secondary"
          disabled={(!data?.seeCards?.open_see_data || data?.seeCards?.open_see_data.length === 0) ? true : false}
        />
      ),
      table2: <AohTable tableData={data?.seeCards?.open_see_data} tableColumns={seecardColumns}/>,
    },
    "INCA Incidents": {
      title: "Summary",
      summary: (
        <SummaryCard
          data={data?.inca?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title2: "Open Incident List",
      radioFilters2: (
          <RadioFilters
              timePeriod={FilterStatus}
              setTimePeriod={setFilterStatus}
              activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          />
      ),
      button2: (
        <Button
            label="Download"
            className={(!tableData || tableData.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (tableData && incaColumns) {
                    const csvContent = generateCSV(tableData, incaColumns);
                    downloadCSV(csvContent, "inca_incredients.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!tableData || tableData.length === 0) ? true : false}
        />
    ),
      table2: (
        <AohTableWithLabels
          tableData={tableData}
          tableColumns={incaColumns}
        />
      ),
      title3: "Global EHSS Awareness",
      subtitle3: "In Last 5 Days",
      button3: (
        <Button
            label="Download"
            className={(!data?.inca?.inca_global_ehss || data?.inca?.inca_global_ehss.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.inca?.inca_global_ehss && incaGlobalEhssColumns) {
                    const csvContent = generateCSV(data?.inca?.inca_global_ehss, incaGlobalEhssColumns);
                    downloadCSV(csvContent, "global_ehss_awareness.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.inca?.inca_global_ehss || data?.inca?.inca_global_ehss.length === 0) ? true : false}
        />
      ),
      table3: <AohTable tableData={data?.inca?.inca_global_ehss} tableColumns={incaGlobalEhssColumns}/>,
    },
    "Safety Critical Work Orders": {
      title: "Summary",
      title2: "Open Safety Critical Work Orders",
      summary: (
        <SummaryCard
          data={data?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      button2: (
        <Button
            label="Download"
            className={(!data?.workOrderDeepDive || data?.workOrderDeepDive.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.inca?.inca_global_ehss && incaGlobalEhssColumns) {
                    const csvContent = generateCSV(data?.inca?.inca_global_ehss, incaGlobalEhssColumns);
                    downloadCSV(csvContent, "safety_critical_work_orders.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.workOrderDeepDive || data?.workOrderDeepDive.length === 0) ? true : false}
        />
      ),
      table2: (
        <AohTableWithLabels
          tableData={data?.workOrderDeepDive}
          tableColumns={workOrderColumns}
        />
      ),
    },
    "EHSS Training Compliance": {
      title: "Summary",
      summary: (
        <SummaryCard
          data={data?.ehss?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title2: "Upcoming Due Tasks",
      subtitle2: "Within 5 Days",
      button2: (
        <Button
            label="Download"
            className={(!data?.ehss?.data || data?.ehss?.data.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.ehss?.data && ehssColumns) {
                    const csvContent = generateCSV(data?.ehss?.data, ehssColumns);
                    downloadCSV(csvContent, "upcoming_due_tasks.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.ehss?.data || data?.ehss?.data.length === 0) ? true : false}
        />
      ),
      table2: (
        <AohTable tableData={data?.ehss?.data} tableColumns={ehssColumns} />
      ),
      title3: "EHSS Compliance Rate",
      heatmap: <ColorCodedTable data={data?.ehss?.complianceRateData} />,
    },
    "Safety CAPAs": {
      title: "Summary",
      summary: (
        <SummaryCard
          data={data?.capa?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title2: "Unclosed CAPAs",
      button2: (
        <Button
            label="Download"
            className={(!data?.capa?.data || data?.capa?.data.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.capa?.data && capaColumns) {
                    const csvContent = generateCSV(data?.capa?.data, capaColumns);
                    downloadCSV(csvContent, "unclosed_capa.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.capa?.data || data?.capa?.data.length === 0) ? true : false}
        />
      ),      
      table2: (
        <AohTable tableData={data?.capa?.data} tableColumns={capaColumns} />
      ),
    },
  };

  return (
    <div className="safety-tier-main">
      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown}
      {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle1 && (
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle}
      </div>
      )}
      {safetyDeepDive[activeKeyDeepDiveTab]?.summary}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
        }
      ></div>
      {safetyDeepDive[activeKeyDeepDiveTab].table}

      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title2}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button2}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2 && (
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2}
      </div>
      )}
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown2}
      {safetyDeepDive[activeKeyDeepDiveTab]?.radioFilters2}
      {safetyDeepDive[activeKeyDeepDiveTab]?.table2}
      {safetyDeepDive[activeKeyDeepDiveTab]?.table}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.heatmap ||
            safetyDeepDive[activeKeyDeepDiveTab]?.table ||
            safetyDeepDive[activeKeyDeepDiveTab]?.table2 ||
            safetyDeepDive[activeKeyDeepDiveTab]?.stackedBar
            ? "mr-bottom-20"
            : ""
        }
      ></div>

      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title3}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button3}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown3}
      {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle3 && (
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle3}
      </div>
      )}
      {safetyDeepDive[activeKeyDeepDiveTab]?.summary3}
      {safetyDeepDive[activeKeyDeepDiveTab].table3}
      {safetyDeepDive[activeKeyDeepDiveTab].heatmap}
    </div>
  );
};
export default SafetyTier1;
