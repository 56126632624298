import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { SCRAP_DEEP_DIVE_URL } from 'utils/constants';
import { StringKeyAnyDataProps } from 'utils/data-types';

interface NormalScrapDdvInfoState {
  normalScrapDdvInfo: APIResponseDataType[] | null;
  normalScrapFilterData: StringKeyAnyDataProps[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: NormalScrapDdvInfoState = {
  normalScrapDdvInfo: null,
  normalScrapFilterData: null,
  loading: false,
  error: false,
}

const fetchnormalScrapDdv = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
  SCRAP_DEEP_DIVE_URL,
  async (params) => {
      const filteredParams = filterParams(params);
      const token: string = getOktaToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}${SCRAP_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
          method: "GET",
          headers: {
              authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          }
      });
      if (!response.ok) {
          throw new Error('Failed to fetch PM Adherence');
      }
      const data = await response.json();

      data.forEach((filter: any) => {
        if (filter.extraFilterData) {
          filter.extraFilterData = [{ line: 'All' }, ...filter.extraFilterData];
        }
      });
      return data;
  }
);

const fetchnormalScrapFilterData = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
  `${SCRAP_DEEP_DIVE_URL}filter`,
  async (params) => {
      const filteredParams = filterParams(params);
      const token: string = getOktaToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}${SCRAP_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
          method: "GET",
          headers: {
              authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          }
      });
      if (!response.ok) {
          throw new Error('Failed to fetch radio filter data');
      }
      const data = await response.json();

      data.forEach((filter: any) => {
        if (filter.extraFilterData) {
          filter.extraFilterData = [{ line: 'All' }, ...filter.extraFilterData];
        }
      });
      return data;
  }
);

const handleBatchDispositionFilterData = (oldState: { normalScrapDdvInfo: any[]; }, filerData: { filterType: any; data: any; }) => {
  const state = oldState.normalScrapDdvInfo
  state?.map((item: { filterType: any; data: any; }) => {
      if (filerData?.filterType === item.filterType) {
          item.data = filerData.data
      }
      return item
  });
  return state

}

const normalScrapDdvReducer = createSlice({
  name: 'normalScrapDdv',
  initialState,
  reducers: {
    clearnormalScrapDdvData(state) {
      state.normalScrapDdvInfo = null;
      state.normalScrapFilterData = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchnormalScrapDdv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchnormalScrapDdv.fulfilled, (state, action) => {
        state.loading = false;
        state.normalScrapDdvInfo = action.payload
      })
      .addCase(fetchnormalScrapDdv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(fetchnormalScrapFilterData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchnormalScrapFilterData.fulfilled, (state, action) => {
        state.loading = false;
        state.normalScrapDdvInfo = handleBatchDispositionFilterData(state as any, action.payload as any);
      })
      .addCase(fetchnormalScrapFilterData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
  }
})

export { fetchnormalScrapDdv,fetchnormalScrapFilterData };

export const { clearnormalScrapDdvData } = normalScrapDdvReducer.actions

export default normalScrapDdvReducer.reducer;