import React from 'react';
import { Empty } from 'antd';
import styled from '@emotion/styled';

interface StyledEmptyDataProps {
  description?: React.ReactNode;
  image?: React.ReactNode;
  imageStyle?: React.CSSProperties;
}

const StyledNoDataViewPlaceholder = styled(Empty)`
  .ant-empty-image {
    height: 30px;
  }
  .ant-empty-description {
    font-size: 10px;
  }
  .ant-empty-normal {
    margin-block: 0;
  }
`;

const StyledNoDataView: React.FC<StyledEmptyDataProps> = ({ description, image, imageStyle }) => {
  return <StyledNoDataViewPlaceholder description={description ?? "No Data"} image={image ?? Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={imageStyle} />;
};

export default StyledNoDataView;
