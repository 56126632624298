import React from 'react';
import ReactECharts from 'echarts-for-react';
import StyledNoDataView from 'dct-component-library/layouts/StyledNoDataView';

interface StyledPieChartProps {
    data: any;
    config?: {
        radius?: [string, string];
        center?: [string, string];
        startAngle?: number;
        endAngle?: number;
        legend?: {
            orient?: string;
            right?: string;
            left?: string;
            top?: string;
            itemGap?: number;
            icon?: string;
            itemWidth?: number;
            itemHeight?: number;
            textStyle?: {
                fontSize?: number;
                color?: string;
            };
        };
    };
}

const StyledPieChart: React.FC<StyledPieChartProps> = (props) => {
    const { data, config } = props;

    const seriesData = data;
    const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)' // Show name and value in the tooltip
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: config?.radius ?? ['60%', '100%'], // Adjust the radius to ensure a minimum width of 80px
                center: config?.center ?? ['50%', '65%'], // Center the pie chart
                startAngle: config?.startAngle ?? 180,
                endAngle: config?.endAngle ?? 360,
                avoidLabelOverlap: false,
                label: {
                    show: false,
                },
                labelLine: {
                    show: false,
                },
                data: seriesData,
            },
        ],
        legend: {
            orient: config?.legend?.orient ?? 'horizontal',
            right: config?.legend?.right ?? 'auto',
            left: config?.legend?.left ?? 'center', // Center align the legend
            top: config?.legend?.top ?? 'bottom', // Position legend at the bottom
            itemGap: config?.legend?.itemGap ?? 15,
            icon: config?.legend?.icon ?? 'circle',
            itemWidth: config?.legend?.itemWidth ?? 8, // Set legend item width to 8px
            itemHeight: config?.legend?.itemHeight ?? 8, // Set legend item height to 8px
            textStyle: {
                fontSize: config?.legend?.textStyle?.fontSize ?? 10,
                color: config?.legend?.textStyle?.color ?? '#000',
            },
        },
    };

    return (
        <>
            {seriesData.some((item: { value: string }) => item.value !== '0') ? (
                <ReactECharts style={{ width: '100%', height: '100px', minWidth: '80px' }} option={option} />
            ) : (<StyledNoDataView />
            )}
        </>
    );
};
export default StyledPieChart;
