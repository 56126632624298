import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, INCA, METRICS, SAFETY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface IncaInfoState {
  incaInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: IncaInfoState = {
  incaInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, SAFETY, BASE_VIEW, INCA];
const fetchInca = fetchApi(segments);

const incaReducer = createSlice({
    name: 'inca',
    initialState,
    reducers: {
        clearIncaData(state) {
            state.incaInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInca.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchInca.fulfilled, (state, action) => {
                state.loading = false;
                state.incaInfo = action.payload;
            })
            .addCase(fetchInca.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    }
});

export { fetchInca };

export const { clearIncaData } = incaReducer.actions

export default incaReducer.reducer;