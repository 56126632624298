import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, DELIVERY, VERSION, WORK_CENTER_TEAM, DELIVERY_CALIBRATION } from 'utils/constants';

interface BatchDispositionInfoState {
  deliveryCalibrationInfo: APIResponseDataType[] | null;
  status: string;
  error: boolean;
}

const initialState: BatchDispositionInfoState = {
  deliveryCalibrationInfo: null,
  status: 'idle',
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, DELIVERY, BASE_VIEW, DELIVERY_CALIBRATION];
const fetchDeliveryCalibration = fetchApi(segments);

const deliveryCalibrationSlice = createSlice({
    name: 'deliveryCalibration',
    initialState,
    reducers: {
        clearDeliveryCalibrationData(state) {
            state.deliveryCalibrationInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeliveryCalibration.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchDeliveryCalibration.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.deliveryCalibrationInfo = action.payload
            })
            .addCase(fetchDeliveryCalibration.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchDeliveryCalibration }

export const { clearDeliveryCalibrationData } = deliveryCalibrationSlice.actions

export default deliveryCalibrationSlice.reducer