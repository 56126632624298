import React, { useEffect, useState } from "react";

import SummaryCard from "../../common/summaryCard/summaryCard";

import "./Quality.scss";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { RadioFilters } from "components/resuable/radio-filters/RadioFiltersQuality";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { RadioFiltersGraph } from "components/resuable/radio-filters/RadioFilterGraph";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import AohTable from "components/common/table/AohTable";
import { DualLineAreaGraph } from "components/resuable/charts-graph/dual-line-area-graph";

import { downloadCSV, generateCSV } from "utils/common-methods";
import { CapaEVColumns, CapaStatusColumns, ChangeControlColumns, majorDeviationColumns, minorDeviationColumns } from "utils/wctConstants";
import { formatDayMonth, formatDM, formatMTDDate, NewformatMonthYear, newMinMaxValue } from "components/common/StackedBar/utils";
import AohDropdown from "components/common/dropdown/aohdropdown";
import { MenuProps } from "antd";
interface QualityTier3Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const QualityTier3: React.FC<QualityTier3Props> = ({
    data: { lines, exceptionGraphData, deviationGraphData, summary, heatmapData, adherenceGraphDataMtd, adherenceGraphData6m, agingOpenGraphDataMtd, agingOpenGraphData6m, tableDataAll, tableDataAdherence, tableDataOverdue, tableDataComingDue, tableDataAging, tableDataOpen, CAPAtableDataAll, CAPAtableDataOverdue, CAPAtableDataComingDue, CAPAtableDataAging, CAPAtableDataOpen, CAPAEVtableDataAll, CAPAEVtableDataOverdue, CAPAEVtableDataComingDue, CAPAEVtableDataAging, CAPAEVtableDataOpen, capaAdherenceMtdGraph, capaAdherence6Month, capaAgingMtd, capaAging6Month },
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const [minDevTableTimePeriod, setMinDevTableTimePeriod] = useState("All")
    const [minDevTable2TimePeriod, setMinDevTable2TimePeriod] = useState("All")
    const [graphTimePeriod, setGraphTimePeriod] = useState("MTD")
    const [agingGraphTimePeriod, setAgingGraphTimePeriod] = useState("MTD")
    const [rftTimePeriod, setRftTimePeriod] = useState("MTD")
    const [rftViewBy, setRftViewBy] = useState("All")
    const [rftTimePeriod1, setRftTimePeriod1] = useState("MTD")
    const [rftViewBy1, setRftViewBy1] = useState("All")
    const [rftDropdownValueException, setRftDropdownValueException] = useState([]);
    const [rftDropdownValueDeviation, setRftDropdownValueDeviation] = useState([]);

    // Simulate a data fetch function (replace with your actual API call)
    const fetchLines = async () => {
        return await new Promise((resolve) => {
            setTimeout(() => {
                resolve(lines);
            }, 1000);
        });
    };

    const getLineDropdownValueDeviation = async () => {
        const initialDropdownData = [{ key: 'All', label: 'All' }];

        // Simulating fetching data for lines. Replace with your actual fetch function
        const lines: any = await fetchLines();

        // Process line data
        const lineData = lines?.map((line: any) => ({
            key: line.line ?? "",
            label: line.line ?? "",
        })) ?? [];

        return [...initialDropdownData, ...lineData];
    };

    const getLineDropdownValueException = async () => {
        const initialDropdownData = [{ key: 'All', label: 'All' }];

        // Simulating fetching data for lines. Replace with your actual fetch function
        const lines: any = await fetchLines();

        // Process line data
        const lineData = lines?.map((line: any) => ({
            key: line.line ?? "",
            label: line.line ?? "",
        })) ?? [];

        return [...initialDropdownData, ...lineData];
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const exceptionData: any = await getLineDropdownValueException();
                setRftDropdownValueException(exceptionData);
                const deviationData: any = await getLineDropdownValueDeviation();
                setRftDropdownValueDeviation(deviationData);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };

        fetchData();
    }, [exceptionGraphData?.mtdLine, deviationGraphData?.mtdLine]);

    const getDualGraphData = (
        filter: string,
        graphType: string,
        viewByFilter: string,
        timePeriodFilter: string
    ): any => {
        const graphModData = graphType === 'exceptionGraphData' ? exceptionGraphData : deviationGraphData;

        // Map data to filter for All
        const rftDualLineGraphDataMap: Record<string, any> = {
            "MTD All": graphModData?.mtdAll || [],
            "Last 6 Months All": graphModData?.sixMonthsAll || [],
        };

        // Data for specific line view
        if (timePeriodFilter === 'Last 6 Months' && viewByFilter !== 'All') {
            return graphModData?.sixMonthsLine?.filter((item: any) => item.name === viewByFilter) || []
        } else if (timePeriodFilter === 'MTD' && viewByFilter !== 'All') {
            return graphModData?.mtdLine?.filter((item: any) => item.name === viewByFilter) || []
        }

        return rftDualLineGraphDataMap[filter] || [];
    };

    const handleMenuClickException: MenuProps["onClick"] = (selectedStatus: any) => {
        setRftViewBy(selectedStatus?.key)
    };

    const handleMenuClickDeviation: MenuProps["onClick"] = (selectedStatus: any) => {
        setRftViewBy1(selectedStatus?.key)
    };

    const isAllOrAdherence = (
        minDevTableTimePeriod === "All" || 
        minDevTableTimePeriod === "Overdue" ||
        minDevTableTimePeriod === "Coming Due" ||
        minDevTableTimePeriod === "Adherence" || 
        minDevTableTimePeriod === "Open" ||
        minDevTableTimePeriod === "Extensions"
    )

    const isAllOrAdherence2 = (
        minDevTable2TimePeriod === "All" ||
        minDevTable2TimePeriod === "Overdue" ||
        minDevTable2TimePeriod === "Coming Due" ||
        minDevTable2TimePeriod === "Open" ||
        minDevTable2TimePeriod === "Extensions"
    )

    const tableDataMapping: any = {
        All: tableDataAll,
        Adherence: tableDataAdherence,
        Extensions: tableDataAging,
        Open: tableDataOpen,
    };
    
    const capaTableDataMapping: any = {
        All: CAPAtableDataAll,
        Overdue: CAPAtableDataOverdue,
        'Coming Due': CAPAtableDataComingDue,
        Extensions: CAPAtableDataAging,
        Open: CAPAtableDataOpen,
    };
    
    const capaEVTableDataMapping: any = {
        All: CAPAEVtableDataAll,
        Overdue: CAPAEVtableDataOverdue,
        'Coming Due': CAPAEVtableDataComingDue,
        Extensions: CAPAEVtableDataAging,
        Open: CAPAEVtableDataOpen,
    };

    const getTableData = (isAllOrAdherence: boolean, minDevTableTimePeriod: string) => {
        if (isAllOrAdherence) {
            return tableDataMapping[minDevTableTimePeriod] ?? [];
        }

        return []
    };
    
    const getCAPATableData = (isAllOrAdherence: boolean, minDevTableTimePeriod: string) => {
        if (isAllOrAdherence) {
            return capaTableDataMapping[minDevTableTimePeriod] ?? [];
        }

        return []
    };
    
    const getCapaEvTableData = (isAllOrAdherence: boolean, minDevTableTimePeriod: string) => {
        if (isAllOrAdherence) {
            return capaEVTableDataMapping[minDevTableTimePeriod] ?? [];
        }

        return []
    };

    const majorDeviationTableData = getTableData(isAllOrAdherence, minDevTableTimePeriod);
    const minorDeviationTableData = getTableData(isAllOrAdherence, minDevTableTimePeriod);
    const changeControlTableData = getTableData(isAllOrAdherence, minDevTableTimePeriod);
    const CapaStatusTableData = getCAPATableData(isAllOrAdherence2, minDevTableTimePeriod);
    const CapaEVTableData = getCapaEvTableData(isAllOrAdherence2, minDevTable2TimePeriod);

    const qualityDeepDive: any = {
        "Major Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            titleAdherence: "% of Major Deviation Adherence",
            subtitleAdherence: "",
            graphRadioFiltersAdherence: (
                <RadioFiltersGraph
                    timePeriod={graphTimePeriod}
                    setTimePeriod={setGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAdMtd: (
                <StackedBarChart
                    data={adherenceGraphDataMtd}
                    chartType="major-deviation-adherence-mtd"
                    xAxisData={adherenceGraphDataMtd?.map((item: any) => formatDayMonth(item))}
                    yAxisNames={[
                        { name: "TOTAL DUE MAJOR\nDEVIATION IN REVIEW PERIOD", position: "left", max: newMinMaxValue(adherenceGraphDataMtd)?.Max },
                        { name: "% OF MAJOR DEVIATION ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            stackBarAd6m: (
                <StackedBarChart
                    data={adherenceGraphData6m}
                    chartType="major-deviation-adherence-6m"
                    xAxisData={adherenceGraphData6m?.map((item: any) => NewformatMonthYear(item))}
                    yAxisNames={[
                        { name: "TOTAL DUE MAJOR\nDEVIATION IN REVIEW PERIOD", position: "left", max: newMinMaxValue(adherenceGraphData6m)?.Max },
                        { name: "% OF MAJOR DEVIATION ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            titleAging: "% of Extensions Major Deviation",
            subtitleAging: "",
            graphRadioFiltersAging: (
                <RadioFiltersGraph
                    timePeriod={agingGraphTimePeriod}
                    setTimePeriod={setAgingGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAgingMtd: (
                <StackedBarChart
                    data={agingOpenGraphDataMtd}
                    chartType="major-deviation-aging-mtd"
                    xAxisData={agingOpenGraphDataMtd?.map((item: any) => formatDayMonth(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN MAJOR DEVIATION", position: "left", max: newMinMaxValue(agingOpenGraphDataMtd)?.Max },
                        { name: "% OF EXTENSIONS MAJOR DEVIATION", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            stackBarAging6m: (
                <StackedBarChart
                    data={agingOpenGraphData6m}
                    chartType="major-deviation-aging-6m"
                    xAxisData={agingOpenGraphData6m?.map((item: any) => NewformatMonthYear(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN MAJOR DEVIATION", position: "left", max: newMinMaxValue(agingOpenGraphData6m)?.Max },
                        { name: "% OF EXTENSIONS MAJOR DEVIATION", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            title2: "Major Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                />
            ),
            button: (
                <Button
                    label="Download"
                    className={((!majorDeviationTableData) || (majorDeviationTableData.length === 0)) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (majorDeviationTableData && majorDeviationColumns) {
                            const csvContent = generateCSV(majorDeviationTableData, majorDeviationColumns);
                            downloadCSV(csvContent, "Major_Deviation_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!majorDeviationTableData) || (majorDeviationTableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={majorDeviationTableData}
                tableColumns={majorDeviationColumns} />,
        },
        "Minor Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            titleAdherence: "% of Minor Deviation Adherence",
            subtitleAdherence: "",
            graphRadioFiltersAdherence: (
                <RadioFiltersGraph
                    timePeriod={graphTimePeriod}
                    setTimePeriod={setGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAdMtd: (
                <StackedBarChart
                    data={adherenceGraphDataMtd}
                    chartType="minor-deviation-adherence-mtd"
                    xAxisData={adherenceGraphDataMtd?.map((item: any) => formatDayMonth(item))}
                    yAxisNames={[
                        { name: "TOTAL DUE MINOR\nDEVIATION IN REVIEW PERIOD", position: "left", max: newMinMaxValue(adherenceGraphDataMtd)?.Max },
                        { name: "% OF MINOR DEVIATION ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            stackBarAd6m: (
                <StackedBarChart
                    data={adherenceGraphData6m}
                    chartType="minor-deviation-adherence-6m"
                    xAxisData={adherenceGraphData6m?.map((item: any) => NewformatMonthYear(item))}
                    yAxisNames={[
                        { name: "TOTAL DUE MINOR\nDEVIATION IN REVIEW PERIOD", position: "left", max: 100 },
                        { name: "% OF MINOR DEVIATION ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            titleAging: "% of Extensions Minor Deviation",
            subtitleAging: "",
            graphRadioFiltersAging: (
                <RadioFiltersGraph
                    timePeriod={agingGraphTimePeriod}
                    setTimePeriod={setAgingGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAgingMtd: (
                <StackedBarChart
                    data={agingOpenGraphDataMtd}
                    chartType="minor-deviation-aging-mtd"
                    xAxisData={agingOpenGraphDataMtd?.map((item: any) => formatDayMonth(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN MINOR DEVIATIONS", position: "left", max: newMinMaxValue(agingOpenGraphDataMtd)?.Max },
                        { name: "% OF EXTENSIONS MINOR DEVIATIONS", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            stackBarAging6m: (
                <StackedBarChart
                    data={agingOpenGraphData6m}
                    chartType="minor-deviation-aging-6m"
                    xAxisData={agingOpenGraphData6m?.map((item: any) => NewformatMonthYear(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN MINOR DEVIATIONS", position: "left", max: newMinMaxValue(agingOpenGraphData6m)?.Max },
                        { name: "% OF EXTENSIONS MINOR DEVIATIONS", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            title2: "Minor Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                />
            ),
            button: (
                <Button
                    label="Download"
                    className={((!minorDeviationTableData) || (minorDeviationTableData.length === 0)) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (minorDeviationTableData && minorDeviationColumns) {
                            const csvContent = generateCSV(minorDeviationTableData, minorDeviationColumns);
                            downloadCSV(csvContent, "Minor_Deviation_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!minorDeviationTableData) || (minorDeviationTableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={minorDeviationTableData}
                tableColumns={minorDeviationColumns} />
        },
        "GXP Training": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            heatmapTitle: "Compliance Rate (%)",
            heatmap: (
                <ColorCodedTable
                    data={heatmapData}
                    metricKey="gxp"
                />
            )
        },
        "Change Control": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            titleAdherence: "% of Change Control Adherence",
            graphRadioFiltersAdherence: (
                <RadioFiltersGraph
                    timePeriod={graphTimePeriod}
                    setTimePeriod={setGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAdMtd: (
                <StackedBarChart
                    data={adherenceGraphDataMtd}
                    chartType="change-control-adherence-mtd"
                    xAxisData={adherenceGraphDataMtd?.map((item: any) => formatDM(item?.compliance_date))}
                    yAxisNames={[
                        { name: "TOTAL DUE CHANGE\nCONTROLS IN REVIEW PERIOD", position: "left", max: newMinMaxValue(adherenceGraphDataMtd)?.Max },
                        { name: "% OF CHANGE CONTROL ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            stackBarAd6m: (
                <StackedBarChart
                    data={adherenceGraphData6m}
                    chartType="change-control-adherence-6m"
                    xAxisData={adherenceGraphData6m?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "TOTAL DUE CHANGE\nCONTROLS IN REVIEW PERIOD", position: "left", max: newMinMaxValue(adherenceGraphData6m)?.Max },
                        { name: "% OF CHANGE CONTROL ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            titleAging: "% of Extensions Change Controls",
            graphRadioFiltersAging: (
                <RadioFiltersGraph
                    timePeriod={agingGraphTimePeriod}
                    setTimePeriod={setAgingGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAgingMtd: (
                <StackedBarChart
                    data={agingOpenGraphDataMtd}
                    chartType="change-control-aging-mtd"
                    xAxisData={agingOpenGraphDataMtd?.map((item: any) => formatDM(item?.compliance_date))}
                    yAxisNames={[
                        { name: "TOTAL OPEN CHANGE CONTROLS", position: "left", max: newMinMaxValue(agingOpenGraphDataMtd)?.Max },
                        { name: "% OF EXTENSIONS CHANGE CONTROLS", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            stackBarAging6m: (
                <StackedBarChart
                    data={agingOpenGraphData6m}
                    chartType="change-control-aging-6m"
                    xAxisData={agingOpenGraphData6m?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN CHANGE CONTROLS", position: "left", max: newMinMaxValue(agingOpenGraphData6m)?.Max },
                        { name: "% OF EXTENSIONS CHANGE CONTROLS", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            title2: "Change Control",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                />
            ),
            button: (
                <Button
                    label="Download"
                    className={((!changeControlTableData) || (changeControlTableData.length === 0)) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (changeControlTableData && ChangeControlColumns) {
                            const csvContent = generateCSV(changeControlTableData, ChangeControlColumns);
                            downloadCSV(csvContent, "Change_Control_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!changeControlTableData) || (changeControlTableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={changeControlTableData}
                tableColumns={ChangeControlColumns} />
        },
        "CAPA Status": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            titleAdherence: "% of CAPA Adherence",
            graphRadioFiltersAdherence: (
                <RadioFiltersGraph
                    timePeriod={graphTimePeriod}
                    setTimePeriod={setGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAdMtd: (
                <StackedBarChart
                    data={capaAdherenceMtdGraph}
                    chartType="capa-status-mtd"
                    xAxisData={capaAdherenceMtdGraph?.map((item: any) => formatDM(item?.compliance_date))}
                    yAxisNames={[
                        { name: "TOTAL DUE CAPAS IN REVIEW PERIOD", position: "left", max: newMinMaxValue(capaAdherenceMtdGraph)?.Max },
                        { name: "% OF CAPA ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            stackBarAd6m: (
                <StackedBarChart
                    data={capaAdherence6Month}
                    chartType="capa-status-adherence-6m"
                    xAxisData={capaAdherence6Month?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "TOTAL DUE CAPAS IN REVIEW PERIOD", position: "left", max: newMinMaxValue(capaAdherence6Month)?.Max },
                        { name: "% OF CAPA ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            titleAging: "% of Extensions CAPAs",
            graphRadioFiltersAging: (
                <RadioFiltersGraph
                    timePeriod={agingGraphTimePeriod}
                    setTimePeriod={setAgingGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAgingMtd: (
                <StackedBarChart
                    data={capaAgingMtd}
                    chartType="capa-status-aging-mtd"
                    xAxisData={capaAgingMtd?.map((item: any) => formatDM(item?.compliance_date))}
                    yAxisNames={[
                        { name: "TOTAL OPEN CAPAS", position: "left", max: newMinMaxValue(capaAgingMtd)?.Max },
                        { name: "% OF EXTENSIONS CAPAS", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            stackBarAging6m: (
                <StackedBarChart
                    data={capaAging6Month}
                    chartType="capa-status-aging-6m"
                    xAxisData={capaAging6Month?.map((item: any) => formatMTDDate(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN CAPAS", position: "left", max: newMinMaxValue(capaAging6Month)?.Max },
                        { name: "% OF EXTENSIONS CAPAS", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            title2: "CAPA",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                />
            ),
            button: (
                <Button
                    label="Download"
                    className={((!CapaStatusTableData) || (CapaStatusTableData.length === 0)) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (CapaStatusTableData && CapaStatusColumns) {
                            const csvContent = generateCSV(CapaStatusTableData, CapaStatusColumns);
                            downloadCSV(csvContent, "CAPA_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!CapaStatusTableData) || (CapaStatusTableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={CapaStatusTableData}
                tableColumns={CapaStatusColumns} />,
            title3: "CAPA EV",
            radioFilters3: (
                <RadioFilters
                    timePeriod={minDevTable2TimePeriod}
                    setTimePeriod={setMinDevTable2TimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button3: (
                <Button
                    label="Download"
                    className={((!CapaEVTableData) || (CapaEVTableData.length === 0)) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (CapaEVTableData && CapaEVColumns) {
                            const csvContent = generateCSV(CapaEVTableData, CapaEVColumns);
                            downloadCSV(csvContent, "CapaEV_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!CapaEVTableData) || (CapaEVTableData.length === 0)) ? true : false}
                />
            ),
            table3: <AohTable
                tableData={CapaEVTableData}
                tableColumns={CapaEVColumns} />
        },
        "Right First Time": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            title4: "Average Number of Exceptions Per Batch",
            subtitle4: "Distribution Range 30%-80%",
            title5: "Average Number of Deviations Per Closed Batch",
            subtitle5: "Distribution Range 30%-80%",
            graphRadioFiltersRft: (
                <RadioFilters
                    customLabel="TIME PERIOD :"
                    timePeriod={rftTimePeriod}
                    setTimePeriod={setRftTimePeriod}
                    tierLabel={tierLabel}
                    timePeriodData={["MTD", 'Last 6 Months']}
                />
            ),
            graphViewByFiltersRft: (
                <AohDropdown
                    items={rftDropdownValueException}
                    title="VIEW BY "
                    dropOptions={rftViewBy}
                    handleMenuClick={handleMenuClickException}
                />
            ),
            lineGraph: (
                <DualLineAreaGraph
                    config={{
                        areaKey: 'minMax',
                        xAxisKey: (rftViewBy || '').toLowerCase() === 'all' ? 'name' : 'dateValue',
                        lineKey: 'lineValue',
                        xAxis: { name: 'DISTRIBUTION RANGE 30%-80%' },
                    }}
                    data={getDualGraphData(
                        `${rftTimePeriod} ${rftViewBy}`,
                        'exceptionGraphData',
                        rftViewBy,
                        rftTimePeriod
                    ) || []} />
            ),
            graphRadioFiltersRft1: (
                <RadioFilters
                    customLabel="TIME PERIOD :"
                    timePeriod={rftTimePeriod1}
                    setTimePeriod={setRftTimePeriod1}
                    tierLabel={tierLabel}
                    timePeriodData={["MTD", 'Last 6 Months']}
                />
            ),
            graphViewByFiltersRft1: (
                <AohDropdown
                    items={rftDropdownValueDeviation}
                    title="VIEW BY "
                    dropOptions={rftViewBy1}
                    handleMenuClick={handleMenuClickDeviation}
                />
            ),
            lineGraph1: (
                <DualLineAreaGraph
                    config={{
                        areaKey: 'minMax',
                        xAxisKey: (rftViewBy1 ?? '').toLowerCase() === 'all' ? 'name' : 'dateValue',
                        lineKey: 'lineValue',
                        xAxis: { name: 'DISTRIBUTION RANGE 30%-80%' },
                    }}
                    data={getDualGraphData(
                        `${rftTimePeriod1} ${rftViewBy1}`,
                        'deviationGraphData',
                        rftViewBy1,
                        rftTimePeriod1
                    ) || []} />
            ),
        }

    }

    return (
        <div className="quality-tier-main">
            {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {/* Summary card */}
                    {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                </h2>
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.summary}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                }
            ></div>
            {/* Heatmap Card */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle}
                </h2>
            </div>
            )}
            {/* main heatmap component */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmap}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.heatmap ||
                        qualityDeepDive[activeKeyDeepDiveTab]?.table
                        ? "mr-bottom-20"
                        : ""
                }
            ></div>
            {/* Adherence Graph */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.titleAdherence && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.titleAdherence}
                </h2>
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.graphRadioFiltersAdherence}
            {graphTimePeriod === "MTD" ? <div>  {qualityDeepDive[activeKeyDeepDiveTab]?.stackBarAdMtd}</div> : <div>  {qualityDeepDive[activeKeyDeepDiveTab]?.stackBarAd6m}</div>}
            {/* Aging Graph */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.titleAging && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.titleAging}
                </h2>
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.graphRadioFiltersAging}
            {agingGraphTimePeriod === "MTD" ? <div>  {qualityDeepDive[activeKeyDeepDiveTab]?.stackBarAgingMtd}</div> : <div>  {qualityDeepDive[activeKeyDeepDiveTab]?.stackBarAging6m}</div>}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title4 && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title4}
                </h2>
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle4 && ( 
            <div className="tier-subtitle" style={{ marginBottom: "14px" }}>
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle4}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.graphRadioFiltersRft && ( 
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {qualityDeepDive[activeKeyDeepDiveTab]?.graphRadioFiltersRft}
                {qualityDeepDive[activeKeyDeepDiveTab]?.graphViewByFiltersRft}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.lineGraph}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title5 && ( 
            <div className="tier-container" style={{ marginTop: "20px" }}>
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title5}
                </h2>
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle5 && ( 
            <div className="tier-subtitle" style={{ marginBottom: "14px" }}>
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle5}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.graphRadioFiltersRft1 && ( 
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {qualityDeepDive[activeKeyDeepDiveTab]?.graphRadioFiltersRft1}
                {qualityDeepDive[activeKeyDeepDiveTab]?.graphViewByFiltersRft1}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.lineGraph1}
            {qualityDeepDive[activeKeyDeepDiveTab]?.component}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title2 && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title2}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle && ( 
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title3 && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title3}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button3}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle3 && ( 
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle3}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters3}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table3}
        </div>
    );
};
export default QualityTier3
