import "./Productivity.scss";
import { abnormalScrapddvColumns, DataTypeKey, ProductivityDdvT2TableColumns } from "utils/wctConstants";
import RenderTierWiseComponent from "../delivery-tierwise/RenderTierWiseComponent";
import { useEffect, useState } from "react";
import { StringKeyDataProps } from "utils/data-types";
import { APIResponseDataType, TableType } from "types/wctTypes";
import { downloadCSV, filterbyDataType, generateCSV, getFormattedMonthYearV2 } from "utils/common-methods";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";
import { fetchOverallQuipmentEfficiencyDeepDive } from "reducers/productivity-deep-dive/overallEquipmentEfficiencyAllDeepDiveReducer";
import WaterfallChart from "components/common/waterfall-chart/WaterFallChart";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { newMinMaxValue } from "components/common/StackedBar/utils";
import AohDropdown from "components/common/dropdown/aohdropdown";
import { fetchmajorEqDowntimeDdv } from "reducers/productivity-deep-dive/majorEqDowntimeDdvReducer";

interface ProductivityTier2Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const ProductivityTier2: React.FC<ProductivityTier2Props> = ({
    data,
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const [tableData, setTableData] = useState<StringKeyDataProps[]>()
    const allTables: APIResponseDataType[] = filterbyDataType<TableType>(data, DataTypeKey.IsTable)
    const [filter, setFilter] = useState<any>({})

    const [filteSelect, setFilterSelect] = useState<{ [key: string]: any }>({
      majorEqDowntime: 'All'
    })
    const title = data[0]?.graphParams?.title;
    useEffect(() => {
        setTableData(Array.isArray(allTables?.[0]?.data) ? allTables[0].data : [allTables?.[0]?.data ?? []]);
    }, [tableData])
    const dispatch = useDispatch<AppDispatch>();
    const [weekFilter, setWeekFilter] = useState<string>('All')
    const [lineFilter, setLineFilter] = useState<string>('All')

    const handleMenuClick = (selected: any, filterType: string, filterValue?: string) => {

        setFilterSelect(prev => ({ ...prev, [filterType]: selected.key }));
        const params = {
          workStream: "manufacturing",
          site: "AOH",
          persona: "wct",
          tierNumber: "tier 2",
          filterType,
          filterValue,
          line: selected.key
        }
        dispatch(fetchmajorEqDowntimeDdv(params as any));
      }

    useEffect(() => {
        const params = {
            workStream: "manufacturing",
            site: "AOH",
            persona: "wct",
            tierNumber: "tier 2",
            selectedline: lineFilter
        }
        dispatch(fetchOverallQuipmentEfficiencyDeepDive(params))
    }, [lineFilter, tierLabel])

    const productivityDeepDive: any = {
        "OEE 1 and OEE2 and OEE3": {
            title: "OEE Values Over Time per Line",
            chart: (
                <StackedBarChart
                    data={data?.[0]?.data}
                    chartType={data?.[0]?.filterType}
                    xAxisData={data?.[0]?.data?.map((item: any) => item?.month ? getFormattedMonthYearV2(item?.month) : getFormattedMonthYearV2(item?.test))}
                    yAxisNames={data?.[0]?.graphParams?.yAxisNames?.filter((item: any) => item.position?.toLowerCase() !== 'right')?.map((item: any) => ({ ...item, name: item.name?.toUpperCase(), max: newMinMaxValue(data?.data)?.Max }))}
                    yAxisNameStyleConfig={activeKeyDeepDiveTab === "OEE 1 and OEE2 and OEE3" ? { fontSize: 10, fontWeight: 700 } : undefined}
                    xAxisNames={activeKeyDeepDiveTab === "OEE 1 and OEE2 and OEE3" ? [{ name: 'LAST 4 WEEKS', position: 'bottom', nameGap: 35 }] : []}
                    xAxisNameStyleConfig={activeKeyDeepDiveTab === "OEE 1 and OEE2 and OEE3" ? { fontSize: 10, fontWeight: 700 } : undefined}
                />
            ),
            title1: "Waterfall Plot with Annotation",
            dropdown: (
                <div>
                    <div style={{ display: 'flex', marginBottom: '20px', gap: '8px' }}>
                        <AohDropdown
                            items={data?.[1]?.extraFilterData?.map((item: any) => ({
                                key: item.line,
                                label: item.line
                            }))}
                            title={'LINES'}
                            dropOptions={lineFilter === 'all' ? 'All' : lineFilter}
                            handleMenuClick={(evt: any) => { setLineFilter(evt.key) }}
                        />
                        <div style={{ display: "flex", alignItems: "center", color: "#8C8C8C", fontFamily: "Inter", fontSize: "12px", fontWeight: "400", lineHeight: "18px", textAlign: "center" }}>
                            <span style={{ gap: "3px" }}><span style={{ fontWeight: "bold" }}>Note: </span>&apos;LINES&apos; filter would be applicable to both &apos;OEE Values Over Time per Line&apos; and &apos;Waterfall Plot with Annotation&apos;</span>
                        </div>
                    </div>
                </div>
            ),
            chart1: (
                <WaterfallChart waterFallData={data?.[1]} activeKeyDeepDiveTab={activeKeyDeepDiveTab} filter={weekFilter} 
                chartHeight="300px" yAxisTitle={"ALL WEEKS"} />
            ),
            dropdown1: (
                <div>
                    <div style={{ display: 'flex', marginBottom: '20px', gap: '8px' }}>
                        <AohDropdown
                            items={[{ key: 'All', label: 'All' }, ...((data?.[1]?.extraFilterData2 || []).map((item: any) => ({
                                key: item.date,
                                label: item.date
                            })) || [])]}
                            title={'WEEK'}
                            dropOptions={weekFilter === 'All' ? 'All' : weekFilter}
                            handleMenuClick={(evt: any) => { setWeekFilter(evt.key) }}
                            disableButtonMinWidth={true}
                        />
                    </div>
                </div>
            ),
        }
    }

    if (activeKeyDeepDiveTab === "OEE 1 and OEE2 and OEE3") {
        return (
            <div className="productivity-tier-main">
                <div
                    className={
                        productivityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                    }
                ></div>
                <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: "column", overflow: "hidden" }}>
                   {productivityDeepDive[activeKeyDeepDiveTab]?.title && (
                    <div>
                        <div className="tier-container">
                            <h2 className="tier-title">
                                {productivityDeepDive[activeKeyDeepDiveTab]?.title}
                            </h2>
                        </div>
                        {productivityDeepDive[activeKeyDeepDiveTab]?.dropdown}
                        <div>
                            {productivityDeepDive[activeKeyDeepDiveTab]?.chart}
                        </div>
                    </div>
                   )}
                   {productivityDeepDive[activeKeyDeepDiveTab]?.title1 && (
                    <div>
                        <h2 className="tier-title">
                            {productivityDeepDive[activeKeyDeepDiveTab]?.title1}
                        </h2>
                        {productivityDeepDive[activeKeyDeepDiveTab]?.dropdown1}
                        <div>
                            {productivityDeepDive[activeKeyDeepDiveTab]?.chart1}
                        </div>
                    </div>
                   )}
                </div>
            </div>
        )
    } else {
        return (<div className="productivity-tier-main">
            {data?.map((item: any, index: number | null | undefined) => {
                return <RenderTierWiseComponent
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    tierLabel={tierLabel}
                    key={index}
                    data={item}
                    deepDiveTableColumns={ProductivityDdvT2TableColumns}
                    handleMenuClick={handleMenuClick}
                    extraFilterselect={filteSelect}
                    activeFilter={filter}
                />
            })}
        </div>)
        // }
    }
};
export default ProductivityTier2;