import { Tabs } from 'antd';
import styled from '@emotion/styled';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0 0 24px 0;
    padding: 0 16px;
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
  }

  .ant-tabs-tab {
    color: #333;
    font-size: 25px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 4px;
  }

  .ant-tabs-tab-active {
    color: #1DA57A;
  }

  .ant-tabs-tab:hover {
    color: #1DA57A;
  }

  .ant-tabs-tab-disabled {
    color: #d9d9d9;
  }
`;

export default StyledTabs;