import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, CAPA, METRICS, SAFETY, VERSION, WORK_CENTER_TEAM, AGING_WORKORDER, DELIVERY } from 'utils/constants';

interface AgingWorkorderInfoState {
  agingWorkorderInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: AgingWorkorderInfoState = {
  agingWorkorderInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, DELIVERY, BASE_VIEW, AGING_WORKORDER];
const fetchAgingWorkorder = fetchApi(segments);

const agingWorkorderReducer = createSlice({
    name: 'agingWorkorder',
    initialState,
    reducers: {
        clearAgingWorkorderData(state) {
            state.agingWorkorderInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgingWorkorder.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchAgingWorkorder.fulfilled, (state, action) => {
                state.loading = false;
                state.agingWorkorderInfo = action.payload
            })
            .addCase(fetchAgingWorkorder.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchAgingWorkorder };

export const { clearAgingWorkorderData } = agingWorkorderReducer.actions

export default agingWorkorderReducer.reducer;