import AohDropdown from "components/common/dropdown/aohdropdown";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { newMinMaxValue } from "components/common/StackedBar/utils";
import SummaryCard from "components/common/summaryCard/summaryCard";
import AohTable from "components/common/table/AohTable";
import { DualLineAreaGraph } from "components/resuable/charts-graph/dual-line-area-graph";
import RadioFiltersV2 from "components/resuable/radio-filters/RadioFiltersV2";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadCSV, generateCSV } from "utils/common-methods";
import { DEEP_DIVE_UI_CONSTANTS } from "utils/wctConstants";

const RenderTierWiseComponent = ({ data, setTableType, activeKeyDeepDiveTab, tierLabel, activeFilter, handleMenuClick, extraFilterselect, deepDiveFilters, deepDiveTableColumns }: { data: any, setTableType?: (filterType: any, value: any) => any, activeKeyDeepDiveTab: string, tierLabel: string, activeFilter?: any, handleMenuClick?: any, extraFilterselect?: any, deepDiveFilters?: any, deepDiveTableColumns: any }) => {
    const renderTitle = (title: string) => (
        <>
            <div className="tier-container">
                <h2 className="tier-title">{title}</h2>
                {data?.downloadButton && renderDownloadButton(data?.data, deepDiveTableColumns[data?.filterType as keyof typeof deepDiveTableColumns] || [], data?.graphParams?.title)}
            </div>
        </>);

    const renderSubtitle = (subtitle: string) => (
        <div className="tier-subtitle">{subtitle}</div>
    );

    const renderRadioFilters = (filterLabel: string, filterType: string, extraFilterLabel?: string, extraFilterData?: any) => (
        <>
            {data.graphParams.filterLabel && <RadioFiltersV2
                selectedFilter={activeFilter[filterType as keyof typeof activeFilter]}  // this has to be check once data param is fixed
                filterLabel={DEEP_DIVE_UI_CONSTANTS[filterLabel as keyof typeof DEEP_DIVE_UI_CONSTANTS]}
                radioFilter={deepDiveFilters[filterType as keyof typeof deepDiveFilters]}
                setRadioFilter={(value) => typeof setTableType === 'function' && setTableType(filterType, value)}
            />}
            {extraFilterLabel && <AohDropdown
                items={extraFilterData}
                title={extraFilterLabel}
                dropOptions={extraFilterselect[filterType as keyof typeof deepDiveFilters]}
                handleMenuClick={(selected) => handleMenuClick(selected, filterType, activeFilter[filterType])}
            />}
        </>

    );

    const renderDownloadButton = (data: any[], columns: any[], fileName: string) => (
        <Button
            label="Download"
            className={((!data) || (data.length === 0)) ? "" : "download-btn"}
            icon={
                <DownloadOutlined style={((!data) || (data.length === 0)) ? { color: "", fontSize: "14px" } : { color: "#0063C3", fontSize: "14px" }} />
            }
            iconPosition="right"
            onClick={() => {
                if (data && columns) {
                    const csvContent = generateCSV(data, columns);
                    downloadCSV(csvContent, `${fileName}.csv`);
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={((!data) || (data.length === 0)) ? true : false} 
        />
    )

    if (data?.isSummary) {
        return (
            <>
                {data?.graphParams?.title && renderTitle(data.graphParams.title)}
                {data?.graphParams?.subTitle && renderSubtitle(data.graphParams.subTitle)}
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={data?.data}
                />
                <div className={"mr-bottom-20"}></div>
            </>
        );
    }

    if (data.isTable) {
        return (
            <>
                {data?.graphParams?.title && renderTitle(data.graphParams.title)}

                {data?.graphParams?.subTitle && renderSubtitle(data.graphParams.subTitle)}
                <div style={{ display: "flex", justifyContent: "space-between", margin: data?.filterType === "oee3Line" || data?.filterType === "scrap" || data?.filterType === "majorEqDowntime" || data?.filterType === "setupClosedownAdherence" ? "11px 0px 0px -14px" : "0px 0px 0px 0px" }}>
                    {renderRadioFilters(data.graphParams.filterLabel, data.filterType, data?.extraFilterLabel, data?.extraFilterData?.map((i: { line: any; }) => ({ key: i.line, label: i.line })))}
                </div>
                <AohTable
                    tableData={data?.data}
                    tableColumns={deepDiveTableColumns[data?.filterType as keyof typeof deepDiveTableColumns] || []}
                />
            </>
        );
    }

    if (data.isStackBarChart) {
        return (
            <>
                {data?.graphParams?.title && renderTitle(data.graphParams.title)}
                {data?.graphParams?.subTitle && renderSubtitle(data.graphParams.subTitle)}
                {data?.graphParams?.filterLabel && <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {renderRadioFilters(data.graphParams.filterLabel, data.filterType, data?.extraFilterLabel, data?.extraFilterData?.map((i: { line: any; }) => ({ key: i.line, label: i.line })))}
                </div>
                }
                <StackedBarChart
                    data={data?.data}
                    chartType={data?.filterType}
                    xAxisData={data?.data?.map((item: any) => item?.date ?? item?.month ?? item?.test ?? item?.day)}
                    yAxisNames={data?.graphParams?.yAxisNames?.map((item: any) => ({ max: newMinMaxValue(data?.data)?.Max, ...item }))}
                    chartWidth= {activeKeyDeepDiveTab==="OEE 1 and OEE2 and OEE3"? "45%": "100%"}
                />
            </>
        );
    }

    if (data.isRunChart) {
        return (
            <>
                {data?.graphParams?.title && renderTitle(data.graphParams.title)}
                {data?.graphParams?.subTitle && renderSubtitle(data.graphParams.subTitle)}
                {data?.graphParams?.filterLabel && <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {renderRadioFilters(data.graphParams.filterLabel, data.filterType, data?.extraFilterLabel, data?.extraFilterData?.map((item: any) => ({ key: item.line, label: item.line })))}
                </div>
                }

                {/* // this has to be check once data param is fixed */}
                <DualLineAreaGraph
                    data={data?.data}
                    config={{
                        areaKey: 'range',
                        xAxisKey: data?.graphParams?.xAxisKey ?? 'month',
                        lineKey: data?.graphParams?.lineKey ?? 'avg',
                        xAxis: data?.graphParams?.xAxis,
                        yAxis: data?.graphParams?.yAxis
                    }}
                />
            </>
        );
    }
    return null;
};

export default RenderTierWiseComponent;