import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { CRITICAL_MFC, DEEP_DIVE_VIEW, INVENTORY, METRICS, WORK_CENTER_TEAM } from 'utils/constants';

interface CriticalMfcDeepDiveStateProps {
    briticalMfcDeepDiveInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: CriticalMfcDeepDiveStateProps = {
    briticalMfcDeepDiveInfo: null,
    loading: false,
    error: false
};

const segments = [WORK_CENTER_TEAM, METRICS, INVENTORY, DEEP_DIVE_VIEW, CRITICAL_MFC];
const fetchCriticalMfcDeepDive = fetchApi(segments);

const briticalMfcDeepDiveReducer = createSlice({
    name: 'briticalMfcDeepDive',
    initialState,
    reducers: {
        clearCriticalMfcDeepDiveData(state) {
            state.briticalMfcDeepDiveInfo = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCriticalMfcDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchCriticalMfcDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.briticalMfcDeepDiveInfo = action.payload;
            })
            .addCase(fetchCriticalMfcDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
});

export const { clearCriticalMfcDeepDiveData } = briticalMfcDeepDiveReducer.actions;
export { fetchCriticalMfcDeepDive };
export default briticalMfcDeepDiveReducer.reducer;
