import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { BATCH_DISPOSIOTION_DEEP_DIVE_URL, DEEP_DIVE_VIEW, OEE_123, METRICS, PRODUCTIVITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';
import { StringKeyAnyDataProps } from 'utils/data-types';

interface OverallQuipmentEfficiencyDeepDiveStateProps {
    overallQuipmentEfficiencyDeepDiveInfo: APIResponseDataType[] | null;
    overallQuipmentEfficiencyFilterData: StringKeyAnyDataProps[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: OverallQuipmentEfficiencyDeepDiveStateProps = {
    overallQuipmentEfficiencyDeepDiveInfo: null,
    overallQuipmentEfficiencyFilterData: null,
    loading: false,
    error: false
};

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, PRODUCTIVITY, DEEP_DIVE_VIEW, OEE_123];

const fetchOverallQuipmentEfficiencyDeepDive = fetchApi(segments, false);

const fetchOverallQuipmentEfficiencyFilterData = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    `${segments.join}/filter`,
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${segments.join('')}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch radio filter data');
        }
        return await response.json();
    }
);

const handleOverallQuipmentEfficiencyFilterData = (oldState: { overallQuipmentEfficiencyDeepDiveInfo: any[]; }, filerData: { filterType: any; data: any; }) => {
    const state = oldState.overallQuipmentEfficiencyDeepDiveInfo
    state?.map((item: { filterType: any; data: any; }) => {
        if (filerData?.filterType === item.filterType) {
            item.data = filerData.data
        }
        return item
    });
    return state

}

const overallQuipmentEfficiencyDeepDiveReducer = createSlice({
    name: 'overallQuipmentEfficiencyDeepDive',
    initialState,
    reducers: {
        clearOverallQuipmentEfficiencyDeepDiveData(state) {
            state.overallQuipmentEfficiencyDeepDiveInfo = null;
            state.overallQuipmentEfficiencyFilterData = null;
        },

        clearOverallQuipmentEfficiencyFilterData(state) {
            state.overallQuipmentEfficiencyFilterData = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOverallQuipmentEfficiencyDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchOverallQuipmentEfficiencyDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.overallQuipmentEfficiencyDeepDiveInfo = action.payload;
            })
            .addCase(fetchOverallQuipmentEfficiencyDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchOverallQuipmentEfficiencyFilterData.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchOverallQuipmentEfficiencyFilterData.fulfilled, (state, action) => {
                state.loading = false;
                state.overallQuipmentEfficiencyDeepDiveInfo = handleOverallQuipmentEfficiencyFilterData(state as any, action.payload as any);
            })
            .addCase(fetchOverallQuipmentEfficiencyFilterData.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    }
});

export const { clearOverallQuipmentEfficiencyDeepDiveData } = overallQuipmentEfficiencyDeepDiveReducer.actions;
export { fetchOverallQuipmentEfficiencyDeepDive, fetchOverallQuipmentEfficiencyFilterData };
export default overallQuipmentEfficiencyDeepDiveReducer.reducer;
