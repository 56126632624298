import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { BASE_VIEW, INVENTORY, KANBAN_HEALTH, METRICS, VERSION, WORK_CENTER_TEAM } from 'utils/constants'

interface KanbanHealthInfoState {
    kanbanHealthBVInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: KanbanHealthInfoState = {
    kanbanHealthBVInfo: null,
    loading: false,
    error: false
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, INVENTORY, BASE_VIEW, KANBAN_HEALTH];
const fetchKanBanHealthBV = fetchApi(segments);

const kanbanHealthBVReducer = createSlice({
    name: 'kanbanHealthBV',
    initialState,
    reducers: {
        clearKanBanHealthBVData(state) {
            state.kanbanHealthBVInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchKanBanHealthBV.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchKanBanHealthBV.fulfilled, (state, action) => {
                state.loading = false;
                state.kanbanHealthBVInfo = action.payload;
            })
            .addCase(fetchKanBanHealthBV.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchKanBanHealthBV };

export const { clearKanBanHealthBVData } = kanbanHealthBVReducer.actions

export default kanbanHealthBVReducer.reducer;