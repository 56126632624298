import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, CRITICAL_MFC, INVENTORY, METRICS, VERSION, WORK_CENTER_TEAM } from 'utils/constants'

interface CriticalMfcState {
    criticalMfcInfo: APIResponseDataType[] | null;
    status: string;
    error: boolean;
}

const initialState: CriticalMfcState = {
    criticalMfcInfo: null,
    status: 'idle',
    error: false
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, INVENTORY, BASE_VIEW, CRITICAL_MFC];
const fetchCriticalMfc = fetchApi(segments);

const CriticalMfcSlice = createSlice({
    name: 'deliveryCriticalMfc',
    initialState,
    reducers: {
        clearCriticalMfcData(state) {
            state.criticalMfcInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCriticalMfc.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchCriticalMfc.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.criticalMfcInfo = action.payload
            })
            .addCase(fetchCriticalMfc.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchCriticalMfc }

export const { clearCriticalMfcData } = CriticalMfcSlice.actions

export default CriticalMfcSlice.reducer