import styled from '@emotion/styled';
import { Tag } from 'antd';

interface StyledTagProps {
  style?: React.CSSProperties;
}

const StyledTag = styled(Tag)<StyledTagProps>`
  color: #f0f0f0;
  background-color: #434343; /* default background color */
  padding: 0px 8px;
  height: 16px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 4px;
  font-weight: 700;
  font-family: 'Inter';
  ${(props) => props.style && { ...props.style }}
`;

export default StyledTag;
