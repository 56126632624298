import { useEffect, useRef, useState } from "react";
import styled from '@emotion/styled';

interface percentDetail {
  line: string;
  shift: string;
  shift_name: string;
  total_lag_ahead_hours: number;
}

interface ConfigType {
  containerWidth?: string;
  containerHeight?: string;
  containerBgColor?: string;
  containerBorderRadius?: string;
  containerMarginTop?: string;
  containerLeft?: string;
  barHeight?: string;
  barBorderRadius?: string;
  barOpacity?: number;
  onTimeValue?: number;
  onTimeLabel?: string;
  onTimeLabelFontSize?: string;
  onTimeLabelFontWeight?: string;
  onTimeLabelColor?: string;
  onTimeLabelMarginTop?: string;
  topShiftColor?: string;
  bottomShiftColor?: string;
  topShiftOpacity?: number;
  bottomShiftOpacity?: number;
  topLag?: number;
  topShift?: string;
  bottomLag?: number;
  bottomShift?: string;
}

interface ProgressType {
  data: percentDetail[];
  config?: ConfigType;
}

const HorizontalBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HorizontalProgressContainer = styled.div<{ config?: ConfigType }>`
  width: ${({ config }) => config?.containerWidth ?? '85%'};
  height: ${({ config }) => config?.containerHeight ?? '14px'};
  position: relative;
  background-color: ${({ config }) => config?.containerBgColor ?? '#cccccc'};
  border-radius: ${({ config }) => config?.containerBorderRadius ?? '2px'};
  margin-top: ${({ config }) => config?.containerMarginTop ?? '7px'};
  left: ${({ config }) => config?.containerLeft ?? '40px'};
`;

const ChildDivContainer = styled.div<{ color: string; opacity: number; config?: ConfigType }>`
  height: ${({ config }) => config?.barHeight ?? '14px'};
  background-color: ${({ color }) => color};
  border-radius: ${({ config }) => config?.barBorderRadius ?? '2px'};
  opacity: ${({ opacity }) => opacity};
`;

const HorizontalProgressBarWrapper = styled.div`
  display: flex;
  height: 14px;
  width: 100%;
  align-items: center; // Center children vertically
`;

const HorizontalProgressLabels = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 9px;
  font-weight: 700;
  color: #595959;
`;

const BlackLineContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
`;

const ProgressBlackLine = styled.div`
  position: absolute;
  top: -20.5px;
  height: 28px;
  width: 0.8px;
  background-color: #1F1F1F;
  border-radius: 4px !important;
`;

const OnTimeLabel = styled.div<{ config?: ConfigType }>`
  text-align: center;
  margin-top: ${({ config }) => config?.onTimeLabelMarginTop ?? '14px'};
  font-size: ${({ config }) => config?.onTimeLabelFontSize ?? '8px'};
  font-family: "Inter", sans-serif;
  font-weight: ${({ config }) => config?.onTimeLabelFontWeight ?? '600'};
  color: ${({ config }) => config?.onTimeLabelColor ?? '#000000'};
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  cursor: pointer;
`;

const LineLabel = styled.div`
  position: relative;
  top: 22.5px;
  font-weight: 700;
  font-size: 10px;
`;

const StyledHorizontalBulletChart: React.FC<ProgressType> = ({ data, config }) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const onTimeValue = config?.onTimeValue ?? 0;

  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth(currentWidth ?? 1);
  }, [containerRef]);

  const topLag = config?.topLag ?? data[0]?.total_lag_ahead_hours;
  const topShift = config?.topShift ?? data[0]?.shift_name;
  const bottomLag = config?.bottomLag ?? data[1]?.total_lag_ahead_hours;
  const bottomShift = config?.bottomShift ?? data[1]?.shift_name;

  const isBeforeOnTime = parseFloat(topLag.toString()) < onTimeValue;
  const isAfterOnTime = parseFloat(bottomLag.toString()) > onTimeValue;
  const topShiftColor = config?.topShiftColor ?? (isBeforeOnTime ? "#E01029" : isAfterOnTime ? "#2CC84D" : "#cccccc");
  const bottomShiftColor = config?.bottomShiftColor ?? topShiftColor;

  return (
    <ProgressBarContainer>
      <LineLabel>{data[0]?.line}</LineLabel>
      <HorizontalBarContainer>
        <HorizontalProgressContainer ref={containerRef} config={config}>
          {data.length > 0 && (
            <>
              <HorizontalProgressBarWrapper>
                <ChildDivContainer
                  color={topShiftColor}
                  opacity={config?.topShiftOpacity ?? (topShift === "previous" ? 0.3 : 1)}
                  config={config}
                  style={{
                    width: `${Math.abs(topLag) / 2}%`,
                    left: topLag > 0 ? `50%` : `${50 - Math.abs(topLag) / 2}%`,
                    position: "absolute",
                    zIndex: 0,
                    height: "6px",
                    overflow: "hidden",
                    top: "50%", // Center vertically
                    transform: "translateY(-50%)", // Center vertically
                  }}
                />
                <ChildDivContainer
                  color={bottomShiftColor}
                  opacity={config?.bottomShiftOpacity ?? (bottomShift === "previous" ? 0.3 : 1)}
                  config={config}
                  style={{
                    width: `${Math.abs(bottomLag) / 2}%`,
                    left: bottomLag > 0 ? `50%` : `${50 - Math.abs(bottomLag) / 2}%`,
                    position: "absolute",
                    zIndex: 100,
                    overflow: "hidden",
                  }}
                />
              </HorizontalProgressBarWrapper>
              <HorizontalProgressLabels>
                {data.map((data: percentDetail, i: number) => (
                  <BlackLineContainer key={i}>
                    <ProgressBlackLine />
                    <OnTimeLabel config={config}>{config?.onTimeLabel ?? "ON TIME"}</OnTimeLabel>
                  </BlackLineContainer>
                ))}
              </HorizontalProgressLabels>
            </>
          )}
        </HorizontalProgressContainer>
      </HorizontalBarContainer>
    </ProgressBarContainer>
  );
};

export default StyledHorizontalBulletChart;
