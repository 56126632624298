import AohTable from "../../common/table/AohTable";
import AohDropdown from "components/common/dropdown/aohdropdown";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Safety.scss";
import { useContext, useEffect, useState } from "react";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import AohTableWithLabels from "components/common/table/AohTableWithLabels";
import HorizontalBarChart from "components/common/HorizontalBar/HorizontalBar";
import {
  capaColumns,
  deepDiveStackedInterval,
  incaColumns,
  incaGlobalEhssColumns,
  seecardColumns,
  workOrderColumns,
} from "utils/constants";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { RadioFilters } from "components/resuable/radio-filters/RadioFilters";
import { MenuProps } from "antd";
import { fetchIncaTimePeriodDeepDive,fetchStatusDeepDive } from "reducers/safety-deep-dive/incaDeepDiveReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import ErrorHandler from "utils/error-handler";
import { StringKeyAnyDataProps } from "utils/data-types";
import { downloadCSV, filterDuplicateStrings, generateCSV } from "utils/common-methods";
import { AppContext } from "context/app-context";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "@gitlab-rtsensing/component-library";

interface SafetyTier2Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

interface HeaderDropDownType {
  key: string;
  label: string;
}

const SafetyTier2: React.FC<SafetyTier2Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
   const { appState } = useContext(AppContext);
  const [filteredByStatusData, setFilteredByStatusData] = useState(
    data?.inca?.inca_open_incidents
  );
  const [filteredByCostCenterData, setFilteredByCostCenterData] = useState(
    data?.ehss?.complianceRateData
  );

  const dispatch = useDispatch<AppDispatch>();

  const { incaTimePeriodInfo, loading, error } = useSelector((state: RootState) => state.incaDeepDive)

  const [currentStatus, setCurrentStatus] = useState<HeaderDropDownType>({
    key: "All",
    label: "All",
  });
  const [currentCostCenter, setCurrentCostCenter] =
    useState<HeaderDropDownType>({
      key: "All Cost Centers",
      label: "All Cost Centers",
    });
  const [incaTimePeriodData, setIncaTimePeriodData] = useState<StringKeyAnyDataProps>(data?.inca);

  const costCenterDropDownData: HeaderDropDownType[] = [
    { key: "All Cost Centers", label: "All Cost Centers" },
    ...Array.from(
      new Set(
        data?.ehss?.complianceRateData?.map(
          (item: { cost_center_name: string }) => item.cost_center_name
        )
      )
    )
      .filter(
        (costCenterName): costCenterName is string =>
          costCenterName !== undefined && costCenterName !== null
      )
      .map((uniqueCostCenter: string) => ({
        key: uniqueCostCenter,
        label: uniqueCostCenter,
      })),
  ];
  const statusDropDownData: HeaderDropDownType[] = [
    { key: "All", label: "All" },
    ...Array.from(
      new Set(
        data?.inca?.inca_open_incidents?.map(
          (item: { status: string }) => item.status
        )
      )
    )
      .filter(
        (status): status is string => status !== undefined && status !== null
      )
      .map((uniqueStatus: string) => ({
        key: uniqueStatus,
        label: uniqueStatus,
      })),
  ];

  const handleStatusClick: MenuProps["onClick"] = (selectedStatus: any) => {
    setCurrentStatus({ key: selectedStatus.key, label: selectedStatus.key });

    const newFilteredData =
      selectedStatus.key === "All"
        ? data?.inca?.inca_open_incidents
        : data?.inca?.inca_open_incidents.filter(
          (item: { status: string }) => item.status === selectedStatus.key
        );

    setFilteredByStatusData(newFilteredData);
  };

  const handleCostCenterClick: MenuProps["onClick"] = (
    selectedCostCenter: any
  ) => {
    setCurrentCostCenter({
      key: selectedCostCenter.key,
      label: selectedCostCenter.key,
    });

    const filteredData =
      selectedCostCenter.key === "All Cost Centers"
        ? data?.ehss?.complianceRateData
        : data?.ehss?.complianceRateData?.filter(
          (item: { cost_center_name: string }) =>
            item?.cost_center_name === selectedCostCenter.key
        );

    setFilteredByCostCenterData(filteredData);
  };

  // --- Stacked bar charts logic ----

  // Inca Incidents Chart

  const [timePeriod, setTimePeriod] = useState<string>("Last Week");

  const [FilterStatus,setFilterStatus]= useState("All");

  const filterDataByTimePeriod = (period: any) => {
    const params = {
      workStream: appState.workStream,
      site: appState.site,
      building: "AOH1",
      persona: appState.persona,
      tierNumber: tierLabel.toLowerCase(),
      interval: deepDiveStackedInterval[period]
    };
    dispatch(fetchIncaTimePeriodDeepDive(params));
  }

  useEffect(() => {
    if (incaTimePeriodInfo) {
      setIncaTimePeriodData(incaTimePeriodInfo);
    } else if (data?.inca) {
      setIncaTimePeriodData(data.inca);
    }
  }, [incaTimePeriodInfo, data?.inca]);

  let tableData: any;

  switch (FilterStatus) {
    case 'All':
      tableData = data?.inca?.tableDataAll;
      break;
    case 'Overdue':
      tableData = data?.inca?.tableDataOverdue;
      break;
    case 'Coming Due':
      tableData = data?.inca?.tableDataComingDue;
      break;
    default:
      tableData = data?.inca?.tableDataAll; // Fallback to last 30 days if the condition is invalid
  }

  // Extract xAxisData and map it separately
  let xAxisData: any = [];
  if (Array.isArray(incaTimePeriodData?.data)) {
    // First, extract the dates based on the xAxisKey or fallback to 'date_of_incident'
    const extractedDates = incaTimePeriodData?.data.map((item: any) => {
      const dateValue = item[incaTimePeriodData?.dataParams?.xAxisKey ?? 'date_of_incident'];
      return dateValue;
    });
      // Then, apply formatDayMonth to each extracted date value
    xAxisData = filterDuplicateStrings(extractedDates.map((dateValue: any) => {
   
      return dateValue
    }));
  }

  const safetyDeepDive: any = {
    "SEE Cards": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={data?.seeCards?.summary}
        />
      ),
      title: "See Cards Group by Type",
      table: (
        <StackedBarChart
          data={data?.seeCards?.see_trend_chart}
          chartType="see-cards"
        />
      ),
      component2: (
        <HorizontalBarChart
          title1="EHSS Program Area"
          title2="Behavior Observation Type"
          subtitle1="For Last 5 Days"
          subtitle2="For Last 5 Days"
          chartType="ehss"
          leftBarGraphData={data?.seeCards?.ehss_chart}
          rightBarGraphData={data?.seeCards?.see_chart} EHSSProgramData={[]} BehaviorObservationData={[]} />
      ),
      title3: "Open SEE Cards",
      button3: (
        <Button
            label="Download"
            className={(!data?.seeCards?.open_see_data || data?.seeCards?.open_see_data.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.seeCards?.open_see_data && seecardColumns) {
                    const csvContent = generateCSV(data?.seeCards?.open_see_data, seecardColumns);
                    downloadCSV(csvContent, "global_ehss_awareness.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.seeCards?.open_see_data || data?.seeCards?.open_see_data.length === 0) ? true : false}
        />
      ),      
      table3: (
        <AohTable
          tableData={data?.seeCards?.open_see_data}
          tableColumns={seecardColumns}
        />
      ),
    },
    "INCA Incidents": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={data?.inca?.summary}
        />
      ),
      title: "Number of Incidents",
      radioFilters: (
        <RadioFilters timePeriod={timePeriod} setTimePeriod={setTimePeriod} filterDataByTimePeriod={filterDataByTimePeriod} />
      ),
      stackedBar: (
        <StackedBarChart
          data={incaTimePeriodData?.inca_trend_chart ? incaTimePeriodData?.inca_trend_chart : incaTimePeriodData?.data}
          xAxisData={Array.isArray(incaTimePeriodData?.data) ? filterDuplicateStrings((incaTimePeriodData?.data)?.map((item: any) => item[incaTimePeriodData?.dataParams?.xAxisKey ?? 'date_of_incident'])) : []}
          chartConfig={incaTimePeriodData?.dataParams?.chartConfig ? incaTimePeriodData?.dataParams?.chartConfig : incaTimePeriodData?.dataParams?.trendChartParams?.chartConfig}
          yAxisMax={incaTimePeriodData?.dataParams?.yAxisMax ? incaTimePeriodData?.dataParams?.yAxisMax : incaTimePeriodData?.dataParams?.trendChartParams?.yAxisMax}
          showSlider={incaTimePeriodData?.dataParams?.showSlider ? incaTimePeriodData?.dataParams?.showSlider : incaTimePeriodData?.dataParams?.trendChartParams?.showSlider}
          grid={{ left: "2%" }}
          chartType="global"
        />
      ),
      title2: "Open Incident List",
      radioFilters2: (
          <RadioFilters
              timePeriod={FilterStatus}
              setTimePeriod={setFilterStatus}
              activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          />
      ),
      button2: (
        <Button
            label="Download"
            className={(!tableData || tableData.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (tableData && incaColumns) {
                    const csvContent = generateCSV(tableData, incaColumns);
                    downloadCSV(csvContent, "inca_incredients.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!tableData || tableData.length === 0) ? true : false}
        />
    ),
      table2: (
        <AohTableWithLabels
          tableData={tableData}
          tableColumns={incaColumns}
        />
      ),
      title3: "Global EHSS Awareness",
      button3: (
        <Button
            label="Download"
            className={(!data?.inca?.inca_global_ehss || data?.inca?.inca_global_ehss.length === 0) ? "" : "download-btn"}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.inca?.inca_global_ehss && incaGlobalEhssColumns) {
                    const csvContent = generateCSV(data?.inca?.inca_global_ehss, incaGlobalEhssColumns);
                    downloadCSV(csvContent, "global_ehss_awareness.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.inca?.inca_global_ehss || data?.inca?.inca_global_ehss.length === 0) ? true : false}
        />
      ),
      table3: (
        <AohTable
          tableData={data?.inca?.inca_global_ehss}
          tableColumns={incaGlobalEhssColumns}
        />
      ),
    },
    "Safety Critical Work Orders": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          data={data?.summary}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title: "Open Safety Critical Work Orders",
      button: (
        <Button
            label="Download"
            className={(!data?.workOrderDeepDive || data?.workOrderDeepDive.length === 0) ? "" : `download-btn`}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.workOrderDeepDive && workOrderColumns) {
                    const csvContent = generateCSV(data?.workOrderDeepDive, workOrderColumns);
                    downloadCSV(csvContent, "open_safety_critical_work_orders.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.workOrderDeepDive || data?.workOrderDeepDive.length === 0) ? true : false}
        />
      ),
      table: (
        <AohTableWithLabels
          tableData={data?.workOrderDeepDive}
          tableColumns={workOrderColumns}
        />
      ),
    },
    "EHSS Training Compliance": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          data={data?.ehss?.summary}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title: "EHSS Compliance Rate",
      subtitle: "For Last 5 Hours",
      dropdown: (
        <div className="tier-dropdown">
          <AohDropdown
            items={costCenterDropDownData}
            title="Cost Center"
            dropOptions={currentCostCenter?.label}
            handleMenuClick={handleCostCenterClick}
          />
        </div>
      ),
      heatmap: (
        <ColorCodedTable
          data={filteredByCostCenterData ?? data?.ehss?.complianceRateData}
        />
      ),
    },
    "Safety CAPAs": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          data={data?.capa?.summary}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title: "Unclosed CAPAs",
      button: (
        <Button
            label="Download"
            className={(!data?.capa?.data || data?.capa?.data.length === 0) ? "" : `download-btn`}
            icon={<DownloadOutlined />}
            iconPosition="right"
            onClick={() => {
                if (data?.capa?.data && capaColumns) {
                    const csvContent = generateCSV(data?.capa?.data, capaColumns);
                    downloadCSV(csvContent, "unclosed_capa.csv");
                } else {
                    console.error("No data or columns available for download");
                }
            }}
            type="secondary"
            disabled={(!data?.capa?.data || data?.capa?.data.length === 0) ? true : false}
        />
      ),
      table: (
        <AohTable tableData={data?.capa?.data} tableColumns={capaColumns} />
      ),
    },
  };

  return (
    <div className="safety-tier-main">
      <h2 className="tier-title">
        {/* Summary card */}
        {safetyDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
      </h2>
      {safetyDeepDive[activeKeyDeepDiveTab]?.summary}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
        }
      ></div>
      {/* Title card-1 */}
      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown}
      {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle && (
          <div className="tier-subtitle">
            {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle}
          </div>
      )}
      {/* main component-1 */}
      {safetyDeepDive[activeKeyDeepDiveTab].heatmap}
      {safetyDeepDive[activeKeyDeepDiveTab].table}
      {safetyDeepDive[activeKeyDeepDiveTab].radioFilters}
      {loading || error ? (
        <div className="loader">
          <ErrorHandler isLoading={loading} isError={error} loaderColor={'BLUE'} />
        </div>
      ) : (
        safetyDeepDive[activeKeyDeepDiveTab].stackedBar
      )}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.heatmap ||
            safetyDeepDive[activeKeyDeepDiveTab]?.table ||
            safetyDeepDive[activeKeyDeepDiveTab]?.stackedBar
            ? "mr-bottom-20"
            : ""
        }
      ></div>

      {/* Title card-2*/}
      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title2}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button2}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown2}
      {safetyDeepDive[activeKeyDeepDiveTab]?.radioFilters2}
      {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2 && (
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2}
      </div>
      )}
      {/* main component-2 */}
      {safetyDeepDive[activeKeyDeepDiveTab].heatmap2}
      {safetyDeepDive[activeKeyDeepDiveTab].table2}
      {safetyDeepDive[activeKeyDeepDiveTab]?.component2}
      <div
        className={
          (safetyDeepDive[activeKeyDeepDiveTab]?.title3 !== "Open SEE Cards" &&
            (safetyDeepDive[activeKeyDeepDiveTab]?.heatmap2 ||
              safetyDeepDive[activeKeyDeepDiveTab]?.table2 ||
              safetyDeepDive[activeKeyDeepDiveTab]?.component2)
          )
            ? "mr-bottom-20"
            : ""
        }
      ></div>

      {/* Title card-3*/}
      <div className="tier-container">
        <h2 className="tier-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title3}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button3}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown3}
      {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle3 && (
      <div className="tier-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle3}
      </div>
      )}
      {/* main component-3 */}
      {safetyDeepDive[activeKeyDeepDiveTab].table3}
    </div>
  );
};
export default SafetyTier2;
