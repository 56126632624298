import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { DEEP_DIVE_VIEW, DELIVERY, METRICS, ON_TIME_SCHEDULE_ADHERENCE, WORK_CENTER_TEAM } from 'utils/constants';

interface onTimeScheduleAdherenceDeepDiveStateProps {
    onTimeScheduleAdherenceDeepDiveInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: onTimeScheduleAdherenceDeepDiveStateProps = {
    onTimeScheduleAdherenceDeepDiveInfo: null,
    loading: false,
    error: false
};

const segments = [WORK_CENTER_TEAM, METRICS, DELIVERY, DEEP_DIVE_VIEW, ON_TIME_SCHEDULE_ADHERENCE];
const fetchonTimeScheduleAdherenceDeepDive = fetchApi(segments);

const onTimeScheduleAdherenceDeepDiveReducer = createSlice({
    name: 'onTimeScheduleAdherenceDeepDive',
    initialState,
    reducers: {
        clearonTimeScheduleAdherenceDeepDiveData(state) {
            state.onTimeScheduleAdherenceDeepDiveInfo = null;
        },

        clearonTimeScheduleAdherenceViewByData(state) {
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchonTimeScheduleAdherenceDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchonTimeScheduleAdherenceDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.onTimeScheduleAdherenceDeepDiveInfo = action.payload;
            })
            .addCase(fetchonTimeScheduleAdherenceDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
});

export const { clearonTimeScheduleAdherenceDeepDiveData } = onTimeScheduleAdherenceDeepDiveReducer.actions;
export { fetchonTimeScheduleAdherenceDeepDive };
export default onTimeScheduleAdherenceDeepDiveReducer.reducer;
