/* eslint-disable @typescript-eslint/restrict-plus-operands */
import moment from "moment";
import momentDuration from 'moment-duration-format';
import { APIResponseDataType, DataType, ResponseObjType } from "types/wctTypes";
momentDuration(moment as any);
//TODO: Status Value in Caps , issue is Alert type does not have values in 'CAPS'
export const getStatusText: (status: string) => "Green" | "Yellow" | "Red" = (
  status: string
) => {
  switch (status) {
    case "GREEN":
      return "Green";
    case "YELLOW":
      return "Yellow";
    case "RED":
      return "Red";
    default:
      return "Red";
  }
};

export const getStatus: (status: string) => string = (status: string) => {
  switch (status) {
    case "running":
      return "Running";
    case "idle":
      return "Idle";
    case "down":
      return "Down";
    case "online":
      return "Online";
    case "changeover":
      return "Changeover";
    default:
      return "";
  }
};

export const getStatusColor: (status: string) => any = (status: string) => {
  switch (status) {
    case "green":
      return "#008533";
    case "grey":
      return "#9C9C9C";
    case "red":
      return "#D62728";
    case "blue":
      return "#0063C3";
    default:
      return "#0063C3";
  }
};

export const makeBold = (word: String | undefined) => {
  return <b> {word}</b>;
};

export const captializeWord = (word: string) => {
  const wordArray = word
    .split("_")
    .map((w: string) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(" ");
  return wordArray;
};

export const capsFirstLetter = (word: string) => {
  const wordArray = word?.toLowerCase();
  return wordArray.charAt(0).toUpperCase() + word.slice(1);
};

export const getOktaDetails = () => {
  const oktaToken: any = localStorage.getItem("dct-okta-token");
  const oktaTokenObj = JSON.parse(oktaToken);
  if (oktaTokenObj?.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};

export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return "";
};
export const ParseFloat = (str: string, val: number) => {
  str = str.toString();
  str = str.slice(0, str.indexOf(".") + val + 1);
  return Number(str);
};

interface memoType {
  down: number;
  running: number;
  idle: number;
  subpercentage: number;
}
interface garphValue {
  active_alarms: number;
  batch: string;
  end_time: string;
  machine: string;
  order_id: number;
  percentage: number;
  refresh_time: number;
  start_time: string;
  state_duration: number;
  status: string;
  status_color_code: string;
  total_time: string;
  start_percentage: number;
  end_percentage: number;
}
export const getAgrregratedPercentage = (
  status: string,
  timeStatusTableData: []
) => {
  const allAggregratedPercentage = timeStatusTableData?.reduce(
    (memo: memoType, currentValue: garphValue) => {
      if (currentValue?.status?.toLowerCase() === "down") {
        const finaldown = memo.down + currentValue.percentage;
        memo = { ...memo, down: finaldown };
      } else if (currentValue?.status?.toLowerCase() === "running") {
        memo.running = memo.running + currentValue.percentage;
      } else if (currentValue?.status?.toLowerCase() === "idle") {
        memo.idle = memo.idle + currentValue.percentage;
      } else if (status === "subgraph") {
        memo.subpercentage =
          memo.subpercentage +
          (currentValue?.end_percentage - currentValue?.start_percentage);
      }
      return memo;
    },
    {
      down: 0,
      running: 0,
      idle: 0,
      subpercentage: 0,
    }
  );
  if (status === "down") {
    return allAggregratedPercentage.down;
  } else if (status === "idle") {
    return allAggregratedPercentage.idle;
  } else if (status === "running") {
    return allAggregratedPercentage.running;
  } else if (status === "subgraph") {
    return allAggregratedPercentage?.subpercentage;
  } else {
    return 0;
  }
};

export const getAgrregratedTimevalue = (
  status: string,
  timeStatusTableData: any
) => {
  const allAggregratedTimeValue = timeStatusTableData?.reduce(
    (memo: any, currentValue: any) => {
      if (currentValue?.status?.toLowerCase() === "down") {
        memo.down = memo.down + currentValue.state_duration
      }
      if (currentValue?.status?.toLowerCase() === "running") {
        memo.running = memo.running + currentValue.state_duration
      }
      if (currentValue.status?.toLowerCase() === "idle") {
        memo.idle = memo.idle + currentValue.state_duration
      } else if (status === "subgraph") {
        // memo.subtime.add(currentValue.mode_duration, "s");
        memo.subtime = memo.subtime + currentValue.mode_duration
      }
      return memo;
    },
    {
      down: 0,
      running: 0,
      idle: 0,
      subtime: 0,
    }
  );
  if (status === "down") {
    return moment?.duration(allAggregratedTimeValue.down, "seconds").format("hh:mm:ss");
  } else if (status === "idle") {
    return moment?.duration(allAggregratedTimeValue.idle, "seconds").format("hh:mm:ss");
  } else if (status === "running") {
    return moment?.duration(allAggregratedTimeValue.running, "seconds").format("hh:mm:ss");
  } else if (status === "subgraph") {
    return moment?.duration(allAggregratedTimeValue.subtime, "seconds").format("hh:mm:ss");
  }
};

export const filterParams = (params: Record<string, any>): Record<string, any> => {
  return Object.keys(params).reduce((acc: Record<string, any>, key: string) => {
    const value = params[key];
    if (value !== undefined && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export const setData = (params: any) => Object.entries(params)
  .filter(([_, value]) => value !== undefined && (Array.isArray(value) ? value.length > 0 : true))
  .reduce((acc: Record<string, any>, [key, value]) => {
    acc[key.replaceAll("_", " ")] = value;
    return acc;
  }, {})

export const setDataKeys = (params: any) => Object.entries(params)
  .filter(([_, value]) => value !== undefined)
  .reduce((acc: Record<string, any>, [key, value]) => {
    const newKey = key.replace(/_([a-z])/g, (_, char) => char.toUpperCase());
    acc[newKey] = value;
    return acc;
  }, {})

export const setCapsLabel = (label: string) => {
  return label.replaceAll("_", "").toUpperCase()
}

export const checkValidNumber = (val: any): boolean => (val !== undefined && !isNaN(Number(val)) && Number(val) >= 0)

// send zero based month index
export const getMonthName = (month: number): string => {
  const dateObj = new Date(2024, month, 1)
  return dateObj.toLocaleString('default', { month: 'long' }).slice(0, 3).toUpperCase()
}

export function convertYearMonth(year: any, month: any) {
  // Ensure the month is always two digits (e.g., '01', '02', ..., '12')
  const monthFormatted = String(month).padStart(2, '0');

  // Create a new date using the first day of the given month and year
  const date = new Date(`${year}-${monthFormatted}-01`);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid year or month");
    return 'Invalid Date';
  }

  // Get the abbreviated month (e.g., 'JAN', 'FEB', etc.)
  const monthName = date.toLocaleString('en', { month: 'short' }).toUpperCase();

  // Get the last two digits of the year (e.g., '25' for 2025)
  const yearLastTwoDigits = String(year).slice(-2);

  return `${monthName} ${yearLastTwoDigits}`; // Return in 'MMM YY' format
}

export function convertMY(dateString: any) {
  // Ensure the input date is valid
  const date = new Date(`${dateString}-01`); // Add "-01" to make it a valid date

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date format");
    return 'Invalid Date';
  }

  // Get the abbreviated month (e.g., 'JAN', 'FEB', etc.)
  const month = date.toLocaleString('en', { month: 'short' }).toUpperCase();

  // Get the last two digits of the year (e.g., '25' for 2025)
  const year = String(date.getFullYear()).slice(-2);

  return `${month} ${year}`; // Return in 'MMM YY' format
}

export const getMonthNameWithString = (month: number | string): string => {
  let monthIndex: number;
  if (typeof month === 'string' && /^\d{4}-\d{2}$/.test(month)) {
    monthIndex = parseInt(month.split('-')[1], 10) - 1;
  } else if (typeof month === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(month)) {
    monthIndex = parseInt(month.split('-')[1], 10) - 1;
  } else if (typeof month === 'number') {
    monthIndex = month;
  } else {
    return '';
  }
  const dateObj = new Date(2024, monthIndex, 1);
  return dateObj.toLocaleString('default', { month: 'short' }).toUpperCase();
};

export function ascendDescend(a: number | string, b: number | string): number {
  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  }
  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  }
  return 0;
}

export function getFormattedMonthYear() {
  const now = new Date()

  let month = getMonthName(now.getMonth())
  month = month.charAt(0) + month.slice(1).toLowerCase()
  const year = now.getFullYear();

  return `${month}-${year}`
}

export const generateCSV = (data: any[], columns: any[]) => {
  const headers = columns.map(col => col.title).join(",") + "\n";
  const rows = data
    .map(row =>
      columns.map(col => `"${row[col.dataIndex] || ""}"`).join(",")
    )
    .join("\n");

  return headers + rows;
}

export const downloadCSV = (csvContent: BlobPart, filename = "table_data.csv") => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function formatIntlNumber(num: number) {
  if (num >= 1e9) {
    // Format as billions (round to nearest billion)
    return Math.floor(num / 1e9) + 'B';
  } else if (num >= 1e6) {
    // Format as millions (round to nearest million)
    return Math.floor(num / 1e6) + 'M';
  } else if (num >= 1e3) {
    // Format as thousands (round to nearest thousand)
    return Math.floor(num / 1e3) + 'K';
  } else {
    // If the number is less than 1000, return it as is
    return num.toString();
  }
}

export function isType<T extends DataType>(item: DataType, key: keyof T): item is T {
  return !!(item as T)[key];
}

export function filterbyDataType<T extends DataType>(data: DataType[], key: keyof T): (T & ResponseObjType)[] {
  return data?.filter((item): item is T & ResponseObjType => isType<T>(item, key))
}

export function findbyFilterType<T extends APIResponseDataType>(data: APIResponseDataType[], filterType: string): (T & ResponseObjType) | undefined {
  return data.find((item): item is T & ResponseObjType => item.filterType === filterType);
}

export function filterDuplicateStrings(arr: string[]): string[] {
  return Array.from(new Set(arr));
}

export const getFormattedMonthYearV2 = (date: string): string => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (/^\d{2}-\d{2}$/.test(date)) {
    // Format: DD-MM
    const [day, month] = date.split('-').map(Number);
    const daySuffix = day;
    return `${daySuffix} ${months[month - 1].toUpperCase()}`;
  } else if (/^\d{2}-\d{4}$/.test(date)) {
    // Format: MM-YYYY
    const [month, year] = date.split('-').map(Number);
    return `${months[month - 1].toUpperCase()} ${year.toString().slice(-2)}`;
  } else if (/^\d{1}-\d{4}$/.test(date)) {
    // Format: M-YYYY (Single-digit month)
    const [month, year] = date.split('-').map(Number);
    return `${months[month - 1].toUpperCase()} ${year.toString().slice(-2)}`;
  } else {
    return "";
  }
};

export function aggregateData(data: any, filter: string): any {
  const result: any = [
    { name: 'TOTAL\nTIME', value: 0 },
    { name: 'PLANNED\n DOWNTIME', value: 0 },
    { name: 'PLANNED\nBUSY TIME', value: 0, isTotal: true },
    { name: 'CHANGED\nOVER TIME', value: 0 },
    { name: 'RUN TIME', value: 0, isTotal: true },
    { name: 'UNPLANNED\nDOWN TIME', value: 0 },
    { name: 'EFFECTIVENESS\nLOSS', value: 0 },
    { name: 'QUALITY\nLOSS', value: 0 },
    { name: 'GOOD PRODUCTION\nTIME', value: 0, isTotal: true }
  ];
  // Iterate over each item in the data
  let newData;
  if (filter === 'All') {
    newData = data;
  } else if (data?.some((item: any) => item?.date)) {
    newData = data?.filter((item: any) => item?.date === filter);
  } else {
    newData = data?.filter((item: any) => item?.first_day_of_week === filter);
  }  

  if (Array.isArray(newData) && newData.length > 0) {
    newData.forEach(item => {
      result[0].value += item.total_time;
      result[1].value += item.planned_down_time;
      result[2].value += item.total_planned_busy_time;
      result[3].value += item.change_over_time;
      result[4].value += item.total_run_time;
      result[5].value += item.total_unplanned_downtime;
      result[6].value += item.total_effectiveness_loss;
      result[7].value += item.total_quality_loss;
      result[8].value += item.total_good_production_time;
    });
  }

  result[5].value *= -1;
  result[6].value *= -1;
  result[7].value *= -1;
  return result;
}

/**
 * Finds the maximum value for specified keys in an array of objects and rounds it
 * according to the following logic:
 * - If the maximum value is less than 100, round it to the nearest multiple of 5.
 * - If the maximum value is less than 1000, round it to the nearest multiple of 100.
 * - Otherwise, round it to the nearest multiple of 1000.
 * - If the data array is empty or not provided, return 100 as the default value.
 *
 * @param {any[]} data - The array of objects to search.
 * @param {...string} keys - The key or keys whose values are to be considered for finding the maximum.
 * @returns {number} - The rounded maximum value or 100 if the data array is empty or not provided.
 */
export const findAndRoundMaxValue = (data: any[], ...keys: string[]): number => {
  // Return 100 if the data array is empty or not provided
  if (!data || data.length === 0) return 100;

  // Find the maximum value for the specified keys
  let maxValue = Math.max(
    ...data?.flatMap(item =>
      keys.map(key => item[key]).filter(value => typeof value === 'number')
    )
  );

  // Apply rounding logic
  if (maxValue < 100) {
    maxValue = Math.ceil(maxValue / 5) * 5;
  } else if (maxValue < 1000) {
    maxValue = Math.ceil(maxValue / 100) * 100;
  } else if (maxValue < 10000) {
    maxValue = Math.ceil(maxValue / 1000) * 1000;
  } else if (maxValue < 100000) {
    maxValue = Math.ceil(maxValue / 10000) * 10000;
  } else if (maxValue < 1000000) {
    maxValue = Math.ceil(maxValue / 100000) * 100000;
  } else {
    maxValue = Math.ceil(maxValue / 1000000) * 1000000;
  }

  return maxValue;
};
