import { formatIntlNumber, defaultAxisLabelFormatter } from './formatters';

type FormatterFunction = (value: any, startLabel?: any, endLabel?: any, prefix?: string, suffix?: string, showAllLabels?: boolean, indexVal?: any, dataLength?: any) => string;

interface FormatterFunctions {
  [key: string]: FormatterFunction;
}

export const formatterFunctions: FormatterFunctions = {
  formatIntlNumber,
  defaultAxisLabelFormatter,
  // Add other formatter functions here if needed
};

export const getFormatterFunction = (key: string): FormatterFunction => {
  return formatterFunctions[key];
};