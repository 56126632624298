import { Radio } from "@gitlab-rtsensing/component-library";

type TimePeriod = string;

interface RadioFiltersProps {
  timePeriod: TimePeriod;
  setTimePeriod: (value: TimePeriod) => void;
  activeKeyDeepDiveTab?: string;
  filterDataByTimePeriod?: (
    period: any
  ) => any;
}

export const RadioFilters: React.FC<RadioFiltersProps> = ({
  timePeriod,
  setTimePeriod,
  filterDataByTimePeriod,
  activeKeyDeepDiveTab
}) => {
  let timePeriods: TimePeriod[] = []
  timePeriods = [
    "Last Week",
    "Last Month",
    "Last 3 Months",
  ];
  if ((timePeriod === "All" || timePeriod === "Overdue" || timePeriod === "Coming Due") && activeKeyDeepDiveTab === "INCA Incidents") {
    timePeriods = ["All", "Overdue","Coming Due"];
  }

  return (
    <div className="d-flex" style={{ marginBottom: "10px" }}>
      <div className="ops-fs-5 ops-fw-bold" style={{ marginRight: "15px" }}>
        TIME PERIOD :
      </div>
      {timePeriods.map((period) => (
        <div key={period} style={{ marginRight: "8px" }}>
          <Radio
            name="timePeriod"
            value={period}
            checked={timePeriod === period}
            onChange={() => {
              if (filterDataByTimePeriod) {
                setTimePeriod(period);
                filterDataByTimePeriod(period);                
              } else {
                setTimePeriod(period);
              }
            }}
            variant="primary"
          />
        </div>
      ))}
    </div>
  );
};
