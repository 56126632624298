import StackedBarChart from "components/common/StackedBar/StackedBar";
import "./Delivery.scss";
import { negativeMinMax, newMinMaxValue } from "components/common/StackedBar/utils";

interface DeliveryTier0Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const DeliveryTier0: React.FC<DeliveryTier0Props> = ({
    data,
    tierLabel,
    activeKeyDeepDiveTab,
}) => {

    const deliveryDeepDive: any = {
        "On Time Schedule Adherence": {
            title: "Performance Trend for the units produced",
            subtitle: "In Last 24 hrs",
            stackBar: (
                <StackedBarChart
                    data={data[0]?.data}
                    chartType="trend-graph-units"
                    xAxisData={Array.isArray(data[0]?.data) ? (data[0]?.data).map((item: any) => item.hour) : []}
                    yAxisNames={[
                        { name: "UNITS", position: "left", max: newMinMaxValue(data[0]?.data)?.Max },
                    ]}
                    nameGapCustom={76}
                />
            ),
            title2: "Performance Trend of Adherence",
            stackBar2: (
                <StackedBarChart
                    data={data[1]?.data}
                    chartType="trend-graph-adherence"
                    xAxisData={Array.isArray(data[1]?.data) ? (data[1]?.data).map((item: any) => item.hour) : []}
                    yAxisNames={[
                        { name: "ADHERENCE", position: "left", max: negativeMinMax(data[1]?.data)?.Max, min: negativeMinMax(data[1]?.data)?.Min},
                    ]}
                />
            ),
        }
    }

    return (
        <div className="delivery-tier-main">
            {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle && (
                <div className="tier-container">
                    <h2 className="tier-title">
                        {/* Summary card */}
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                    </h2>
                </div>
            )}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.summary}
            <div
                className={
                    deliveryDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                }
            ></div>
            {deliveryDeepDive[activeKeyDeepDiveTab]?.title && (
                <div className="tier-container">
                    <h2 className="tier-title">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.title}
                    </h2>
                </div>
            )}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle && (
            <div className="tier-subtitle">
                {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            )}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.blockRadioFilters}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.table}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.stackBar}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.title2 && (
                <div className="tier-container">
                    <h2 className="tier-title">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.title2}
                    </h2>
                </div>
            )}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle && (
            <div className="tier-subtitle">
                {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            )}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.stackBar2}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.title3 && (
                <div className="tier-container">
                    <h2 className="tier-title">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.title3}
                    </h2>
                </div>
            )}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle3 && (
            <div className="tier-subtitle">
                {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle3}
            </div>
            )}
            {deliveryDeepDive[activeKeyDeepDiveTab]?.table3}
        </div>

    )
};
export default DeliveryTier0;
