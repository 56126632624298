import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, SAFETY, SEE_CARDS, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface SeeCardsInfoState {
  seeCardsInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: SeeCardsInfoState = {
  seeCardsInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, SAFETY, BASE_VIEW, SEE_CARDS];
const fetchSeeCards = fetchApi(segments);

const seeCardsReducer = createSlice({
    name: 'seeCards',
    initialState,
    reducers: {
        clearSeeCardsData(state) {
            state.seeCardsInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeeCards.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchSeeCards.fulfilled, (state, action) => {
                state.loading = false;
                state.seeCardsInfo = action.payload
            })
            .addCase(fetchSeeCards.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchSeeCards };

export const { clearSeeCardsData } = seeCardsReducer.actions

export default seeCardsReducer.reducer;