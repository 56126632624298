import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { OEE_THREE_DIVE_DIVE_URL } from 'utils/constants';
import { StringKeyAnyDataProps } from 'utils/data-types';

interface OeeThreeDdvInfoState {
    oeeThreeDdvInfo: APIResponseDataType[] | null;
    oeeThreeFilterData: StringKeyAnyDataProps[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: OeeThreeDdvInfoState = {
    oeeThreeDdvInfo: null,
    oeeThreeFilterData: null,
    loading: false,
    error: false,
}

const fetchOeeThreeDdv = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    OEE_THREE_DIVE_DIVE_URL,
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${OEE_THREE_DIVE_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch OEE Three');
        }
        const data = await response.json();
        data.forEach((filter: any) => {
            if (filter.extraFilterData) {
                filter.extraFilterData = [{ line: 'All' }, ...filter.extraFilterData];
            }
        });
        return data;
    }
);

const fetchOee3FilterData = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
    `${OEE_THREE_DIVE_DIVE_URL}filter`,
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${OEE_THREE_DIVE_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch OEE Three');
        }
        const data = await response.json();

        data.forEach((filter: any) => {
            if (filter.extraFilterData) {
                filter.extraFilterData = [{ line: 'All' }, ...filter.extraFilterData];
            }
        });
        return data;
    }
);

const handleOeeThreeFilterData = (oldState: { oeeThreeDdvInfo: any[]; }, filerData: { filterType: any; data: any; }) => {
    const state = oldState.oeeThreeDdvInfo
    state?.map((item: { filterType: any; data: any; }) => {
        if (filerData?.filterType === item.filterType) {
            item.data = filerData.data
        }
        return item
    });
    return state

}

const oeeThreeDdvReducer = createSlice({
    name: 'oeeThreeDdv',
    initialState,
    reducers: {
        clearOeeThreeDdvData(state) {
            state.oeeThreeDdvInfo = null
            state.oeeThreeFilterData = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOeeThreeDdv.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchOeeThreeDdv.fulfilled, (state, action) => {
                state.loading = false;
                state.oeeThreeDdvInfo = action.payload
            })
            .addCase(fetchOeeThreeDdv.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchOee3FilterData.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchOee3FilterData.fulfilled, (state, action) => {
                state.loading = false;
                state.oeeThreeDdvInfo = handleOeeThreeFilterData(state as any, action.payload as any);
            })
            .addCase(fetchOee3FilterData.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
})

export { fetchOeeThreeDdv, fetchOee3FilterData };

export const { clearOeeThreeDdvData } = oeeThreeDdvReducer.actions

export default oeeThreeDdvReducer.reducer;