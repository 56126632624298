import moment from "moment";
import { SVGProps } from "react";
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

type AxisInterval = number | 'preserveStart' | 'preserveEnd' | 'preserveStartEnd' | 'equidistantPreserveStart';

interface AxisConfig {
  key?: string;
  name?: string;
  startLabel?: string | number;
  endLabel?: string | number;
  showLine?: boolean | SVGProps<SVGLineElement>;
  showTickLine?: boolean;
  interval?: AxisInterval;
  tickMargin?: number;
  prefix?: string; // Prop to add a prefix to the value
  suffix?: string; // Prop to add a suffix to the value
  formatterKey?: string; // Prop to format the value
}

interface DualLineAreaGraphPropsType {
  config: {
    xAxisKey: string;
    areaKey: string;
    lineKey: string;
    xAxis?: AxisConfig;
    yAxis?: AxisConfig;
  };
  data: Array<{
    name: string;
    minMax: Array<number>;
    lineValue: number;
  }>;
}

const xAxisConfig: AxisConfig = {
  key: 'name',
  name: 'DISTRIBUTION RANGE 10% - 90%',
  showLine: false,
  showTickLine: false,
  interval: 0
};

const yAxisConfig: AxisConfig = {
  key: 'value',
  name: 'AVERAGE DISTRIBUTION RANGE',
  showLine: false,
  showTickLine: false,
  tickMargin: 12,
  startLabel: 0,
  endLabel: 100,
  interval: 0
};

// Function to format date string to month
const formatDateToMonth = (dateString: string) => {
  if (!dateString) {
    return 'N/A';
  }
  const checkDateFormat = dateString.includes('-');
  if (!checkDateFormat) {
    return dateString;
  }
  const momentCheck = moment(dateString, 'YYYY-MM-DD');
  if (!momentCheck.isValid()) {
    return dateString;
  }
  return momentCheck.format('DD-MMM');
};

// Function to format tooltip labels
const formatTooltipLabel = (label: string) => {
  return label
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const DualLineAreaGraph = ({ data, config }: DualLineAreaGraphPropsType) => {
  const {
    xAxisKey,
    areaKey,
    lineKey,
    xAxis = { ...xAxisConfig, ...config.xAxis },
    yAxis = { ...yAxisConfig, ...config.yAxis }
  } = config;

  return (
    <ResponsiveContainer width="100%" height={280}>
      <ComposedChart
        data={data || []}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 30,
        }}
      >
        <CartesianGrid
          stroke="#0000000F"
          syncWithTicks
          strokeDasharray="none" />
        <XAxis
          dataKey={xAxisKey ?? xAxis.key}
          axisLine={xAxis.showLine}
          tickLine={xAxis.showTickLine}
          interval={xAxis.interval}
          padding={{ left: 80 }}
          tick={{
            fontSize: 10, // Set font size for x-axis labels
            fontFamily: 'Inter', // Set font family for x-axis labels
            fontWeight: 700, // Set font weight for x-axis labels
            color: '#000000A6', // Set color for x-axis labels
          }}
          label={{
            value: xAxis.name,
            position: 'insideBottom',
            offset: -30,
            style: {
              fontSize: 10,
              fontWeight: 700,
              fontFamily: 'Inter',
              lineHeight: 14,
              color: '#595959',
              align: 'center'
            }
          }} // Set x-axis name
        />
        <YAxis
          axisLine={yAxis.showLine}
          tickLine={yAxis.showTickLine}
          tickMargin={yAxis.tickMargin}
          label={{
            value: yAxis.name,
            angle: -90,
            position: 'left',
            offset: 20,
            dy: -90,
            style: {
              fontSize: 10,
              fontWeight: 700,
              fontFamily: 'Inter',
              lineHeight: 14,
              color: '#595959',
              align: 'center'
            }
          }} // Set y-axis name
        />
        <Tooltip 
          formatter={(value, name) => [value, typeof name === 'string' ? formatTooltipLabel(name) : name]}
        />
        <Area
          dataKey={areaKey}
          stroke="none"
          fill="#0063c3"
          fillOpacity={0.2}
          baseValue="dataMin"
        />
        <Line
          type="linear"
          dataKey={lineKey}
          stroke="#0063c3"
          strokeWidth={2}
          dot={{ stroke: "none", strokeWidth: 1, r: 4, strokeDasharray: '', fill: "#0063c3" }} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
