export const defaultAxisLabelFormatter = (
    value: any,
    startLabel: any,
    endLabel: any,
    prefix: string = '',
    suffix: string = '',
    showAllLabels: boolean = false
): string => {
    if (showAllLabels || value === startLabel || value === endLabel) {
        return `${prefix}${value}${suffix}`;
    }
    return ''; // Only show startLabel and endLabel if showAllLabels is false
};

const formatLargeNumber = (num: number): string => {
    if (num >= 1e9) {
        return Math.floor(num / 1e9).toString() + 'B';
    } else if (num >= 1e6) {
        return Math.floor(num / 1e6).toString() + 'M';
    } else if (num >= 1e3) {
        return Math.floor(num / 1e3).toString() + 'K';
    }
    return num.toString();
};

export const formatIntlNumber = (
    value: any,
    startLabel: any,
    endLabel: any,
    prefix: string = '',
    suffix: string = '',
    showAllLabels: boolean = false,
    indexVal: number,
    dataLength: number
): string => {
    const fValue = formatLargeNumber(value);
    const fendLabel = formatLargeNumber(endLabel);
    if (showAllLabels || value === startLabel || value === endLabel) {
        return `${prefix}${fValue}${suffix}`;
    }
    if (indexVal === dataLength + 1) {
        return `${prefix}${fendLabel}${suffix}`;
    }
    return ''; // Only show startLabel and endLabel if showAllLabels is false
};

export function roundOff(value: string | number | undefined): string | number | undefined {
    if (value === undefined) {
        return value;
    }

    if (typeof value === 'string') {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            value = parsedValue;
        } else {
            return value; // Return the original string if it's not a numeric string
        }
    }

    if (typeof value === 'number') {
        return Math.round(value * 100) / 100;
    }

    return value;
}