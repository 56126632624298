import { barSeriesConfig, lineSeriesConfig, dashedLineSeriesConfig, verticalBarBaseViewConfig, barSeriesConfigWoTotal, dottedlineSeriesConfig, withoutdottedlineSeriesConfig, lineSeriesConfigSmooth } from './chartConfig';
import { ChartConfigProps, COLORS, DataItem, LegendData, SeriesData } from './constants'; // Adjust the import path as needed
import { addTransparency } from './utils';

export const getSeriesData = (chartType: string, data: DataItem[], chartConfig?: ChartConfigProps[]) => {
    if (!data) return [];
    if (chartType === "global") {
        const seriesData = chartConfig?.map(({ nameKey, dataKey, statusValue, circleColor }) => {
            return {
                name: statusValue,
                data: data.filter((d: any) => d[nameKey] === statusValue).map((d: any) => d[dataKey] ?? 0),
                ...barSeriesConfig,
                ...(circleColor ? { itemStyle: { color: circleColor } } : {}),
            }
        }) ?? [];

        return seriesData;
    } else if (chartType === "rft") {
        return [{
            name: "",
            data: data?.map((d: any) => d.value ?? 0),
            smooth: true,
            showSymbol: true,
            ...dashedLineSeriesConfig,
        }]
    }
    if (chartType === "see-cards") {
        return [
            {
                name: "Serious",
                data: data.map((d) => d.serious ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.serious },
            },

            {
                name: "Potentially Serious",
                data: data.map((d) => d.potentiallySerious ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },

            {
                name: "Major",
                data: data.map((d) => d.major ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.major },
            },

            {
                name: "Minor",
                data: data.map((d) => d.minor ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.minor },
            },

            {
                name: "Non EHS Issue",
                data: data.map((d) => d.nonEHSIssue ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.nonEHSIssue },
            },
        ];
    } else if (chartType === "minor-deviation-adherence-mtd") {
        return [
            {
                name: "On-Time Minor Deviations",
                data: data?.map((d) => d.on_time_review_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Minor Deviations",
                data: data?.map((d) => d.not_on_time_review_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Minor Deviation Adherence",
                data: data?.map((d) => d.deviation_percentage_mtd ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "minor-deviation-adherence-6m") {
        return [
            {
                name: "On-Time Minor Deviations",
                data: data?.map((d) => d.on_time_review_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Minor Deviations",
                data: data?.map((d) => d.not_on_time_review_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Minor Deviation Adherence",
                data: data?.map((d) => d.deviation_percentage_6m ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "minor-deviation-aging-mtd") {
        return [
            {
                name: "Non-Extensions Minor Deviations",
                data: data?.map((d) => d.open_count_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Extensions Minor Deviations",
                data: data?.map((d) => d.open_aging_count_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Extensions Minor Deviations",
                data: data?.map((d) => d.aging_open_percentage_mtd ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "minor-deviation-aging-6m") {
        return [
            {
                name: "Non-Extensions Minor Deviations",
                data: data?.map((d) => d.open_count_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
                yAxisIndex: 1
            },
            {
                name: "Extensions Minor Deviations",
                data: data?.map((d) => d.open_aging_count_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
                yAxisIndex: 1
            },
            {
                name: "% of Extensions Minor Deviations",
                data: data?.map((d) => d.aging_open_percentage_6m ?? 0),
                ...lineSeriesConfig,
            },
        ];
    } else if (chartType === "minor-deviation") {
        return [
            {
                name: "Non-Extensions Minor Deviations",
                data: data?.map((d) => d.open_count_7d ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Extensions Minor Deviations",
                data: data?.map((d) => d.open_aging_count_7d ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "Trend of daily Coming Due Minor Deviations",
                data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
                barWidth: 16,
                ...lineSeriesConfig,
                stack: "total",
            },
        ];
    } else if (chartType === "major-deviation-adherence-mtd") {
        return [
            {
                name: "On-Time Major Deviations",
                data: data?.map((d) => d.on_time_review_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Major Deviations",
                data: data?.map((d) => d.not_on_time_review_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Major Deviation Adherence",
                data: data?.map((d) => d.deviation_percentage_mtd ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "major-deviation-adherence-6m") {
        return [
            {
                name: "On-Time Major Deviations",
                data: data?.map((d) => d.on_time_review_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Major Deviations",
                data: data?.map((d) => d.not_on_time_review_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Major Deviation Adherence",
                data: data?.map((d) => d.deviation_percentage_6m ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "major-deviation-aging-mtd") {
        return [
            {
                name: "Non-Extensions Major Deviations",
                data: data?.map((d) => d.open_count_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Extensions Major Deviations",
                data: data?.map((d) => d.open_aging_count_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Extensions Major Deviations",
                data: data?.map((d) => d.aging_open_percentage_mtd ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "major-deviation-aging-6m") {
        return [
            {
                name: "Non-Extensions Major Deviations",
                data: data?.map((d) => d.open_count_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Extensions Major Deviations",
                data: data?.map((d) => d.open_aging_count_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Extensions Major Deviations",
                data: data?.map((d) => d.aging_open_percentage_6m ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "major-deviation") {
        return [
            {
                name: "Non-Extensions Major Deviations",
                data: data?.map((d) => d.open_count_7d ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Extensions Major Deviations",
                data: data?.map((d) => d.open_aging_count_7d ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "Trend of daily Coming Due Major Deviations",
                data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
                barWidth: 16,
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "change-control-status") {
        return [
            {
                name: "Non-Extensions Change Controls",
                data: data?.map((d) => d.normal_last_7days ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Extensions Change Controls",
                data: data?.map((d) => d.aging_last_7days ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "Trend of daily Coming Due Change Controls",
                data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "change-control-adherence-mtd" || chartType === "change-control-adherence-6m") {
        return [
            {
                name: "On-Time Change Controls",
                data: data?.map((d) => d.on_time_cc ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Not On-Time Change Controls",
                data: data?.map((d) => d.not_on_time_cc ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Change Control Adherence",
                data: data?.map((d) => d.compliance_rate ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "change-control-aging-mtd" || chartType === "change-control-aging-6m") {
        return [
            {
                name: "Non-Extensions Change Controls",
                data: data?.map((d) => d.non_aging_cc ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#0063C3" },
            },
            {
                name: "Extensions Change Controls",
                data: data?.map((d) => d.aging_cc ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: "#FDB81E" },
            },
            {
                name: "% of Extensions Change Controls",
                data: data?.map((d) => d.compliance_rate ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "capa-status") {
        return [
            {
                name: "Non-Extensions CAPAs",
                data: data?.map((d) => d.non_aging_flag ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Extensions CAPAs",
                data: data?.map((d) => d.aging_flag ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "Trend of daily Coming CAPAs",
                data: data?.map((d) => d.coming_due ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "capa-status-mtd" || chartType === "capa-status-adherence-6m") {
        return [
            {
                name: "On time CAPAs",
                data: data?.map((d) => d.on_time ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not on time CAPAs",
                data: data?.map((d) => d.not_on_time ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskCondition },
            },
            {
                name: "% of CAPA Adherence",
                data: data?.map((d) => d.aging_percentage ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "capa-status-aging-mtd" || chartType === "capa-status-aging-6m") {
        return [
            {
                name: "Non Extensions CAPAs",
                data: data?.map((d) => d.non_aging ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Extensions CAPAs",
                data: data?.map((d) => d.aging ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of Extensions CAPAs",
                data: data?.map((d) => d.aging_percentage ?? 0),
                ...lineSeriesConfig,
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "ratio-pm" || chartType === "ratio-pm-vs") {
        return [
            {
                name: "Pm vs (CM+GM) ratio",
                data: data?.map((d) => d.pm_vs_cm_gm_ratio ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
        ];
    } else if (chartType === "percent-closure-pm") {
        return [
            {
                data: data?.map((d) => d.no_of_work_orders ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.no_of_work_orders },
            },
            {
                data: data?.map((d) => d.pm_percentage_6m ?? 0),
                ...dottedlineSeriesConfig,
                itemStyle: { color: COLORS.pm_percentage_6m },
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "aging-work-orders") {
        return [
            {
                name: 'Open Work Orders',
                data: data?.map((d) => d.open ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Closed Work Orders",
                data: data?.map((d) => d.closed ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
        ];
    } else if (chartType === "wo-closure-adherence") {
        return [
            {
                name: "% CLOSED WORK ORDERS",
                data: data?.map((d) => d.pre_closure_over_weeks ?? 0),
                ...lineSeriesConfigSmooth,
                itemStyle: { color: "#2FBCB6" }
            },
        ];
    } else if (chartType === "wo-calibration-dis") {
        return [
            {
                name: "PMCAL",
                data: data?.map((d) => d.PMCAL ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "GMCAL",
                data: data?.map((d) => d.GMCAL ?? 0),
                type: "bar",
                stack: "total",
                itemStyle: { color: COLORS.safeBehavior },
            },
            {
                name: "CMCAL",
                data: data?.map((d) => d.CMCAL ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
        ];
    } else if (chartType === "per-calibration-closure") {
        return [
            {
                data: data?.map((d) => d.total_workorders ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                data: data?.map((d) => d.closure_adherence_percentage ?? 0),
                ...dottedlineSeriesConfig,
                itemStyle: { color: COLORS.closure_adherence_percentage },
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "on-time-schedule-bv") {
        return [
            {
                name: "Planned Units",
                data: data?.map((d) => d.quarterly_actual ?? 0),
                ...verticalBarBaseViewConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Actual Units",
                data: data?.map((d) => d.quarterly_actual ?? 0),
                ...verticalBarBaseViewConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
        ];
    } else if (chartType === "trend-graph-adherence") {
        return [
            {
                name: "Adherence",
                data: data?.map((d) => d.lag_ahead_hours),
                ...withoutdottedlineSeriesConfig,
                lineStyle: {
                    color: (params: any) => {
                        const value = params.data;
                        if (value > 0) {
                            return COLORS.ahead;
                        } else if (value < 0) {
                            return COLORS.serious;
                        } else {
                            return COLORS.atRiskBehavior;
                        }
                    }
                },
                itemStyle: {
                    color: (params: any) => {
                        const value = params.data;
                        if (value > 0) {
                            return COLORS.ahead;
                        } else if (value < 0) {
                            return COLORS.serious;
                        } else {
                            return COLORS.atRiskBehavior;
                        }
                    }
                }
            }
        ];
    } else if (chartType === "trend-graph-units") {
        return [
            {
                name: "Actual Units",
                data: data?.map((d) => d.actual_units ?? 0),
                ...dottedlineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
            {
                name: "Planned Units",
                data: data?.map((d) => d.planned_units ?? 0),
                ...dottedlineSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
        ];
    } else if (chartType === "on-time-ddv-actual-vs-planned-weekly" ||
        chartType === "on-time-ddv-actual-vs-planned-weekly-current" ||
        chartType === "on-time-ddv-actual-vs-planned-six-months") {
        return [
            {
                name: "Actual Units",
                data: data?.map((d) => d.actual_units ?? 0),
                ...barSeriesConfigWoTotal,
                itemStyle: { color: COLORS.safeBehavior },
            },
            {
                name: "Planned Units",
                data: data?.map((d) => d.planned_units ?? 0),
                ...barSeriesConfigWoTotal,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
        ];
    } else if (chartType === "on-time-ddv-actual-and-planned-by-product") {
        // Generate legend data
        const uniqueColors = Array.from(new Set(Object.values(COLORS)));
        const uniqueMaterialNumbers = Array.from(new Set(data?.flatMap((d) =>
            Object.keys(d).filter((key) => key !== 'date' && (key.endsWith('_actual_units') ||
                key.endsWith('_planned_units')))
                .map((key) => key.replace(/_(actual_units|planned_units)$/, ''))
        )));

        const legendData: LegendData = uniqueMaterialNumbers?.reduce((acc: LegendData, materialNumber, index) => {
            acc[materialNumber] = uniqueColors[index % uniqueColors.length];
            return acc;
        }, {});

        const actualUnitsSeries: SeriesData[] = []
        const plannedUnitsSeries: SeriesData[] = []

        uniqueMaterialNumbers.forEach((material) => {
            const actualKey = `${material}_actual_units`;
            const plannedKey = `${material}_planned_units`;
            const color = legendData[material];
            const transparentColor = addTransparency(color, 0.65); // 65% opacity

            if (data.some((d) => actualKey in d)) {
                actualUnitsSeries.push({
                    name: `${material} Actual Units`,
                    type: "bar",
                    stack: "Actual Units",
                    barWidth: 16,
                    data: data.map((d) => d[actualKey] ?? null),
                    itemStyle: { color: transparentColor },
                });
            }

            if (data.some((d) => plannedKey in d)) {
                plannedUnitsSeries.push({
                    name: `${material} Planned Units`,
                    type: "bar",
                    stack: "Planned Units",
                    barWidth: 16,
                    data: data.map((d) => d[plannedKey] ?? null),
                    itemStyle: { color },
                });
            }
        });

        return [...actualUnitsSeries, ...plannedUnitsSeries];
    } else if (chartType === "brrReviewTarget") {
        return [
            {
                name: "On Time Reviewed BRR",
                data: data?.map((d) => d.on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not On Time Reviewed BRR",
                data: data?.map((d) => d.not_on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of BRR Reviewed Within Target",
                data: data?.map((d) => d.percent_brr_completed_within_target ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "avgBatchDltGraph") {
        return [
            {
                name: "Total Dispositioned Batches",
                data: data?.map((d) => d.total_completed ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "AVG. DLT Per Batches",
                data: data?.map((d) => d.avg_dlt_per_batch ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
    } else if (chartType === "avgTatWrtTesting") {
        return [
            {
                data: data.map((item: DataItem) => ({
                    name: item.test,
                    value: item.avg_turn_around_time,
                    itemStyle: { color: COLORS[item.test as keyof typeof COLORS] || COLORS.default },
                })),
                ...barSeriesConfig,
            }
        ];
    } else if (chartType === "brrCompletedWithinTarget") {
        return [
            {
                name: "On Time BRR",
                data: data?.map((d) => d.on_time_batches_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not On Time BRR",
                data: data?.map((d) => d.not_on_time_batches_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of BRR Completed Within Target",
                data: data?.map((d) => d.percent_brr_completed_within_target ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "brrList") {
        return [
            {
                name: "On Time BRR",
                data: data?.map((d) => d.on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not On Time BRR",
                data: data?.map((d) => d.not_on_time_batches_mtd ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of BRR Completed Within Target",
                data: data?.map((d) => d.percent_brr_completed_within_target ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];

    } else if (chartType === "batchefordispositionWithTarget") {
        return [
            {
                name: "On Time Batches Dispositioned",
                data: data?.map((d) => d.on_time_batches_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },
            {
                name: "Not On Time Batch Dispositioned",
                data: data?.map((d) => d.not_on_time_batches_6m ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.potentiallySerious },
            },
            {
                name: "% of Batche Disposition Within Target (MTD)",
                data: data?.map((d) => d.percent_brr_dispositioned_within_target ?? 0),
                // type: "dashed",
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
                yAxisIndex: 1
            },
        ];
    } else if (chartType === "oeeValuesOvertimePerLine") {
        return [
            {
                name: "OEE 1",
                data: data.map((d) => d.oee1 ?? 0),
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.OEE1 },
            },

            {
                name: "OEE 2",
                data: data.map((d) => d.oee2 ?? 0),
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.OEE2 },
            },

            {
                name: "OEE 3",
                data: data.map((d) => d.oee3 ?? 0),
                ...lineSeriesConfig,
                itemStyle: { color: COLORS.OEE3 },
            },
        ];
    } else {
        return [
            {
                name: "At-Risk Behavior",
                data: data.map((d) => d.atRiskBehavior ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskBehavior },
            },

            {
                name: "At-Risk Condition",
                data: data.map((d) => d.atRiskCondition ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.atRiskCondition },
            },

            {
                name: "Safe Behavior",
                data: data.map((d) => d.safeBehavior ?? 0),
                ...barSeriesConfig,
                itemStyle: { color: COLORS.safeBehavior },
            },
        ];
    }
};