import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, CAPA, EHSS, METRICS, SAFETY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface CapaInfoState {
  capaInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: CapaInfoState = {
  capaInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, SAFETY, BASE_VIEW, CAPA];
const fetchCapa = fetchApi(segments);

const capaReducer = createSlice({
    name: 'capa',
    initialState,
    reducers: {
        clearcapaData(state) {
            state.capaInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCapa.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchCapa.fulfilled, (state, action) => {
                state.loading = false;
                state.capaInfo = action.payload;
            })
            .addCase(fetchCapa.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchCapa };

export const { clearcapaData } = capaReducer.actions

export default capaReducer.reducer;