import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIResponseDataType, FetchDataParams } from "types/wctTypes";
import { filterParams, getOktaToken } from "utils/common-methods";

const generateUrl = (base: string, segments: string[], queryParams?: Record<string, any>): string => {
    const url = new URL(base);
    
    segments.forEach(segment => {
        url.pathname += segment;
    });
    if (queryParams) {
        Object.keys(queryParams).forEach(key => {
            url.searchParams.append(key, queryParams[key]);
        });
    }
    return url.toString();
};

export const fetchApi = (segments: string[], local: boolean = false) => createAsyncThunk<APIResponseDataType[], Record<string, any>>(
    segments.join(''),
    async (params) => {
        const filteredParams = filterParams(params);
        const token: string = getOktaToken();
        const baseUrl = local ? process.env.REACT_APP_LOCAL_API_URL ?? '' : process.env.REACT_APP_API_URL ?? '';
        const url = generateUrl(baseUrl, segments, filteredParams);
        const response = await fetch(url, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    }
);