import React from 'react';
import { Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine, CartesianGrid, Area, ComposedChart } from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import styled from '@emotion/styled';
import { hasDecimalValues, computeYEndLabel } from '../chartUtils';
import { formatIntlNumber } from 'utils/common-methods';

interface SimpleLineGraphProps {
  data: {
    name?: string;
    value?: number | string
  }[];
  config?: {
    lineColor?: string;
    height?: number | string;
    width?: number | string;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    xAxisLine?: object;
    xAxisInterval?: number;
    yAxisTickMargin?: number;
    yAxisDomain?: [number, number];
    xAxisDataKey?: string;
    lineStrokeWidth?: number;
    lineDataKey?: string;
    targetReferenceLineStroke?: string;
    thresholdReferenceLineStroke?: string;
    thresholdReferenceStrokeDashArray?: string;
    xAxisTickLine?: boolean;
    yAxisLine?: boolean;
    yAxisTickLine?: boolean;
    lineDot?: boolean;
    strokeDashedarray?: string;
    grid?: {
      stroke?: string;
      strokeDashedarray?: string;
    };
    graphType?: string;
    xStartLabel?: string;
    xEndLabel?: string;
    yEndLabel?: number;
    tooltipContent?: string;
    targetReferenceValue?: number | string;
    thresholdReferenceValue?: number;
    lineGraphType?: string;
    YAxisPrefix?: string;
  };
}

// Styled component for custom tick
const CustomTick = styled.text`
  fill: #000000; /* Text color */
  opacity: 65%;
  font-size: 10px; /* Font size */
  font-weight: 700; /* Font weight */
  line-height: 14px; /* Line height */
`;

// Custom X Axis Tick
const CustomXAxisTick: React.FC<{ x: number; y: number; payload: any; startLabel: string; endLabel: string }> = ({ x, y, payload, startLabel, endLabel }) => {
  const isStartLabel = payload.value === startLabel;
  const isEndLabel = payload.value === endLabel;

  if (!isStartLabel && !isEndLabel) {
    return null; // Skip labels in between
  }

  return (
    <CustomTick
      x={x}
      y={y + 8}
      textAnchor={isStartLabel ? 'start' : isEndLabel ? 'end' : 'end'}
    >
      {payload.value.toUpperCase()} {/* Transform label to uppercase */}
    </CustomTick>
  );
};

// Custom Y Axis Tick
const CustomYAxisTick: React.FC<{ x: number; y: number; payload: any; isDeci: boolean; prefix?: string }> = ({ x, y, payload, prefix = "" }) => {
  return (
    <CustomTick
      x={x - 10}
      y={y + 4}
      textAnchor="start"
    >
      {prefix}{formatIntlNumber(payload.value)}
    </CustomTick>
  );
};

const SimpleLineGraph: React.FC<SimpleLineGraphProps> = (props) => {
  const {
    data,
    config = {},
  } = props;
  const {
    lineColor = '#0063C3',
    height = '100%',
    width = '100%',
    marginTop = 16,
    marginBottom = 4,
    marginLeft = -24,
    marginRight = 4,
    xAxisLine = { stroke: '#00000026' },
    xAxisInterval = 0,
    yAxisTickMargin = 16,
    yAxisDomain = [0, 100],
    xAxisDataKey = 'name',
    lineStrokeWidth = 2,
    lineDataKey = 'value',
    targetReferenceLineStroke = '#00000026',
    thresholdReferenceLineStroke = '#00000026',
    thresholdReferenceStrokeDashArray = '3 3',
    xAxisTickLine = false,
    yAxisLine = false,
    yAxisTickLine = false,
    lineDot = false,
    strokeDashedarray = "",
    grid = {
      stroke: '#ccc',
      strokeDashedarray: '3 3'
    },
    thresholdReferenceValue,
    targetReferenceValue,
    tooltipContent,
    xStartLabel,
    xEndLabel,
    yEndLabel,
    lineGraphType,
    YAxisPrefix
  } = config;

  const numericData = data.filter((item) => typeof item.value === 'number') as { value: number }[];
  const isDeci = hasDecimalValues(numericData);
  const computedYEndLabel = computeYEndLabel(numericData, isDeci);

  const graphType = lineGraphType ?? "monotone"
  return (
    <ResponsiveContainer width={width}  aspect={3}>
      <ComposedChart
        data={data}
        margin={{ top: marginTop, right: marginRight, bottom: marginBottom, left: marginLeft }}
      >
        <XAxis
          dataKey={xAxisDataKey} // Mapping x-axis data here
          tick={(props) => <CustomXAxisTick {...props} startLabel={xStartLabel ?? data[0]?.name} endLabel={xEndLabel ?? data[data.length - 1]?.name} />}
          axisLine={xAxisLine}
          tickLine={xAxisTickLine}
          interval={xAxisInterval}
        />
        <YAxis
          tick={(props) => <CustomYAxisTick {...props} prefix={YAxisPrefix} />}
          axisLine={yAxisLine}
          tickLine={yAxisTickLine}
          tickMargin={yAxisTickMargin}
          domain={[0, yEndLabel ?? computedYEndLabel]}
          ticks={[0, yEndLabel ?? computedYEndLabel]}
        />
        {grid.stroke ? <CartesianGrid
          stroke={String(grid?.stroke)}
          strokeDasharray={grid?.strokeDashedarray}
          horizontal={true}
          vertical={false}
        /> : null}
        {graphType === "linear" && <Area type={graphType} dataKey={lineDataKey} fill="rgba(0, 122, 255, 0.2)" stroke="none" />}
        <Line
          type={graphType as CurveType}
          dataKey={lineDataKey}
          stroke={targetReferenceValue ? (lineDataKey >= targetReferenceValue ? "#E01029" : "#2CC84D") : lineColor}
          strokeWidth={lineStrokeWidth}
          dot={lineDot}
          strokeDasharray={strokeDashedarray} />
        <ReferenceLine y={targetReferenceValue} stroke={targetReferenceLineStroke} strokeDasharray="5 5" />
        <ReferenceLine y={yEndLabel ?? computedYEndLabel} stroke={targetReferenceLineStroke} />
        {thresholdReferenceValue && <ReferenceLine y={thresholdReferenceValue}
          stroke={thresholdReferenceLineStroke}
          strokeDasharray={thresholdReferenceStrokeDashArray} />  /* Threshold line */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineGraph;