import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, MINOR_DEVIATION, RFT, QUALITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface QualityRftInfoState {
  qualityRFTInfo: APIResponseDataType[] | null;
  status: string;
  error: boolean;
}

const initialState: QualityRftInfoState = {
  qualityRFTInfo: null,
  status: 'idle',
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, QUALITY, BASE_VIEW, RFT];
const fetchQualityRFT = fetchApi(segments);

const qualityRFTSlice = createSlice({
    name: 'qualityRFT',
    initialState,
    reducers: {
        clearQualityRFTData(state) {
            state.qualityRFTInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQualityRFT.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchQualityRFT.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.qualityRFTInfo = action.payload
            })
            .addCase(fetchQualityRFT.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchQualityRFT }

export const { clearQualityRFTData } = qualityRFTSlice.actions

export default qualityRFTSlice.reducer