import styled from '@emotion/styled';

interface StyledHrProps {
  style?: React.CSSProperties;
}

const StyledHr = styled.hr<StyledHrProps>`
  border: none;
  background-color: #F0F0F0;
  height: 2px;
  margin: 4px 0;
  ${(props) => props.style && { ...props.style }}
`;

export default StyledHr;
