import AohTable from "../../common/table/AohTable";
import AohDropdown from "components/common/dropdown/aohdropdown";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Safety.scss";
import { useContext, useEffect, useState } from "react";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import AohTableWithLabels from "components/common/table/AohTableWithLabels";
import HorizontalBarChart from "components/common/HorizontalBar/HorizontalBarChart";
import {
    capaColumns,
    deepDiveStackedInterval,
    incaOpenIncidentsColumns,
    seecardColumns,
    workOrderColumns,
} from "utils/constants";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { MenuProps } from "antd";
import { fetchIncaHbcTimePeriodDeepDive, fetchIncaTimePeriodDeepDive } from "reducers/safety-deep-dive/incaDeepDiveReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { StringKeyAnyDataProps } from "utils/data-types";
import { AppContext } from "context/app-context";
import { downloadCSV, filterDuplicateStrings, generateCSV } from "utils/common-methods";
import ErrorHandler from "utils/error-handler";
import { RadioFilter } from "components/resuable/radio-filter/RadioFilter";
import { formatMonthDay } from "components/common/StackedBar/utils";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";

interface SafetyTier3Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

interface HeaderDropDownType {
    key: string;
    label: string;
}

type TimePeriod = "Last Week" | "Last Month" | "Last 3 Months";

const SafetyTier3: React.FC<SafetyTier3Props> = ({
    data,
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const { appState } = useContext(AppContext);
    const [filteredByCostCenterData, setFilteredByCostCenterData] = useState(
        data?.ehss?.complianceRateData
    );
    const dispatch = useDispatch<AppDispatch>();

    const { incaTimePeriodInfo, loading, error } = useSelector((state: RootState) => state.incaDeepDive)
    const { incaHbcTimePeriodInfo, loading2, error2 } = useSelector((state: RootState) => state.incaDeepDive)
    const [incaTimePeriodData, setIncaTimePeriodData] = useState<StringKeyAnyDataProps>(data?.inca);
    const [incaHbcTimePeriodData, setIncaHbcTimePeriodData] = useState<StringKeyAnyDataProps>(data?.inca);
    const [currentCostCenter, setCurrentCostCenter] =
        useState<HeaderDropDownType>({
            key: "All Cost Centers",
            label: "All Cost Centers",
        });

    const costCenterDropDownData: HeaderDropDownType[] = [
        { key: "All Cost Centers", label: "All Cost Centers" },
        ...Array.from(
            new Set(
                data?.ehss?.complianceRateData?.map(
                    (item: { cost_center_name: string }) => item.cost_center_name
                )
            )
        )
            .filter(
                (costCenterName): costCenterName is string =>
                    costCenterName !== undefined && costCenterName !== null
            )
            .map((uniqueCostCenter: string) => ({
                key: uniqueCostCenter,
                label: uniqueCostCenter,
            })),
    ];

    const typeBarChartConfig = {
        width: 560,
        height: 240,
        xAxisKey: incaHbcTimePeriodData?.dataParams?.typeBarChartParams?.xAxisKey ?? "value",
        yAxisKey: incaHbcTimePeriodData?.dataParams?.typeBarChartParams?.yAxisKey ?? "name",
        xAxisMax: incaHbcTimePeriodData?.dataParams?.typeBarChartParams?.xAxisMax,
        hideXAxisTicks: true,
        hideYAxisTicks: true,
        showYAxisLine: false
    }

    const priorityBarChartConfig = {
        width: 560,
        height: 240,
        xAxisKey: incaHbcTimePeriodData?.dataParams?.priorityBarChartParams?.xAxisKey ?? "value",
        yAxisKey: incaHbcTimePeriodData?.dataParams?.priorityBarChartParams?.yAxisKey ?? "name",
        xAxisMax: incaHbcTimePeriodData?.dataParams?.priorityBarChartParams?.xAxisMax,
        hideXAxisTicks: true,
        hideYAxisTicks: true,
        showYAxisLine: false
    }

    const handleCostCenterClick: MenuProps["onClick"] = (
        selectedCostCenter: any
    ) => {
        setCurrentCostCenter({
            key: selectedCostCenter.key,
            label: selectedCostCenter.key,
        });

        const filteredData =
            selectedCostCenter.key === "All Cost Centers"
                ? data?.ehss?.complianceRateData
                : data?.ehss?.complianceRateData?.filter(
                    (item: { cost_center_name: string }) =>
                        item?.cost_center_name === selectedCostCenter.key
                );

        setFilteredByCostCenterData([...filteredData]);
    };

    // --- Stacked bar charts logic ----

    // Inca Incidents Chart

    const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriod>("Last Week");
    const [selectedHbcTimePeriod, setSelectedHbcTimePeriod] = useState<TimePeriod>("Last Week");

    const timePeriods: TimePeriod[] = ["Last Week", "Last Month", "Last 3 Months"];
    const hbcTimePeriods: TimePeriod[] = ["Last Week", "Last 3 Months"];

    const filterDataByTimePeriod = (period: TimePeriod) => {
        const params = {
            workStream: appState.workStream,
            site: appState.site,
            persona: appState.persona,
            tierNumber: tierLabel.toLowerCase(),
            interval: deepDiveStackedInterval[period]
        };
        dispatch(fetchIncaTimePeriodDeepDive(params));
    }

    const filterHbcDataByTimePeriod = (period: TimePeriod) => {
        const params = {
            workStream: appState.workStream,
            site: appState.site,
            persona: appState.persona,
            tierNumber: tierLabel.toLowerCase(),
            interval: deepDiveStackedInterval[period]
        };
        dispatch(fetchIncaHbcTimePeriodDeepDive(params));
    }

    useEffect(() => {
        if (incaTimePeriodInfo) {
            setIncaTimePeriodData(incaTimePeriodInfo);
        } else if (data?.inca) {
            setIncaTimePeriodData(data.inca);
        }
    }, [incaTimePeriodInfo]);

    useEffect(() => {
        if (incaHbcTimePeriodInfo) {
            setIncaHbcTimePeriodData(incaHbcTimePeriodInfo);
        } else if (data?.inca) {
            setIncaHbcTimePeriodData(data.inca);
        }
    }, [incaHbcTimePeriodInfo]);

      // Extract xAxisData and map it separately
      let xAxisData: any = [];
      if (Array.isArray(incaTimePeriodData?.data)) {
        // First, extract the dates based on the xAxisKey or fallback to 'date_of_incident'
        const extractedDates = incaTimePeriodData?.data.map((item: any) => {
          const dateValue = item[incaTimePeriodData?.dataParams?.xAxisKey ?? 'date_of_incident'];
          return dateValue;
        });
          // Then, apply formatDayMonth to each extracted date value
        xAxisData = filterDuplicateStrings(extractedDates.map((dateValue: any) => {
          return formatMonthDay({ date: String(dateValue) });
        }));
      }

    const safetyDeepDive: any = {
        "SEE Cards": {
            title2: "Hot Spot Area",
            button2: (
                <Button
                    label="Download"
                    className={(!data?.seeCards?.open_see_data || data?.seeCards?.open_see_data.length === 0) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (data?.seeCards?.open_see_data && seecardColumns) {
                            const csvContent = generateCSV(data?.seeCards?.open_see_data, seecardColumns);
                            downloadCSV(csvContent, "hot_spot_area.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={(!data?.seeCards?.open_see_data || data?.seeCards?.open_see_data.length === 0) ? true : false}
                />
              ),
            table2: (
                <AohTable
                    tableData={data?.seeCards?.open_see_data}
                    tableColumns={seecardColumns}
                />
            ),
        },
        "INCA Incidents": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={data?.inca?.summary}
                />
            ),
            title: "Number of Incidents",
            radioFilters: (
                <RadioFilter
                    label="Time Period"
                    options={timePeriods}
                    selectedOption={selectedTimePeriod}
                    setSelectedOption={setSelectedTimePeriod}
                    filterDataByOption={filterDataByTimePeriod}
                />
            ),
            stackedBar: (
                <StackedBarChart
                    data={incaTimePeriodData?.data}
                    xAxisData={Array.isArray(incaTimePeriodData?.data) ? filterDuplicateStrings((incaTimePeriodData?.data)?.map((item: any) => item[incaTimePeriodData?.dataParams?.xAxisKey ?? 'date_of_incident'])) : []}
                    chartConfig={incaTimePeriodData?.dataParams?.trendChartParams?.chartConfig}
                    yAxisMax={incaTimePeriodData?.dataParams?.trendChartParams?.yAxisMax}
                    showSlider={incaTimePeriodData?.dataParams?.trendChartParams?.showSlider}
                    // grid={{ left: "0%" }}
                    chartType="global"
                />
            ),
            title2: "Incidents",
            radioFilters2: (
                <RadioFilter
                    label="Time Period"
                    options={hbcTimePeriods}
                    selectedOption={selectedHbcTimePeriod}
                    setSelectedOption={setSelectedHbcTimePeriod}
                    filterDataByOption={filterHbcDataByTimePeriod}
                />
            ),
            titleLeft: "By Type",
            componentLeft: (
                <HorizontalBarChart
                    data={incaHbcTimePeriodData?.inca_bar_chart_type}
                    config={typeBarChartConfig} />
            ),
            titleRight: "By Priority",
            componentRight: (
                <HorizontalBarChart
                    data={incaHbcTimePeriodData?.inca_bar_chart_priority}
                    config={priorityBarChartConfig} />
            ),
            title3: "Open Incident List",
            button3: (
                <Button
                    label="Download"
                    className={(!data?.inca?.inca_global_ehss || data?.inca?.inca_global_ehss.length === 0) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (data?.inca?.inca_open_incidents && incaOpenIncidentsColumns) {
                            const csvContent = generateCSV(data?.inca?.inca_open_incidents, incaOpenIncidentsColumns);
                            downloadCSV(csvContent, "open_incident_list.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={(!data?.inca?.inca_global_ehss || data?.inca?.inca_global_ehss.length === 0) ? true : false}
                />
            ),
            table3: (
                <AohTable
                    tableData={data?.inca?.inca_open_incidents}
                    tableColumns={incaOpenIncidentsColumns}
                />
            ),
        },
        "Safety Critical Work Orders": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    data={data?.summary}
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                />
            ),
            title: "Open Safety Critical Work Orders",
            button: (
                <Button
                    label="Download"
                    className={(!data?.workOrderDeepDive || data?.workOrderDeepDive.length === 0) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (data?.workOrderDeepDive && workOrderColumns) {
                            const csvContent = generateCSV(data?.workOrderDeepDive, workOrderColumns);
                            downloadCSV(csvContent, "open_safety_critical_work_orders.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={(!data?.workOrderDeepDive || data?.workOrderDeepDive.length === 0) ? true : false}
                />
            ),
            table: (
                <AohTableWithLabels
                    tableData={data?.workOrderDeepDive}
                    tableColumns={workOrderColumns}
                />
            ),
        },
        "EHSS Training Compliance": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    data={data?.ehss?.summary}
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                />
            ),
            title: "EHSS Compliance Rate",
            subtitle: "For Last 5 weeks",
            dropdown: (
                <div className="tier-dropdown">
                    <AohDropdown
                        items={costCenterDropDownData}
                        title="Cost Center"
                        dropOptions={currentCostCenter?.label}
                        handleMenuClick={handleCostCenterClick}
                    />
                </div>
            ),
            heatmap: (
                <ColorCodedTable
                    data={filteredByCostCenterData ?? data?.ehss?.complianceRateData}
                />
            ),
        },
        "Safety CAPAs": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    data={data?.capa?.summary}
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                />
            ),
            title: "Unclosed CAPAs",
            button: (
                <Button
                    label="Download"
                    className={(!data?.capa?.data || data?.capa?.data.length === 0) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (data?.capa?.data && capaColumns) {
                            const csvContent = generateCSV(data?.capa?.data, capaColumns);
                            downloadCSV(csvContent, "unclosed_capas.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={(!data?.capa?.data || data?.capa?.data.length === 0) ? true : false}
                />
            ),
            table: (
                <AohTable tableData={data?.capa?.data} tableColumns={capaColumns} />
            ),
        },
    };

    return (
        <div className="safety-tier-main">
            {safetyDeepDive[activeKeyDeepDiveTab]?.summaryTitle && (
                <div className="tier-container">
                    <h2 className="tier-title">
                        {/* Summary card */}
                        {safetyDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                    </h2>
                </div>
            )}
            {safetyDeepDive[activeKeyDeepDiveTab]?.summary}
            <div
                className={
                    safetyDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                }
            ></div>
            {/* Title card-1 */}
            <div className="tier-container">
                {safetyDeepDive[activeKeyDeepDiveTab]?.title && (
                    <h2 className="tier-title">
                        {safetyDeepDive[activeKeyDeepDiveTab]?.title}
                    </h2>
                )}
                {safetyDeepDive[activeKeyDeepDiveTab]?.button}
            </div>
            {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown}
            {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle && (
            <div className="tier-subtitle">
                {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            )}
            {/* main component-1 */}
            {safetyDeepDive[activeKeyDeepDiveTab].heatmap}
            {safetyDeepDive[activeKeyDeepDiveTab].table}
            {safetyDeepDive[activeKeyDeepDiveTab].radioFilters}
            {loading || error ? (
                <div className="loader">
                    <ErrorHandler isLoading={loading} isError={error} loaderColor={'BLUE'} />
                </div>
            ) : (
                safetyDeepDive[activeKeyDeepDiveTab].stackedBar
            )}
            <div
                className={
                    safetyDeepDive[activeKeyDeepDiveTab]?.heatmap ||
                        safetyDeepDive[activeKeyDeepDiveTab].table
                        ? "mr-bottom-20"
                        : ""
                }
            ></div>

            {/* Title card-2*/}
            <div className="tier-container">
                {safetyDeepDive[activeKeyDeepDiveTab]?.title2 && (
                    <h2 className="tier-title">
                        {safetyDeepDive[activeKeyDeepDiveTab]?.title2}
                    </h2>                
                )}
                {safetyDeepDive[activeKeyDeepDiveTab]?.button2}
            </div>
            {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown2}
            {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2 && (
            <div className="tier-subtitle">
                {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2}
            </div>
            )}
            {safetyDeepDive[activeKeyDeepDiveTab].radioFilters2}
            {/* main component-2 */}
            {safetyDeepDive[activeKeyDeepDiveTab].heatmap2}
            {safetyDeepDive[activeKeyDeepDiveTab].table2}
            <div className="tier-sections-container">
                {/* section 1 */}
                {safetyDeepDive[activeKeyDeepDiveTab]?.componentLeft && (
                    <div className="tier-container section section-left">
                        {safetyDeepDive[activeKeyDeepDiveTab]?.titleLeft && (
                            <h2 className="tier-title">
                                {safetyDeepDive[activeKeyDeepDiveTab]?.titleLeft}
                            </h2>)}
                        {loading2 || error2 ? (
                            <div className="loader">
                                <ErrorHandler isLoading={loading2} isError={error2} loaderColor={'BLUE'} />
                            </div>
                        ) : (
                            safetyDeepDive[activeKeyDeepDiveTab]?.componentLeft
                        )}
                    </div>
                )}

                {/* section 2 */}
                {safetyDeepDive[activeKeyDeepDiveTab]?.componentRight && (
                    <div className="tier-container section section-right">
                        {safetyDeepDive[activeKeyDeepDiveTab]?.titleRight && (
                            <h2 className="tier-title">
                                {safetyDeepDive[activeKeyDeepDiveTab]?.titleRight}
                            </h2>)}
                        {loading2 || error2 ? (
                            <div className="loader">
                                <ErrorHandler isLoading={loading2} isError={error2} loaderColor={'BLUE'} />
                            </div>
                        ) : (
                            safetyDeepDive[activeKeyDeepDiveTab]?.componentRight
                        )}
                    </div>
                )}
            </div>
            <div
                className={
                    safetyDeepDive[activeKeyDeepDiveTab]?.heatmap2 ||
                        safetyDeepDive[activeKeyDeepDiveTab].table2 ||
                        safetyDeepDive[activeKeyDeepDiveTab].component2
                        ? "mr-bottom-20"
                        : ""
                }
            ></div>

            {/* Title card-3*/}
            <div className="tier-container">
                {safetyDeepDive[activeKeyDeepDiveTab]?.title3 && (
                    <h2 className="tier-title">
                        {safetyDeepDive[activeKeyDeepDiveTab]?.title3}
                    </h2>
                )}
                {safetyDeepDive[activeKeyDeepDiveTab]?.button3}
            </div>
            {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown3}
            {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle3 && (
            <div className="safety-tier-subtitle">
                {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle3}
            </div>
            )}
            {/* main component-3 */}
            {safetyDeepDive[activeKeyDeepDiveTab].table3}
        </div>
    );
};
export default SafetyTier3;
