import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, INVENTORY, METRICS, TURN_OVER, VERSION, WORK_CENTER_TEAM } from 'utils/constants'

interface TurnOverInfoState {
    turnOverInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: TurnOverInfoState = {
    turnOverInfo: null,
    loading: false,
    error: false
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, INVENTORY, BASE_VIEW, TURN_OVER];
const fetchTurnOver = fetchApi(segments);

const turnOverReducer = createSlice({
    name: 'turnOver',
    initialState,
    reducers: {
        clearTurnOverData(state) {
            state.turnOverInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTurnOver.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchTurnOver.fulfilled, (state, action) => {
                state.loading = false;
                state.turnOverInfo = action.payload
            })
            .addCase(fetchTurnOver.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchTurnOver };

export const { clearTurnOverData } = turnOverReducer.actions

export default turnOverReducer.reducer;