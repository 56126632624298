import { ProductivityDdvT0TableColumns } from "utils/wctConstants";
import RenderTierWiseComponent from "../delivery-tierwise/RenderTierWiseComponent";
import "./Productivity.scss";
import { fetchmajorEqDowntimeDdv } from "reducers/productivity-deep-dive/majorEqDowntimeDdvReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { useState } from "react";

interface ProductivityTier0Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}
const ProductivityTier0: React.FC<ProductivityTier0Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab
}) => {
  const [filter, setFilter] = useState<any>({})

  const [filteSelect, setFilterSelect] = useState<{ [key: string]: any }>({
    majorEqDowntime: 'All'
  })

  const dispatch = useDispatch<AppDispatch>();

  const handleMenuClick = (selected: any, filterType: string, filterValue?: string) => {

    setFilterSelect(prev => ({ ...prev, [filterType]: selected.key }));
    const params = {
      workStream: "manufacturing",
      site: "AOH",
      persona: "wct",
      tierNumber: "tier 0",
      filterType,
      filterValue,
      line: selected.key
    }
    dispatch(fetchmajorEqDowntimeDdv(params as any));
  }
  return (
    <div className="productivity-tier-main">
      {data?.map((item: any, index: number | null | undefined) => {
        return (
          <RenderTierWiseComponent
            activeKeyDeepDiveTab={activeKeyDeepDiveTab}
            tierLabel={tierLabel}
            key={index}
            data={item}
            deepDiveTableColumns={ProductivityDdvT0TableColumns}
            handleMenuClick={handleMenuClick}
            extraFilterselect={filteSelect}
            activeFilter={filter}
          />
        )
      })}
    </div>
  );
};
export default ProductivityTier0;
