import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { DEEP_DIVE_VIEW, INVENTORY, KANBAN_HEALTH, METRICS, WORK_CENTER_TEAM } from 'utils/constants';

interface KanbanHealthDdvStateProps {
    kanbanHealthDdvInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: KanbanHealthDdvStateProps = {
    kanbanHealthDdvInfo: null,
    loading: false,
    error: false
};

const segments = [WORK_CENTER_TEAM, METRICS, INVENTORY, DEEP_DIVE_VIEW, KANBAN_HEALTH];
const fetchKanbanHealthDdv = fetchApi(segments);

const kanbanHealthDdvReducer = createSlice({
    name: 'kanbanHealthDdv',
    initialState,
    reducers: {
        clearKanbanHealthDdvData(state) {
            state.kanbanHealthDdvInfo = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchKanbanHealthDdv.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchKanbanHealthDdv.fulfilled, (state, action) => {
                state.loading = false;
                state.kanbanHealthDdvInfo = action.payload;
            })
            .addCase(fetchKanbanHealthDdv.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
});

export const { clearKanbanHealthDdvData } = kanbanHealthDdvReducer.actions;
export { fetchKanbanHealthDdv };
export default kanbanHealthDdvReducer.reducer;
