import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';
import { CHANGE_CONTROL_DEEP_DIVE_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types';

interface changeControlSummaryItem {
  coming_due_cc?: number | string;
  open_cc?: number | string;
  overdue_change_control?: number | string;
  perc_aging_cc?: number | string;
  perc_adherence_mtd?: number | string;
  perc_aging_mtd?: number | string;
}

interface changeControlTableItem {
  category_type?: number | string;
  cc_id?: number | string;
  cc_owner?: number | string;
  closure_date?: number | string;
  function?: number | string;
  initiated_date?: number | string;
  qa_owner?: number | string;
  short_description?: number | string;
  status?: number | string;
  target_due_date?: number | string;
}

interface graphItemAging {
  aging_last_7days?: number | string;
  date?: number | string;
}

interface graphItemNotAging {
  normal_last_7days?: number | string;
  date?: number | string;
}

interface graphItemTrend {
  coming_due_last_7d_count?: number | string;
  date?: number | string;
}

interface graphItemAdherence6m {
  compliance_date?: number | string;
  compliance_rate?: number | string;
  not_on_time_cc?: number | string;
  on_time_cc?: number | string;
}

interface graphItemAdherenceMtd {
  compliance_date?: number | string;
  compliance_rate?: number | string;
  not_on_time_cc?: number | string;
  on_time_cc?: number | string;
}

interface graphItemAging6m {
  compliance_date?: number | string;
  compliance_rate?: number | string;
  aging_cc?: number | string;
  not_aging_cc?: number | string;
}

interface graphItemAgingMtd {
  compliance_date?: number | string;
  compliance_rate?: number | string;
  aging_cc?: number | string;
  not_aging_cc?: number | string;
}

interface changeControlDataType {
  summary: changeControlSummaryItem;
  tableDataAll: changeControlTableItem[];
  tableDataOverdue: changeControlTableItem[];
  tableDataComingDue7Days: changeControlTableItem[];
  tableDataAging: changeControlTableItem[];
  tableDataOpen: changeControlTableItem[];
  graphDataAging: graphItemAging[];
  graphDataNotAging: graphItemNotAging[];
  graphDataTrend: graphItemTrend[];
  adherenceGraphData6m: graphItemAdherence6m[];
  adherenceGraphDataMtd: graphItemAdherenceMtd[];
  agingOpenGraphData6m: graphItemAging6m[];
  agingOpenGraphDataMtd: graphItemAgingMtd[];
  graphData: StringKeyDataProps[];
}

interface changeControlInfoState {
  changeControlDeepDiveInfo: changeControlDataType | null;
  loading: boolean;
  error: boolean;
}

const initialState: changeControlInfoState = {
  changeControlDeepDiveInfo: null,
  loading: false,
  error: false,
};

interface FetchDataParams {
  workstream?: string | undefined,
  site?: string | undefined,
  persona?: string | undefined,
  tierNumber?: string | undefined,
  shift?: string | undefined,
  costCenter?: string | undefined,
  line?: string | undefined,
  building?: string | undefined
}

const fetchChangeControlDeepDive = createAsyncThunk<changeControlDataType, FetchDataParams>(
  CHANGE_CONTROL_DEEP_DIVE_URL,
  async (params) => {
    const { workstream, site, persona, tierNumber, shift, costCenter, line, building } = params;
    const token: string = getOktaToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}${CHANGE_CONTROL_DEEP_DIVE_URL}?workStream=${workstream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&building=${building}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Change Control');
    }
    return await response.json();
  }
)

const changeControlDeepDiveReducer = createSlice({
  name: 'changeControlDeepDive',
  initialState,
  reducers: {
    clearQCCDD(state) {
      state.changeControlDeepDiveInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChangeControlDeepDive.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchChangeControlDeepDive.fulfilled, (state, action) => {
        state.loading = false;
        state.changeControlDeepDiveInfo = action.payload;
      })
      .addCase(fetchChangeControlDeepDive.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export { fetchChangeControlDeepDive }

export const { clearQCCDD } = changeControlDeepDiveReducer.actions

export default changeControlDeepDiveReducer.reducer;