import React from 'react';
import ReactECharts from 'echarts-for-react';
import { EChartsOption } from 'echarts';
import { HorizontalBarChartDataProps, HorizontalBarChartProps } from './HorizontalBarChart.types';
import Legend from '../StackedBar/Legend';

// Define the axis label style
const axisLabel = {
    fontSize: 10,
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: 14,
    color: '#595959'
} as const;

// Define custom chart configuration
const customChartConfig = {
    barWidth: 16,
    barGap: 20,
    itemStyle: {
        color: '#0063C3'
    }
}

// Define the HorizontalBarChart component with a generic type T
const HorizontalBarChart = <T extends HorizontalBarChartDataProps>({
    data,
    config = {},
    legendItems
}: HorizontalBarChartProps<T>) => {
    const {
        isConditionalColor = false,
        conditionalColor,
        markLine,
        height = '100%',
        width = '100%',
        xAxisLabel,
        yAxisLabel,
        xAxisKey = 'value',
        yAxisKey = 'name',
        showYAxis = true,
        hideXAxisTicks = false,
        hideYAxisTicks = false,
        showYAxisLine = true,
        gridTop = 24,
        gridBottom = 64,
        gridLeft = 24,
        gridRight = 24,
        gridContainLabel = true,
        xAxisNameGap = 50,
        yAxisNameGap = 50,
        yAxisMax
    } = config;

    const calculatedYAxisMax = data && data.length > 0 
        ? Math.ceil(Math.max(...data.map((item: any) => item[xAxisKey])) / 5) * 5
        : 15; 

    const xAxisConfig: any = {
        type: 'value',
        name: xAxisLabel,
        nameLocation: 'middle',
        nameGap: xAxisNameGap,
        axisTick: {
            show: !hideXAxisTicks
        },
        axisLabel,
        nameTextStyle: axisLabel
    };

    if (yAxisMax !== undefined) {
        let finalYAxisMax = yAxisMax !== undefined ? yAxisMax : calculatedYAxisMax;

        if (calculatedYAxisMax > finalYAxisMax) {
            finalYAxisMax = calculatedYAxisMax;
        }
    
        xAxisConfig.max = finalYAxisMax;  // Apply the max if yAxisMax is provided
    }

    const options: EChartsOption = React.useMemo(() => ({
        grid: {
            top: gridTop,
            bottom: gridBottom,
            left: gridLeft,
            right: gridRight,
            containLabel: gridContainLabel
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: (params: any) => {
                const dataIndex = params[0].dataIndex;
                const dataItem = data[dataIndex];
                const adherenceRatio = dataItem.adherence_ratio !== undefined ? `<br/><b>Adherence Ratio:</b> ${dataItem.adherence_ratio}` : '';
                return `<b>${params[0].name}</b><br/><b>Value:</b> ${params[0].value}${adherenceRatio}`;
            }
        },
        xAxis: xAxisConfig,
        yAxis: {
            type: 'category',
            name: yAxisLabel,
            nameLocation: 'middle',
            nameGap: yAxisNameGap,
            data: data?.map((item: any) => item[yAxisKey]),
            show: showYAxis,
            axisTick: {
                show: !hideYAxisTicks
            },
            axisLine: {
                show: showYAxisLine
            },
            axisLabel,
            nameTextStyle: axisLabel
        },
        series: [
            {
                type: 'bar',
                markLine,
                data: (isConditionalColor && conditionalColor) ? data.map(conditionalColor) : data?.map((item: any) => item[xAxisKey]),
                ...customChartConfig
            }
        ]
    }), [data, xAxisLabel, yAxisLabel, xAxisKey, yAxisKey, showYAxis, hideXAxisTicks, hideYAxisTicks, showYAxisLine, gridTop, gridBottom, gridLeft, gridRight, gridContainLabel, xAxisNameGap, yAxisNameGap]);
    return (
        <>
            <ReactECharts
                option={options}
                style={{ width, height }}
            />
            {Array.isArray(legendItems) && legendItems.length > 0 && (
                <Legend
                    legendItems={legendItems}
                />
            )}
        </>
    );
};

export default HorizontalBarChart;