import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, PRODUCTIVITY, SETUP_CLOSEDOWN_ADHERENCE, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface SetupClosedownAdherenceBvInfoState {
  setupClosedownAdherenceBvInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: SetupClosedownAdherenceBvInfoState = {
  setupClosedownAdherenceBvInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, PRODUCTIVITY, BASE_VIEW, SETUP_CLOSEDOWN_ADHERENCE];
const fetchSetupClosedownAdherenceBv = fetchApi(segments);

const setupClosedownAdherenceBvReducer = createSlice({
  name: 'setupClosedownAdherenceBv',
  initialState,
  reducers: {
    clearSetupClosedownAdherenceBvData(state) {
      state.setupClosedownAdherenceBvInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSetupClosedownAdherenceBv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchSetupClosedownAdherenceBv.fulfilled, (state, action) => {
        state.loading = false;
        state.setupClosedownAdherenceBvInfo = action.payload
      })
      .addCase(fetchSetupClosedownAdherenceBv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

  },
})

export { fetchSetupClosedownAdherenceBv };

export const { clearSetupClosedownAdherenceBvData } = setupClosedownAdherenceBvReducer.actions

export default setupClosedownAdherenceBvReducer.reducer;