import React, { useEffect, useState } from 'react';
import { Spin } from 'antd'; // Import Spin component from antd
import { LoadingOutlined } from '@ant-design/icons'; // Import LoadingOutlined icon from antd
import CardContentRow from './StyledCardContentRow';
import StyledCard from './StyledCard';
import StyledTag from './StyledTag';
import StyledCardHeader from './StyledCardHeader';
import StyledHorizontalBarChart from '../graph-components/StyledHorizontalBarChart'; // Import HorizontalBarChart component
import StyledPieChart from '../graph-components/StyledPieChart'; // Import StyledPieChart component
import StackedBarChart from '../graph-components/StyledStackBarChart'; // Import StackBarChart component
import StyledHR from './StyledHr'; // Import StyledHR component
import StyledProgressBarChart from 'dct-component-library/graph-components/StyledProgressBarChart';
import StyledTooltip from './StyledTooltip'; // Adjust the import path as necessary
import StyledTrendGraph from 'dct-component-library/graph-components/StyledTrendGraph';
import StyledNoDataView from '../layouts/StyledNoDataView'; // Import StyledNoDataView component
import StyledHorizontalBulletChart from 'dct-component-library/graph-components/StyledHorizontalBulletChart';
import StyledTrendLineGraph from 'dct-component-library/graph-components/StyledTrendLineGraph';
import StyledMultiProgressBarChart from '../graph-components/StyledMultiProgressBarChart'; // Import StyledMultiProgressBarChart component

interface CardData {
  tooltipContent?: string;
  isCardTag?: boolean;
  isCard?: boolean;
  isCardTable?: boolean;
  isDividerLine?: boolean; // Add isDivider property
  isTrendGraph?: boolean; // Add isTrendGraph property
  isHorizontalBarGraph?: boolean; // Add isHorizontalBarGraph property
  isPieChart?: boolean; // Add isStyledPieChart property
  isProgressBarChart?: boolean; // Add isProgressBarChart property
  isStackBarChart?: boolean; // Add isStackBarChart property
  isHorizontalBulletChart?: boolean; // Add isHorizontalBulletChart property
  isMultiProgressBarChart?: boolean; // Add isMultiProgressBarChart property
  data: any;
  highLightedParams?: { field: string; color: string }[]; // Change highLightedParams to array of objects
  highlightedLabels?: { labelVal: string; color: string }[]; // Rename specificHighlights to highlightedLabels
  highlightStyles?: { [keyLabel: string]: { [key: string]: { styles: React.CSSProperties } } }; // Update highlightStyles property
  tableKey?: string; // Add tableKey property
  tableValue?: string; // Add tableValue property
  dataParams?: {
    alertType?: string;
    barColorCondition?: string;
    YAxisPrefix?: string;
    barPositiveColor?: string;
    barNegativeColor?: string;
  };
  trendGraphConfig?: any; // Add trendGraphConfig prop
  progressBarConfig?: any; // Add progressBarConfig prop
  pieChartConfig?: any; // Add pieChartConfig prop
  horizontalBarConfig?: any; // Add horizontalBarConfig prop
  stackBarConfig?: any; // Add stackBarConfig prop
  horizontalBulletChartConfig?: any; // Add horizontalBulletChartConfig prop
  multiProgressBarConfig?: any; // Add multiProgressBarConfig prop
  queryParams?: {
    tierLabel?: string;
  };
  separator?: boolean; // Add separator property
}

interface CardWrapperProps {
  data: CardData[];
  setIsOpenDeepDive?: (isOpen: boolean) => void;
  setActiveKeyDeepDiveTab?: (key: string) => void;
  title: string;
  referenceLine?: number;
  tooltipContent?: string;
  id?: string;
  cardStyle?: React.CSSProperties;
}

const CardWrapper: React.FC<CardWrapperProps> = ({
  data,
  setIsOpenDeepDive,
  setActiveKeyDeepDiveTab,
  title,
  id,
  cardStyle
}) => {
  const [loading, setLoading] = useState(true); // State to control spinner
  const [showNoData, setShowNoData] = useState(false); // State to control No Data view

  useEffect(() => {
    setLoading(true); // Reset loading state when data changes
    setShowNoData(false); // Reset No Data view when data changes
  }, [data]); // Add data as a dependency

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading) {
        setLoading(false);
        setShowNoData(true);
      }
    }, 15000); // Set timeout to 15 seconds

    if (data && Array.isArray(data) && data.length > 0) {
      clearTimeout(timeout);
      setLoading(false); // Stop spinner when data is loaded
      setShowNoData(false); // Ensure No Data view is not shown when data is available
    }

    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, [loading, data]); // Add loading and data as dependencies

  const defaultColors = ['#0063C3', '#00C9C9', '#FDB81E']; // Define default colors

  const renderCardContentRow = (row: any, rowIndex: number, item: CardData) => {
    const keyLabel = item.tableKey ? row[item.tableKey] : 'name';
    const valueLabel = item.tableValue ? row[item.tableValue] : 'value';
    const highlightStyles = item.tableValue ? item.highlightStyles?.[rowIndex]?.[item.tableValue]?.styles ?? {} : {}; // Correctly handle highlightStyles
    return (
      <React.Fragment key={`${rowIndex}-${keyLabel}`}>
        <CardContentRow
          key={`${rowIndex}-${keyLabel}`}
          keyLabel={keyLabel?.replace(/_/g, ' ')} // Remove underscores from keyLabel
          value={valueLabel}
          highlightStyles={highlightStyles} // Pass highlight styles to CardContentRow
        />
        {item.separator && rowIndex < item.data.length - 1 && <StyledHR />} {/* Render separator except for the last row */}
      </React.Fragment>
    );
  };

  return (
    <StyledCard
      id={id}
      style={{ ...cardStyle, minHeight: loading ? '80px' : 'auto' }} // Conditionally apply minHeight
      title={
        <StyledCardHeader
          title={title}
          setIsOpenDeepDive={setIsOpenDeepDive}
          setActiveKeyDeepDiveTab={setActiveKeyDeepDiveTab}
          style={{ cursor: 'pointer' }}
        />
      }
    >
      <Spin
        spinning={loading} // Use loading state to control spinner
        indicator={<LoadingOutlined style={{ fontSize: 24, color: 'gray' }} spin />}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80px' }} // Center the loader
      >
        {showNoData ? (
          <StyledNoDataView description="No Data Available" />
        ) : (
          Array.isArray(data) ? data.map((item, index) => {
            if (item.isCardTag) {
              return (
                <div key={index} style={{ display: 'block', width: '100%' }}>
                  <StyledTag>{item.data.cardTag}</StyledTag>
                </div>
              );
            }

            if (item.isCard) {
              return Object.keys(item.data).map((keyLabel, colIndex) => {
                const value = item.data[keyLabel];
                const highlightStyles = item.highlightStyles?.[keyLabel]?.styles ?? {}; // Correctly handle highlightStyles
                return (
                  <CardContentRow
                    key={`${0}-${colIndex}`}
                    keyLabel={keyLabel?.replace(/_/g, ' ')} // Remove underscores from keyLabel
                    value={value}
                    highlightStyles={highlightStyles} // Pass highlight styles to CardContentRow
                  />
                );
              });
            }

            if (item.isCardTable) {
              return (
                <React.Fragment key={index}>
                  {item.data.map((row: any, rowIndex: number) => (
                    <React.Fragment key={rowIndex}>
                      {row.cardTag && (
                        <div style={{ display: 'block', width: '100%' }}>
                          <StyledTag>{row.cardTag}</StyledTag>
                        </div>
                      )}
                      {renderCardContentRow(row, rowIndex, item)}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            }

            if (item.isDividerLine) {
              return <StyledHR key={index} />; // Render StyledHR when isDivider is true
            }

            if (item.isTrendGraph) {
              return (
                <StyledTrendLineGraph
                  key={index}
                  data={item.data}
                  tooltipContent={item.tooltipContent} // Pass tooltipContent prop
                  {...item.trendGraphConfig}
                />
              );
            }

            if (item.isHorizontalBarGraph) {
              const [minCondition, maxCondition] = item?.dataParams?.barColorCondition?.split('-') ?? [0, 0]; // Get min and max conditions
              const config = {
                gridBottom: 5,
                gridRight: 5,
                gridLeft: 5,
                gridTop: 5,
                barWidth: 10,
                conditionalColor: (value: any) => {
                  return (Number(minCondition) <= Math.abs(value?.value || 0) || Math.abs(value?.value || 0) >= Number(maxCondition)) ? item?.dataParams?.barNegativeColor : item?.dataParams?.barPositiveColor
                },
                ...item.horizontalBarConfig,
              }
              return (<StyledHorizontalBarChart key={index}
                data={item.data}
                config={{ ...config }}
                tooltipContent={item.tooltipContent}
              />)
            }

            if (item.isHorizontalBulletChart) {
              return (
                <StyledTooltip key={index} header={"Bullet Chart"} content={item?.tooltipContent}>
                  <StyledHorizontalBulletChart
                    data={item.data}
                    {...item.horizontalBulletChartConfig}
                  />
                </StyledTooltip>
              );
            }

            if (item.isPieChart) {
              return (
                <StyledTooltip key={index} content={item?.tooltipContent}>
                  <StyledPieChart data={item.data} config={item.pieChartConfig} />
                </StyledTooltip>
              )
            }

            if (item.isProgressBarChart) {
              return (
                <StyledTooltip key={index} content={item?.tooltipContent}>
                  <StyledProgressBarChart key={index} data={item.data} config={item.progressBarConfig} />
                </StyledTooltip>
              );
            }

            if (item.isStackBarChart) {
              return <StackedBarChart key={index} data={item.data} config={item.stackBarConfig} tooltipContent={item.tooltipContent}/>; // Render StackBarChart with config
            }

            if (item.isMultiProgressBarChart) {
              return (
                <StyledMultiProgressBarChart key={index} data={item.data} config={item.multiProgressBarConfig} />
              );
            }

            return null;
          }) : null
        )}
      </Spin>
    </StyledCard>
  );
};

export default CardWrapper;
