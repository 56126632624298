import React, { useEffect, useState, useCallback, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { Empty } from "antd";

interface StackedBarChartProps {
  data: {
    seriesData: any[];
    yAxisConfig: any[];
    xAxisData: any[];
    legend: string[];
  };
  timePeriod?: string;
  colors?: string[];
  excludeXAxisKeys?: string[];
  plotTypes?: string[];
  config?: {
    tooltip?: any;
    grid?: any;
    xAxis?: any;
    yAxis?: any;
    dataZoom?: any;
    legend?: any;
    [key: string]: any;
  };
  tooltipContent?: string;
  yAxisLabelFormatter?: (value: number) => string;
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({
  data,
  colors = ['#0063C3', '#FDB81E', '#2FBCB6'],
  config = {},
  tooltipContent = "",
  yAxisLabelFormatter = (value: number) => {
    if (value >= 1000) {
      return `${value / 1000}K`;
    }
    return value.toString();
  }
}) => {
  const [isChartReady, setIsChartReady] = useState(false);

  const generateChartOptions = useCallback((
    data: any,
    colors: string[],
    stackBarConfig: any
  ) => {
    const { seriesData, yAxisConfig, xAxisData, legend } = data;

    const legendData = legend.length > 0 ? legend : [
      'Random Legend 1',
      'Random Legend 2'
    ];

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          let tooltipHeader = `<div><strong>Bar Chart</strong></div><div style="white-space: pre-wrap;">${tooltipContent}</div>`; // Wrapped tooltipContent
          params.forEach((item: any) => {
            tooltipHeader += `<div>${item.marker} ${item.seriesName}: ${item.value}</div>`;
          });
          return tooltipHeader;
        },
        ...stackBarConfig.tooltip
      },
      grid: {
        left: '1%',
        right: '10%',
        bottom: '15%', // Adjusted to make space for the legend
        top: '10%',
        containLabel: true,
        ...stackBarConfig.grid
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        axisTick: { show: false },
        axisLabel: { color: 'rgba(0, 0, 0, 0.65)', fontSize: 12, interval: 0 },
        ...stackBarConfig.xAxis
      },
      yAxis: {
        ...yAxisConfig,
        axisLabel: {
          formatter: yAxisLabelFormatter,
          ...stackBarConfig.yAxis?.axisLabel
        },
        ...stackBarConfig.yAxis
      },
      series: seriesData,
      legend: {
        show: true,
        orient: 'horizontal',
        left: 'center', // Positioned to the center
        bottom: '1%', // Positioned at the bottom
        icon: 'circle',
        textStyle: {
          color: 'rgba(0, 0, 0, 0.65)',
          fontWeight: 700,
          fontSize: 9, // Made smaller
          lineHeight: 12 // Adjusted line height
        },
        formatter: (name: string) => name.toUpperCase(),
        ...stackBarConfig.legend
      }
    };
  }, [colors, config, yAxisLabelFormatter, tooltipContent]);

  const chartOptions = useMemo(() => generateChartOptions(data, colors, config), [data, colors, config, generateChartOptions]);

  useEffect(() => {
    setIsChartReady(true);
  }, [chartOptions]);

  return (
    <div style={{ width: "100%", marginLeft: "5px" }}>
      {data && data.seriesData.length > 0 ? (
        <>
          {isChartReady && <ReactECharts option={chartOptions} />}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Data Available"
        />
      )}
    </div>
  );
};

export default StackedBarChart;
