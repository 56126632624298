import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, CAPA, METRICS, QUALITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface SeeCardsInfoState {
  qualityCAPAInfo: APIResponseDataType[] | null;
  status: string;
  error: boolean;
}

const initialState: SeeCardsInfoState = {
  qualityCAPAInfo: null,
  status: 'idle',
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, QUALITY, BASE_VIEW, CAPA];
const fetchQualityCAPA = fetchApi(segments);

const qualityCAPASlice = createSlice({
    name: 'qualityCAPA',
    initialState,
    reducers: {
        clearQualityCAPAData(state) {
            state.qualityCAPAInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQualityCAPA.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchQualityCAPA.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.qualityCAPAInfo = action.payload
            })
            .addCase(fetchQualityCAPA.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchQualityCAPA }

export const { clearQualityCAPAData } = qualityCAPASlice.actions

export default qualityCAPASlice.reducer