import { axisLabelConfig, dataZoomConfig, layoutConfig, leftAxisConfig, nameTextStyleConfig, tooltipConfig, customConfig } from './chartConfig';
import { ChartConfigProps, COLORS, DataItem, EchartsGridProps, excludedKeys, LegendProps, NameStyleConfig, YAxisNamesProps } from './constants';
import { getSeriesData } from './seriesData';

export function addTransparency(color: string, opacity: number): string {
  const alpha = Math.round(opacity * 255).toString(16).padStart(2, '0').toUpperCase();
  return `${color}${alpha}`;
}

export function formatMonthYear(item: DataItem): string {
  const month = item.Month?.toString().padStart(2, "0") ?? "01";
  const year = item.Year?.toString().slice(-2) ?? "24";
  return `${month}/${year}`;
}

export function formatDayMonth(item: any): string {
  let date;

  // Check if item.date exists and it's a valid date string
  if (item?.month) {
    // Split the month-year string into month and year
    const [month, year] = item.month.split(' ');

    // Convert the month to uppercase (e.g., 'Jan' -> 'JAN')
    const monthAbbr = month.slice(0, 3).toUpperCase();

    // Get the last two digits of the year (e.g., '2025' -> '25')
    const yearAbbr = year.slice(-2);

    return `${monthAbbr} ${yearAbbr}`; // Return in 'MMM YY' format
  } else if (item?.start_date) {
    // If it's in 'DD/MM' format (e.g., '20/01')
    if (item.start_date.includes('/')) {
      const [month, day] = item.start_date.split('/'); // Split into day and month
      const currentYear = new Date().getFullYear(); // Get the current year
      // Construct the Date object with the current year, month, and day
      date = new Date(`${currentYear}-${month}-${day}`);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.error("Invalid date format in 'DD/MM' format");
        return 'Invalid Date';
      }
    } else {
      // If it's a full date (e.g., '2025-01-18')
      date = new Date(item.start_date);

      // Check if the full date is valid
      if (isNaN(date.getTime())) {
        console.error("Invalid full date format");
        return 'Invalid Date';
      }
    }
  } else if (item?.date) {
    // Check if the date is in 'DD/MM' format
    if (item.date.includes('/')) {
      const [day, month] = item.date.split('/'); // Split into day and month
      const currentYear = new Date().getFullYear(); // Get the current year
      // Construct the Date object with the current year, month, and day
      date = new Date(`${currentYear}-${month}-${day}`);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.error("Invalid date format in 'DD/MM' format");
        return 'Invalid Date';
      }
    } else {
      return item.date;
    }
  } else if (item?.name) {
    // Handle item.name similarly, in case it contains a date string
    date = new Date(item.name);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date format in item.name");
      return 'Invalid Date';
    }
  } else if (item?.mtd_date) {

    // Parse the date from item.mtd_date (assumed format: 'YYYY-MM-DD')
    date = new Date(item.mtd_date);

    if (isNaN(date.getTime())) {
      console.error("Invalid date format in item.mtd_date");
      return 'Invalid Date';
    }
  } else {
    // If neither date nor name is present, default to current date
    date = new Date();
  }

  // Extract the day and month
  const day = String(date.getDate()).padStart(2, "0"); // Ensure day is two digits
  const month = date.toLocaleString('en', { month: 'short' }).toUpperCase(); // Get the abbreviated month (e.g., 'JAN')

  return `${day} ${month}`; // Return in 'DD MMM' format
}

export function formatMonthDay(item: any): string {
  let date;

  // Check if item.date exists and it's a valid date string
  if (item?.date) {

    // If the date is in 'DD/MM' format (assuming MM/DD/YYYY format)
    if (item.date.includes('/')) {
      const [month, day] = item.date.split('/');
      const currentYear = new Date().getFullYear(); // Get current year
      date = new Date(`${currentYear}-${month}-${day}`);

      if (isNaN(date.getTime())) {
        console.error("Invalid date format in 'DD/MM' format");
        return 'Invalid Date';
      }
    } else {
      // If it's a full date (e.g., '2025-01-18')
      date = new Date(item.date);

      if (isNaN(date.getTime())) {
        console.error("Invalid full date format");
        return 'Invalid Date';
      }
    }
  } else if (item?.last_7d_date) {
    // Handle item.last_7d_date similarly if it contains a date string
    date = new Date(item.last_7d_date);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format in item.name");
      return 'Invalid Date';
    }
  } else if (item?.compliance_date) {
    // Handle item.compliance_date similarly if it contains a date string
    date = new Date(item.compliance_date);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format in item.name");
      return 'Invalid Date';
    }
  } else if (item?.name) {
    // Handle item.name similarly if it contains a date string
    date = new Date(item.name);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format in item.name");
      return 'Invalid Date';
    }
  } else {
    // If neither date nor name is present, default to current date
    date = new Date();
  }

  // Extract the day and month (in 'DD MMM' format)
  const day = String(date.getDate()).padStart(2, "0"); // Ensure day is two digits
  const month = date.toLocaleString('en', { month: 'short' }).toUpperCase(); // Get abbreviated month

  return `${day} ${month}`; // Return in 'DD MMM' format
}

export function NewformatMonthYear(item: any): string {
  const month = item?.Month;
  const year = item?.Year;

  if (month && year) {
    // Create a Date object using the first day of the given month and year
    const date = new Date(year, month - 1);  // month is 1-based, so subtract 1

    // Format the date to 'MMM YY'
    const formattedDate = date.toLocaleString('en-US', {
      month: 'short',  // "MMM" (abbreviated month)
      year: '2-digit'  // "YY" (2-digit year)
    });

    return formattedDate;  // Returns "MMM YY"
  }

  // If Month or Year is missing, return an "Invalid Date" fallback
  return 'Invalid Date';
}

export const generateChartOptions = (chartType: string, data: any, xAxisValues: any, yAxisNames: any, xAxisNames: any,
  nameGapCustom: any, chartConfig?: ChartConfigProps[], yAxisMax: number = 0, showSlider: boolean = false, grid: EchartsGridProps = layoutConfig, yAxisNameStyleConfig?: NameStyleConfig, xAxisNameStyleConfig?: NameStyleConfig) => {
  const yAxisConfig = yAxisNames && yAxisNames.length > 0 ? yAxisNames?.map((yAxis: YAxisNamesProps) => ({
    type: "value",
    name: yAxis.name,
    nameLocation: "middle",
    position: yAxis.position,
    nameGap: yAxis.nameGap ?? nameGapCustom ?? 50,
    min: yAxis.min ?? 0,
    max: yAxis.max,
    nameTextStyle: {
      ...nameTextStyleConfig,
      ...yAxisNameStyleConfig,
    },
    nameRotate: yAxis.position === "right" ? yAxis.nameRotate ?? -90 : yAxis.nameRotate ?? 90,
    alignTicks: true,
    axisLabel: yAxis.showPercentageLabel ? axisLabelConfig : leftAxisConfig,
  })) : {
    type: "value",
    nameGap: 0,
    min: 0,
    ...(yAxisMax && yAxisMax > 0 ? { max: yAxisMax } : {}),
    position: "left",
    axisLabel: {
      ...leftAxisConfig
    }
  }

  const xAxisConfig = xAxisNames ? xAxisNames?.map((xAxis: any) => ({
    type: "category",
    data: xAxisValues,
    name: xAxis.name,
    position: xAxis.bottom,
    nameGap: xAxis.nameGap,
    nameLocation: "middle",
    nameTextStyle: { ...nameTextStyleConfig, ...xAxisNameStyleConfig },
    axisTick: { show: false },
    axisLabel: leftAxisConfig,
  })) : {
    type: "category",
    data: xAxisValues,
    axisTick: { show: false },
    axisLabel: leftAxisConfig,
  };

  const options = {
    tooltip: tooltipConfig,
    grid: nameGapCustom ? customConfig : { ...layoutConfig, ...grid },
    xAxis: xAxisConfig,
    yAxis: yAxisConfig,
    dataZoom: [{ ...dataZoomConfig[0] }, { ...dataZoomConfig[1], show: showSlider }],
    series: getSeriesData(chartType, data, chartConfig),
  }

  return options;
}

export function formatDMTDate(item: DataItem): string | undefined {
  const date = item?.mtd_date ?? item?.last_7d_date ?? item?.date ?? item?.compliance_date ?? item?.month;

  if (date) {
    const dateObj = new Date(date);

    const day = String(dateObj.getDate()).padStart(2, "0"); // Adds leading zero if day is single digit
    const month = dateObj.toLocaleString('en-US', { month: 'short' }).toUpperCase(); // Gets the short month name (JAN, FEB, etc.)

    return `${day} ${month}`;
  }

  return undefined;
}

export function formatDM(date: string | Date): string {
  // If the date is invalid, return an empty string
  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) {
    return '';
  }

  // Get the day (padded to 2 digits if needed)
  const day = String(parsedDate.getDate()).padStart(2, '0');

  // Get the abbreviated month (e.g., 'JAN', 'FEB', 'MAR', etc.)
  const month = parsedDate.toLocaleString('en', { month: 'short' }).toUpperCase();

  return `${day} ${month}`; // Return the formatted date
}

export function formatMTDDate(item: DataItem): string | undefined {
  const date = item.mtd_date ?? item.last_7d_date ?? item.date ?? item.compliance_date ?? item.month;

  if (date) {
    const dateObj = new Date(date);

    const month = dateObj.toLocaleString('en-US', { month: 'short' }).toUpperCase(); // Gets the month in short form (JAN, FEB, etc.)
    const year = String(dateObj.getFullYear()).slice(-2); // Gets the last two digits of the year

    return `${month} ${year}`;
  }

  return undefined;
}

export function newMinMaxValue(items: DataItem[]) {
  if (!items || items.length === 0) {
    return undefined;
  }

  const numericValues: number[] = items?.flatMap(item =>
    Object.entries(item)
      .filter(
        ([key, value]) =>
          !excludedKeys.includes(key) &&
          typeof value === 'number' &&
          value !== null &&
          value !== undefined
      )
      .reduce((acc, [_, value]) => acc + (value as number), 0)
  )

  const minValue = Math.min(...numericValues);
  let maxValue = Math.max(...numericValues);
  if (numericValues.length === 0) {
    return { Min: 0, Max: 100 };
  } else if (maxValue === 0) {
    return { Min: minValue, Max: 100 }
  }

  maxValue = roundMaxValue(maxValue)

  return { Min: minValue, Max: maxValue };
}

export function roundMaxValue(maxValue: number): number {
  if (maxValue < 100) {
    return Math.ceil(maxValue / 5) * 5;
  } else if (maxValue < 1000) {
    return Math.ceil(maxValue / 100) * 100;
  } else if (maxValue < 10000) {
    return Math.ceil(maxValue / 1000) * 1000;
  } else if (maxValue < 100000) {
    return Math.ceil(maxValue / 10000) * 10000;
  } else if (maxValue < 1000000) {
    return Math.ceil(maxValue / 100000) * 100000;
  } else {
    return Math.ceil(maxValue / 1000000) * 1000000;
  }
}

export function negativeMinMax(items: DataItem[]) {
  if (!items || items.length === 0) {
    return undefined;
  }

  const numericValues: number[] = items.flatMap(item =>
    Object.entries(item)
      .filter(
        ([key, value]) =>
          !excludedKeys.includes(key) &&
          typeof value === 'number' &&
          value !== null &&
          value !== undefined
      )
      .map(([, value]) => value as number)
  );

  const minValue = Math.min(...numericValues);
  let maxValue = Math.max(...numericValues);
  if (numericValues.length === 0) {
    return { Min: 0, Max: 100 };
  } else if (maxValue === 0) {
    return { Min: minValue, Max: 100 };
  }

  maxValue = Math.ceil(maxValue / 5) * 5;

  return { Min: minValue, Max: maxValue };
}

export const getLegendItems = (data: DataItem[], chartType: string): LegendProps[] => {
  if (!data) return [];
  if (chartType === 'rft') return [];
  if (chartType === 'avgBatchDltGraph') return [];
  if (chartType === "see-cards") {
    return [
      { statusValue: "Serious", circleColor: COLORS.serious },
      {
        statusValue: "Potentially Serious",
        circleColor: COLORS.potentiallySerious,
      },
      { statusValue: "Major", circleColor: COLORS.major },
      { statusValue: "Minor", circleColor: COLORS.minor },
      { statusValue: "Non-EHS Issue", circleColor: COLORS.nonEHSIssue },
    ];
  } else if (chartType === "minor-deviation") {
    return [
      { statusValue: "NON-EXTENSIONS MINOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "EXTENSIONS MINOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "TREND OF DAILY COMING DUE MINOR DEVIATIONS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "major-deviation") {
    return [
      { statusValue: "NON-EXTENSIONS MAJOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "EXTENSIONS MAJOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "TREND OF DAILY COMING DUE MAJOR DEVIATIONS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "minor-deviation-adherence-mtd" || chartType === "minor-deviation-adherence-6m") {
    return [
      { statusValue: "ON-TIME MINOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "NOT ON-TIME MINOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "% OF MINOR DEVIATION ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "major-deviation-adherence-mtd" || chartType === "major-deviation-adherence-6m") {
    return [
      { statusValue: "ON-TIME MAJOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "NOT ON-TIME MAJOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "% OF MAJOR DEVIATION ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "minor-deviation-aging-mtd" || chartType === "minor-deviation-aging-6m") {
    return [
      { statusValue: "NON-EXTENSIONS MINOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "EXTENSIONS MINOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "% OF EXTENSIONS MINOR DEVIATIONS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "major-deviation-aging-mtd" || chartType === "major-deviation-aging-6m") {
    return [
      { statusValue: "NON-EXTENSIONS MAJOR DEVIATIONS", circleColor: "#0063C3" },
      { statusValue: "EXTENSIONS MAJOR DEVIATIONS", circleColor: "#FDB81E" },
      { statusValue: "% OF EXTENSIONS MAJOR DEVIATIONS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "change-control-status") {
    return [
      { statusValue: "NON-EXTENSIONS CHANGE CONTROLS", circleColor: "#0063C3" },
      { statusValue: "EXTENSIONS CHANGE CONTROLS", circleColor: "#FDB81E" },
      { statusValue: "TREND OF DAILY COMING DUE CHANGE CONTROLS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "change-control-adherence-mtd" || chartType === "change-control-adherence-6m") {
    return [
      { statusValue: "ON-TIME CHANGE CONTROLS", circleColor: "#0063C3" },
      { statusValue: "NON ON-TIME CHANGE CONTROLS", circleColor: "#FDB81E" },
      { statusValue: "% OF CHANGE CONTROL ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "change-control-aging-mtd" || chartType === "change-control-aging-6m") {
    return [
      { statusValue: "NON CHANGE CONTROLS", circleColor: "#0063C3" },
      { statusValue: "EXTENSIONS CHANGE CONTROLS", circleColor: "#FDB81E" },
      { statusValue: "% OF EXTENSIONS CONTROLS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "capa-status") {
    return [
      { statusValue: "NON-EXTENSIONS CAPAS", circleColor: "#0063C3" },
      { statusValue: "EXTENSIONS CAPAS", circleColor: "#FDB81E" },
      { statusValue: "TREND OF DAILY COMING CAPAS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "capa-status-mtd" || chartType === "capa-status-adherence-6m") {
    return [
      { statusValue: "ON-TIME CAPAS", circleColor: "#0063C3" },
      { statusValue: "NOT ON TIME CAPAS", circleColor: "#FDB81E" },
      { statusValue: "% OF CAPA ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "capa-status-aging-mtd" || chartType === "capa-status-aging-6m") {
    return [
      { statusValue: "NON EXTENSIONS CAPAS", circleColor: "#0063C3" },
      { statusValue: "EXTENSIONS CAPAS", circleColor: "#FDB81E" },
      { statusValue: "% OF EXTENSIONS CAPAS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "ratio-pm" || chartType === "ratio-pm-vs" || chartType === "percent-closure-pm") {
    return [
      { statusValue: "WORK ORDERS OF PM", circleColor: "#0063C3" },
      { statusValue: "% CLOSURE ADHERENCE OF PM", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "aging-work-orders") {
    return [
      { statusValue: "OPEN", circleColor: "#0063C3" },
      { statusValue: "CLOSED", circleColor: "#FDB81E" },
    ];
  } else if (chartType === "wo-closure-adherence") {
    return [
      { statusValue: "% CLOSED WORK ORDERS", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "wo-calibration-dis") {
    return [
      { statusValue: "PMCAL", circleColor: "#0063C3" },
      { statusValue: "CMCAL", circleColor: "#FDB81E" },
      { statusValue: "GMCAL", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "per-calibration-closure") {
    return [
      { statusValue: "WORK ORDERS", circleColor: "#0063C3" },
      { statusValue: "% CALIBRATION CLOSURE ADHERENCE", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "on-time-schedule-bv") {
    return [];
  } else if (chartType === "trend-graph-adherence") {
    return [
      { statusValue: "AHEAD", circleColor: "#2CC84D" },
      { statusValue: "DELAYED", circleColor: "#E01029" },
      { statusValue: "ON TIME", circleColor: "#0063C3" },
    ];
  } else if (chartType === "trend-graph-units" || chartType === "on-time-ddv-actual-vs-planned-weekly" || chartType === "on-time-ddv-actual-vs-planned-weekly-current" || chartType === "on-time-ddv-actual-vs-planned-six-months") {
    return [
      { statusValue: "ACTUAL", circleColor: "#2FBCB6" },
      { statusValue: "PLANNED", circleColor: "#0063C3" },
    ];
  } else if (chartType === "on-time-ddv-actual-and-planned-by-product") {
    const uniqueColors = Array.from(new Set(Object.values(COLORS)));
    const uniqueMaterialNumbers = Array.from(new Set(data?.flatMap((d) =>
      Object.keys(d).filter((key) => key !== 'date' &&
        (key.endsWith('_actual_units') || key.endsWith('_planned_units')))
        .map((key) => key.replace(/_(actual_units|planned_units)$/, ''))
    )));

    const legendData = uniqueMaterialNumbers?.map((materialNumber, index) => {
      return {
        statusValue: materialNumber?.toString() ?? "N/A",
        circleColor: uniqueColors[index % uniqueColors.length],
      };
    });

    return legendData;
  } else if (chartType === "brrReviewTarget") {
    return [
      { statusValue: "On Time Reviewed BRR", circleColor: "#0063C3" },
      { statusValue: "Not On Time Reviewed BRR", circleColor: "#FDB81E" },
      { statusValue: "% of BRR Reviewed Within Target", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "avgBatchDltGraph") {
    return [
      { statusValue: "TOTAL DISPOSITIONED BATCHES", circleColor: "#0063C3" },
      { statusValue: "AVG. DLT PER BATCHES (LAST 7 DAYS)", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "avgTatWrtTesting") {
    return [
      { statusValue: "Chromatography_HPLC", circleColor: "#0063C3" },
      { statusValue: "Elisa_Immuno", circleColor: "#FDB81E" },
      { statusValue: "Chromatography_UPLC", circleColor: "#2FBCB6" },
      { statusValue: "RAMAN", circleColor: "#E377C2" },
    ];
  } else if (chartType === "brrCompletedWithinTarget") {
    return [
      { statusValue: "On Time BRR", circleColor: "#0063C3" },
      { statusValue: "Not On Time BRR", circleColor: "#FDB81E" },
      { statusValue: "% of BRR Completed Within Target", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "batchefordispositionWithTarget") {
    return [
      { statusValue: "On Time Batche Dispositioned", circleColor: "#0063C3" },
      { statusValue: "Not On Time Batche Dispositioned", circleColor: "#FDB81E" },
      { statusValue: "% of Batch Dispositioned Within Target (MTD)", circleColor: "#2FBCB6" },
    ];
  } else if (chartType === "oeeValuesOvertimePerLine") {
    return [
      { statusValue: "OEE 1", circleColor: "#2FBCB6" },
      { statusValue: "OEE 2", circleColor: "#E01029" },
      { statusValue: "OEE 3", circleColor: "#0063C3" },
    ];
  } else {
    return [
      { statusValue: "At-Risk Behavior", circleColor: COLORS.atRiskBehavior },

      {
        statusValue: "At-Risk Condition",
        circleColor: COLORS.atRiskCondition,
      },

      { statusValue: "Safe Behavior", circleColor: COLORS.safeBehavior },
    ];
  }
};