import { InventoryDdvT1TableColumns } from "utils/wctConstants";
import RenderTierWiseComponent from "../delivery-tierwise/RenderTierWiseComponent";
import "./Inventory.scss";

interface InventoryTier1Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const InventoryTier1: React.FC<InventoryTier1Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  return (
    <div className="inventory-tier-main">
      {data?.map((item: any, index: number | null | undefined) => {
        return (
          <RenderTierWiseComponent
            activeKeyDeepDiveTab={activeKeyDeepDiveTab}
            tierLabel={tierLabel}
            key={index}
            data={item}
            deepDiveTableColumns={InventoryDdvT1TableColumns}
            deepDiveFilters={[item?.filterType]}
          />
        )
      })}
    </div>
  );
};
export default InventoryTier1;
