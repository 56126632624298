import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, DELIVERY, VERSION, WORK_CENTER_TEAM, PM_ADHERENCE } from 'utils/constants';

interface PmAdherenceInfoInfoState {
  pmAdherenceInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: PmAdherenceInfoInfoState = {
  pmAdherenceInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, DELIVERY, BASE_VIEW, PM_ADHERENCE];
const fetchPmAdherence = fetchApi(segments);

const pmAdherenceReducer = createSlice({
    name: 'pmAdherence',
    initialState,
    reducers: {
        clearPmAdherenceData(state) {
            state.pmAdherenceInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPmAdherence.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchPmAdherence.fulfilled, (state, action) => {
                state.loading = false;
                state.pmAdherenceInfo = action.payload
            })
            .addCase(fetchPmAdherence.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchPmAdherence };

export const { clearPmAdherenceData } = pmAdherenceReducer.actions

export default pmAdherenceReducer.reducer;