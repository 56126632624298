import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';

interface SeeCardsDataItem {
    date: number | string;
    record_id: number | string,
    manager_to_respond: number | string,
    short_description: number | string,
    ehss_program_area: number | string,
    see_bop_type_of_observation: number | string,
    safety_pyramid_level: number | string,
}

interface SeeCardsSummaryItem {
    total_see_cards_today: number,
    total_overdue: number,
    total_coming_due_in_2days: number,
}

interface SeeCardsSeeChartItem {
    date: string,
    total_see_bop_type_of_observation: number,
    see_bop_type_of_observation: string,
}

interface SeeCardsEhssChartItem {
    total_ehss_program_area: number,
    total_ehss_program: string,
}

interface ApiData {
    date: string;
    total_see: number;
    see_bop_type_of_observation: string;
    total_see_bop_type_of_observation: number;
}

interface GroupedData {
    date: string;
    atRiskBehavior: number;
    atRiskCondition: number;
    safeBehavior: number;
}

interface SeeCardsDataType {
    data: SeeCardsDataItem[];
    summary: SeeCardsSummaryItem[];
    see_chart: SeeCardsSeeChartItem[];
    ehss_chart: SeeCardsEhssChartItem[];
    open_see_data: SeeCardsDataItem[];
    see_trend_chart: GroupedData[];
}

interface TransformedData {
    seeCards: SeeCardsDataType;
}

interface SeeCardsInfoState {
    seeCardsDeepDiveInfo: TransformedData | null;
    loading: boolean;
    error: boolean;
}

const initialState: SeeCardsInfoState = {
    seeCardsDeepDiveInfo: null,
    loading: false,
    error: false,
};

interface FetchDataParams {
    workstream?: string | undefined,
    site?: string | undefined,
    persona?: string | undefined,
    tierNumber?: string | undefined,
    shift?: string | undefined,
    costCenter?: string | undefined,
    line?: string | undefined,
    building?: string | undefined,
}

const fetchSeeCardsDeepDive = createAsyncThunk<any, any>(
    "/work-center-team/metrics/safety/deep-dive-view/see",
    async (params) => {
        const { workStream, site, persona, tierNumber, shift, costCenter, line, building } = params;
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/work-center-team/metrics/safety/deep-dive-view/see?workStream=${workStream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&building=${building}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch seeCards');
        }
        return await response.json();
    }
);

const transformTrendChartData = (apiData: ApiData[]): GroupedData[] => {
    const groupedData: { [key: string]: GroupedData } = {};

    apiData?.forEach(({ date: dateOfSee, total_see: totalSee, see_bop_type_of_observation: seeBobTypeOfObservation }) => {
        const date = new Date(dateOfSee).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
        });

        if (!groupedData[date]) {
            groupedData[date] = { date, atRiskBehavior: 0, atRiskCondition: 0, safeBehavior: 0 };
        }

        switch (seeBobTypeOfObservation) {
            case "Safe Behavior":
                groupedData[date].safeBehavior += Number(totalSee);
                break;
            case "At-Risk Behavior":
                groupedData[date].atRiskBehavior += Number(totalSee);
                break;
            case "At-Risk Condition":
                groupedData[date].atRiskCondition += Number(totalSee);
                break;
            default:
                break;
        }
    });

    return Object.values(groupedData);
};

const transformData = (data: any): TransformedData => ({
    seeCards: {
        data: data?.new_see_card?.map((item: SeeCardsDataItem) => ({
            date: item.date ?? "",
            record_id: item.record_id ?? "",
            manager_to_respond: item.manager_to_respond ?? "",
            short_description: item.short_description ?? "",
            ehss_program_area: item.ehss_program_area ?? "",
            see_bop_type_of_observation: item.see_bop_type_of_observation ?? "",
            safety_pyramid_level: item.safety_pyramid_level ?? ""
        })),
        summary: data?.summary?.[0],
        see_chart: data.bop_chart?.map((item: SeeCardsSeeChartItem) => ({
            date: item.date ?? "",
            value: item.total_see_bop_type_of_observation ?? "",
            name: item.see_bop_type_of_observation ?? ""
        })),
        ehss_chart: data.ehss_chart?.map((item: SeeCardsEhssChartItem) => ({
            value: item.total_ehss_program_area ?? "",
            name: item.total_ehss_program ?? "",
        })),
        open_see_data: data?.open_see_card?.map((item: SeeCardsDataItem) => ({
            date: item.date ?? "",
            record_id: item.record_id ?? "",
            manager_to_respond: item.manager_to_respond ?? "",
            short_description: item.short_description ?? "",
            ehss_program_area: item.ehss_program_area ?? "",
            see_bop_type_of_observation: item.see_bop_type_of_observation ?? "",
            safety_pyramid_level: item.safety_pyramid_level ?? ""
        })),
        see_trend_chart: transformTrendChartData(data?.see_chart)
    }
});

const seeCardsDeepDiveReducer = createSlice({
    name: 'seeCardsDeepDive',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeeCardsDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchSeeCardsDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.seeCardsDeepDiveInfo = transformData(action.payload);
            })
            .addCase(fetchSeeCardsDeepDive.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchSeeCardsDeepDive };

export default seeCardsDeepDiveReducer.reducer;