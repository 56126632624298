import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, MAJOR_DEVIATION, METRICS, QUALITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface SeeCardsInfoState {
  majorDeviationInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: SeeCardsInfoState = {
  majorDeviationInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, QUALITY, BASE_VIEW, MAJOR_DEVIATION];
const fetchMajorDeviation = fetchApi(segments);

const majorDeviationReducer = createSlice({
    name: 'majorDeviation',
    initialState,
    reducers: {
        clearMajorDeviationData(state) {
            state.majorDeviationInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMajorDeviation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchMajorDeviation.fulfilled, (state, action) => {
                state.loading = false;
                state.majorDeviationInfo = action.payload;
            })
            .addCase(fetchMajorDeviation.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchMajorDeviation };

export const { clearMajorDeviationData } = majorDeviationReducer.actions

export default majorDeviationReducer.reducer;