import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

interface Data {
  name?: string;
  value?: number;
}

interface HorizontalBarChartProps {
  title1: string;
  title2: string;
  subtitle1: string;
  subtitle2: string;
  chartType?: string
  leftBarGraphData: Data[];
  rightBarGraphData: Data[];
  threshold?: number
  EHSSProgramData: Data[];
  BehaviorObservationData: Data[];
  xAxisName?: string;
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  title1,
  title2,
  subtitle1,
  subtitle2,
  rightBarGraphData,
  leftBarGraphData,
  threshold,
  BehaviorObservationData,
  EHSSProgramData,
  chartType,
  xAxisName = ""
}) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartOptions2, setChartOptions2] = useState({});
  const getMaxValue = (data: Data[]) => {
    if (!Array.isArray(data) || data.length === 0) {
      return 0;
    }
    return Math.max(...data.map((item) => item.value ?? 0));
  };
  let barWidth
  if (rightBarGraphData && rightBarGraphData.length > 0) barWidth = 450
  else barWidth = 1000
  const generateTicks = (maxValue: number) => {
    const step = Math.ceil(maxValue / 10);
    return Array.from(
      { length: Math.ceil(maxValue / step) + 1 },
      (_, index) => index * step
    );
  };

  const EHSSProgramTicks = generateTicks(getMaxValue(EHSSProgramData));
  const BehaviorObservationTicks = generateTicks(
    getMaxValue(BehaviorObservationData)
  )

  const getOption = (data: Data[], ticks: number[], showLegend: boolean) => {
    return {
      grid: {
        left: "5%",
        right: "10%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        name: xAxisName,
        nameLocation: "middle", // Position the label in the middle
        nameGap: 30, // Adjust the gap between the axis and the label
        nameTextStyle: {
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: 10,
          lineHeight: 14,
          align: "center"
        },
        axisLabel: {
          fontSize: 10,
          fontFamily: "Inter",
          color: "#595959",
          fontWeight: 700
        },
        splitLine: { show: false },
        data: ticks
      },
      legend: {
        show: showLegend,
        data: ["Target Line", "Number of Pending Exceptions"]
      },
      yAxis: {
        type: "category",
        data: data?.map((item) => item.name),
        axisLabel: {
          fontSize: 10,
          fontFamily: "Inter",
          color: "#595959",
          fontWeight: 700
        },
        splitLine: { show: false },
        axisTick: { show: false }
      },
      series: [
        {
          type: "bar",
          data: data?.map((item: any) => ({
            value: item.value,
            itemStyle: {
              color: threshold && item.value >= threshold? "#E42E44": "#0063C3"
            }
          })
          )
        }
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      dataZoom: [
        {
          type: "slider",
          yAxisIndex: 0,
          show: data?.length > 5,
          showDetail: false,
          backgroundColor: "#f0f0f0",
          fillerColor: "#217ecf",
          // borderRadius: 12,
          borderColor: "transparent", // Border color of the slider track area
          borderRadius: 12,
          width: 15,
          handleStyle: {
            color: "#175891",
            borderRadius: 5,
            borderColor: "transparent",
            shadowColor: "transparent",
            // opacity: 0,
          },
          dataBackground: {
            lineStyle: {
              opacity: 0
            },
            areaStyle: {
              opacity: 0
            }
          },
          start: 0,
          end: data?.length > 5 ? Math.floor((5 / data?.length) * 100) : 100,
          brushSelect: false,
          brushStyle: {
            // opacity: 0,
          },
        },
      ],
    };
  };

  useEffect(() => {
    const option1 = getOption(leftBarGraphData, EHSSProgramTicks, chartType === 'rft');
    const option2 = getOption(
      rightBarGraphData,
      BehaviorObservationTicks, chartType === 'rft'
    );
    setChartOptions(option1)
    setChartOptions2(option2)
  }, [EHSSProgramData, BehaviorObservationData]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>

      {(chartType === 'inca' || chartType === 'ehss' || chartType === 'rft') && <div style={{ width: "60%" }}>
        <h3 className="tier0-title">{title1}</h3>
        <p className="tier0-subtitle">{subtitle1}</p>
        {leftBarGraphData?.length > 0 ? (
          <ReactECharts option={chartOptions} style={{ height: 400, width: barWidth }} />
        ) : (
          <p>No data available</p>
        )}
      </div>}

      {(chartType === 'inca' || chartType === 'Aging' || chartType === 'ehss') && <div style={{ width: "60%" }}>
        <h3 className="tier0-title">{title2}</h3>
        <p className="tier0-subtitle">{subtitle2}</p>
        {rightBarGraphData?.length > 0 && (
          <ReactECharts option={chartOptions2} style={{ height: 400, width: barWidth  }} />
        )}
      </div>}

      {(chartType === 'Aging') && <div style={{ width: "60%" }}>
        <h3 className="tier0-title">{title2}</h3>
        <p className="tier0-subtitle">{subtitle2}</p>
        {BehaviorObservationData?.length > 0 ? (
          <ReactECharts option={chartOptions2} style={{ height: 240 }} />
        ) : (
          <p>No data available</p>
        )}
      </div>}
    </div>
  );
};

export default HorizontalBarChart;
