import React from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts";
import { formatIntlNumber } from "../chartUtils";

interface Data {
  name?: string;
  value?: number;
  [key: string]: any;
}

interface StyledHorizontalBarChartProps {
  data: Data[];
  config?: {
    conditionalColor?: (item: Data) => any;
    markLine?: any;
    height?: string;
    width?: string;
    xAxisLabel?: string;
    yAxisLabel?: string;
    xAxisKey?: string;
    yAxisKey?: string;
    showYAxis?: boolean;
    hideXAxisTicks?: boolean;
    hideYAxisTicks?: boolean;
    showYAxisLine?: boolean;
    gridTop?: number | string;
    gridBottom?: number | string;
    gridLeft?: number | string;
    gridRight?: number | string;
    gridContainLabel?: boolean;
    xAxisNameGap?: number;
    yAxisNameGap?: number;
    isabbreviateAxis?: boolean;
    xAxisMax?: number;
    xAxisMin?: number; // Added xAxisMin for negative values
    positiveColor?: string; // Positive bar color
    negativeColor?: string; // Negative bar color
    barWidth?: number;
    showTooltip?: boolean; // Add showTooltip config
    showGridLines?: boolean; // Add showGridLines config
    xAxisSuffix?: string; // Add xAxisSuffix config
    showAllLabels?: boolean; // Add showAllLabels config
  };
  tooltipContent?: string;
}

const StyledHorizontalBarChart: React.FC<StyledHorizontalBarChartProps> = ({
  data,
  config = {},
  tooltipContent = '', // Add tooltipContent prop
}) => {
  const {
    conditionalColor,
    markLine,
    height = '100%',
    width = '100%',
    xAxisLabel,
    yAxisLabel,
    xAxisKey = 'value',
    yAxisKey = 'name',
    showYAxis = true,
    hideXAxisTicks = false,
    hideYAxisTicks = false,
    showYAxisLine = true,
    gridTop = 24,
    gridBottom = 24,
    gridLeft = 24,
    gridRight = 24,
    gridContainLabel = true,
    xAxisNameGap = 50,
    yAxisNameGap = 50,
    isabbreviateAxis = false,
    xAxisMax, // Default max value
    xAxisMin, // Default min value
    showAllLabels = false,
    positiveColor = "#0063C3", // Default positive color
    negativeColor = "red", // Default negative color
    barWidth = 16,
    showTooltip = true, // Enable tooltip
    showGridLines = true, // Default grid lines visibility to true
    xAxisSuffix = '',
  } = config;

  const commonTextStyle = {
    fontSize: 10,
    fontFamily: "Inter",
    color: "#595959",
    fontWeight: 700,
  };

  const chartOption: EChartsOption = React.useMemo(() => {
    return {
      grid: {
        top: gridTop,
        bottom: gridBottom,
        left: gridLeft,
        right: typeof gridRight === 'number' ? gridRight + 10 : gridRight,
        containLabel: gridContainLabel,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        z: 9999,
        show: showTooltip,
        backgroundColor: '#fff',
        borderColor: '#ccc',
        borderWidth: 1,
        textStyle: {
          color: '#000',
        },
        extraCssText: `
          padding: 12px;
          border-radius: 8px;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
          white-space: normal;
          z-index: 9999;
        `,
        renderMode: 'html',
        position: 'bottom',
        formatter: (params: any) => {
          const header = `<div style="font-weight: 700; font-size: 12px; font-family: 'Inter'; line-height: 18px; color: #1F1F1F;"><strong>Horizontal Bar Chart</strong></div>`;
          const description = tooltipContent ? `<div style="font-weight: 400; font-size: 12px; font-family: 'Inter'; line-height: 18px; color: #1F1F1F;">${tooltipContent}</div>` : '';
          const dataPoints = params.map((param: any) => {
            return `<div style="font-weight: 400; font-size: 12px; font-family: 'Inter'; line-height: 18px; color: #1F1F1F;"><strong>${param.name}</strong>: ${param.value}</div>`;
          }).join('');
          return `${header}${description}${dataPoints}`;
        },
      },
      xAxis: {
        type: 'value',
        name: xAxisLabel,
        nameLocation: 'middle',
        nameGap: xAxisNameGap,
        axisTick: {
          show: hideXAxisTicks,
        },
        interval: !showAllLabels ? xAxisMax: 0,
        axisLabel: {
          ...commonTextStyle,
          hideOverlap: true,
          formatter: (value: number) => {
            if (value === xAxisMin || value === 0 || value === xAxisMax) {
              return `${value}${xAxisSuffix}`;
            }
            return '';
          },
        },
        nameTextStyle: commonTextStyle,
        max: xAxisMax,
        min: xAxisMin, // Include negative values
        axisLine: {
          show: false, // Hide the x-axis line
        },
        splitLine: {
          show: showGridLines, // Control grid lines visibility
        }
      },
      yAxis: {
        type: 'category',
        name: yAxisLabel,
        nameLocation: 'middle',
        nameGap: yAxisNameGap,
        data: data.map((item) => item[yAxisKey]),
        show: showYAxis,
        axisTick: {
          show: hideYAxisTicks
        },
        axisLine: {
          show: showYAxisLine
        },
        axisLabel: commonTextStyle
      },
      series: [
        {
          type: 'bar',
          markLine,
          data: data.map((item) => ({
            value: item[xAxisKey],
            itemStyle: {
              color:
                conditionalColor?.(item) ??
                (item[xAxisKey] > 0 ? positiveColor : negativeColor),
            },
          })),
          barWidth,
          barGap: 20,
        },
      ],
    };
  }, [
    data,
    xAxisLabel,
    yAxisLabel,
    xAxisKey,
    yAxisKey,
    showYAxis,
    hideXAxisTicks,
    hideYAxisTicks,
    showYAxisLine,
    gridTop,
    gridBottom,
    gridLeft,
    gridRight,
    gridContainLabel,
    xAxisNameGap,
    yAxisNameGap,
    conditionalColor,
    markLine,
    xAxisMax,
    xAxisMin,
    positiveColor,
    negativeColor,
    isabbreviateAxis,
    showTooltip,
    showGridLines,
    tooltipContent // Add tooltipContent dependency
  ]);

  return (
    <div style={{ height, width }}>
      {data.length > 0 ? (
        <ReactECharts option={chartOption} style={{ height, width }} />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default StyledHorizontalBarChart;