/** @jsxImportSource @emotion/react */
import React from 'react';
import { Typography, Row, Col } from 'antd';
import styled from '@emotion/styled';

const { Text } = Typography;

interface CardContentRowProps {
  keyLabel: string | number;
  value: string | number | undefined;
  isError?: boolean;
  isSuccess?: boolean;
  opacity?: string | number;
  highlightStyles?: React.CSSProperties  // Update highlightStyles prop type
}

const KeyLabel = styled(Text)`
  color: #000; /* default color */
  font-family: 'Inter', sans-serif; /* default font-family */
  font-weight: 700; /* default font-weight */
  font-size: 10px; /* default font-size */
  line-height: 14px; /* default line-height */
  opacity: 0.65; /* default opacity */
  margin: 0; /* remove margin */
  padding: 0; /* remove padding */
  white-space: normal; /* allow text to wrap */
  display: inline-block; /* add this line */
  text-transform: uppercase; /* ensure text is uppercase */
`;

const Value = styled(Text) <{ isError: boolean; isSuccess: boolean; value: any; opacity?: string | number; highlightStyles?: React.CSSProperties; }>` // Add highlightStyles prop
  color: '#000'; /* default color */
  font-family: 'Inter', sans-serif; /* default font-family */
  font-size: 12px; /* default font-size */
  line-height: 14px; /* default line-height */
  display: inline-block; /* add this line */
  font-weight: 400; /* default font-weight */
  text-transform: uppercase; /* ensure text is uppercase */
  opacity: ${props => props.opacity}; /* 65% opacity for text values */
  ${props => props.highlightStyles && { ...props.highlightStyles }}; /* Apply highlight styles if available */
`;

const CardContentRow: React.FC<CardContentRowProps> = ({ keyLabel, value, isError = false, isSuccess = false, opacity = 1, highlightStyles }) => {
  return (
    <Row justify="space-between" align="middle" gutter={0} style={{ margin: 0, padding: 0, marginBottom: '4px' }}>
      <Col span={12} style={{ display: 'flex', alignItems: 'center', margin: 0, padding: 0 }}>
        <KeyLabel>{keyLabel?.toString().replace(/_/g, ' ')}</KeyLabel> {/* Remove underscores from keyLabel */}
      </Col>
      <Col span={12} style={{ textAlign: 'end', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: 0, padding: 0 }}>
        <Value isError={isError} isSuccess={isSuccess} value={value} opacity={opacity} highlightStyles={highlightStyles}>
          {value}
        </Value>
      </Col>
    </Row>
  );
};

export default CardContentRow;
