import React from "react";
import "./index.scss";
import "../../../../../../../assets/bootstrap.scss";
import HorizontalBar from "./horizontal-bar";

interface MachineAlarmTableProps {
  alarmData: any;
  idMachineList: React.Key | null | undefined;
  idAlarmGraph: React.Key | null | undefined;
}

const MachineAlarmsBarTable: React.FC<MachineAlarmTableProps> = (
  props: MachineAlarmTableProps
) => {
  const { alarmData, idMachineList, idAlarmGraph } = props;

  return (
    <>
      <div className="aoh-row aoh-machine-alarms-container aoh-mb-2">
        <div title={alarmData.alarm} className="aoh-col-2 aoh-pb-2 aoh-pr-0 ops-fw-semibold aoh-text-uppercase ops-fs-6 aoh-machine-table-alarm aoh-pr-1">
          {alarmData.alarm}
        </div>

        <div className="aoh-col-7 aoh-pl-0 ops-fs-6 aoh-machine-alarm-bar">
          <HorizontalBar
            percentageArray={[
              {
                idMachineList,
                idAlarmGraph,
                alarm: alarmData.alarm,
                count: alarmData.count,
                details: alarmData.details,
                duration: alarmData.duration,
                percentage: alarmData.percentage,
                flag: "BLUE",
              },
            ]}
          />
        </div>
        <div className="aoh-col-3 aoh-pb-2 ops-fs-5 aoh-machine-alarm-time aoh-pr-3 ops-fw-medium">
          {alarmData.duration}
        </div>
      </div>
    </>
  );
};
export default MachineAlarmsBarTable;
