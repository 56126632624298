import React, { useState } from "react";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Quality.scss";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { RadioFilters } from "components/resuable/radio-filters/RadioFiltersQuality";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import AohTable from "components/common/table/AohTable";
import HorizontalBarChart from "components/common/HorizontalBar/HorizontalBarChart";
import { downloadCSV, generateCSV } from "utils/common-methods";
import { CapaEVColumns, CapaStatusColumns, ChangeControlColumns, majorDeviationColumns, minorDeviationColumns, rftColumnsTier2 } from "utils/wctConstants";
import { BlockRadioFilters } from "components/resuable/radio-filters/BlockRadioFilters";
import { StringKeyDataProps } from "utils/data-types";
import { formatDayMonth, formatMonthDay, formatMTDDate, newMinMaxValue } from "components/common/StackedBar/utils";

interface QualityTier2Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const QualityTier2: React.FC<QualityTier2Props> = ({
    data: { lineGraphData, tableDataPending, tableDataAcknowledge, barGraphData, summary, heatmapData, tableDataAll, tableDataComingDue7Days, tableDataOverdue, tableDataComingDue, tableDataAging, tableDataOpen, graphDataAging, graphData, CAPAtableDataAll, CAPAtableDataOverdue, CAPAtableDataComingDue, CAPAtableDataAging, CAPAtableDataOpen, CAPAEVtableDataAll, CAPAEVtableDataOverdue, CAPAEVtableDataComingDue, CAPAEVtableDataAging, CAPAEVtableDataOpen },
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const rftLineArr = ["All", "Pending", "Acknowledge"]
    const rftViewByArr = ["Average", "Lines"]
    const rftChartLegend = [
        { statusValue: "TARGET LINE", circleColor: "#E42E44" },
        { statusValue: "NUMBER OF PENDING EXCEPTION", circleColor: "#0063C3" },
    ]
    const [rftTableTimePeriod, setRftTableTimePeriod] = useState("All")
    const [rftGraphViewBy, setRftGraphViewBy] = useState("Average")
    const rftTableDataMap: Record<string, any> = {
        All: tableDataAll || [],
        Pending: tableDataPending || [],
        Acknowledge: tableDataAcknowledge || []
    }
    const rftLineGraphDataMap: Record<string, any> = {
        Average: lineGraphData?.avg || [],
        Lines: lineGraphData?.lines || []
    }
    const [minDevTableTimePeriod, setMinDevTableTimePeriod] = useState("All")
    const [minDevTable2TimePeriod, setMinDevTable2TimePeriod] = useState("All")

    const isAllOrOverdueOrComingDue = (
        minDevTableTimePeriod === "All" ||
        minDevTableTimePeriod === "Overdue" ||
        minDevTableTimePeriod === "Coming Due" ||
        minDevTableTimePeriod === "Open" ||
        minDevTableTimePeriod === "Extensions"
    )

    const isAllOrOverdueOrComingDue2 = (
        minDevTable2TimePeriod === "All" ||
        minDevTable2TimePeriod === "Overdue" ||
        minDevTable2TimePeriod === "Coming Due" ||
        minDevTable2TimePeriod === "Open" ||
        minDevTable2TimePeriod === "Extensions"
    )

    const CapaStatusTableData = isAllOrOverdueOrComingDue
        ? (minDevTableTimePeriod === "All" ? CAPAtableDataAll : minDevTableTimePeriod === "Overdue" ? CAPAtableDataOverdue 
            : minDevTableTimePeriod === 'Coming Due' ? CAPAtableDataComingDue : minDevTableTimePeriod === "Open" 
            ? CAPAtableDataOpen : CAPAtableDataAging) : []
    const CapaEVTableData = isAllOrOverdueOrComingDue2
        ? (minDevTable2TimePeriod === "All" ? CAPAEVtableDataAll : minDevTable2TimePeriod === "Overdue" ? CAPAEVtableDataOverdue 
            : minDevTableTimePeriod === 'Coming Due' ? CAPAEVtableDataComingDue : minDevTable2TimePeriod === "Open" 
            ? CAPAEVtableDataOpen : CAPAEVtableDataAging) : []

    const tableDataMapping: any = {
        All: tableDataAll,
        Overdue: tableDataOverdue,
        "Coming Due": tableDataComingDue ?? tableDataComingDue7Days,
        Open: tableDataOpen,
        Extensions: tableDataAging,
    };

    const getTableData = (isAllOrOverdueOrComingDue: boolean, minDevTableTimePeriod: string) => {
        if (isAllOrOverdueOrComingDue) {
            return tableDataMapping[minDevTableTimePeriod] ?? [];
        }

        return []
    };

    const majorDeviationTableData = getTableData(isAllOrOverdueOrComingDue, minDevTableTimePeriod);

    const minorDeviationTableData = getTableData(isAllOrOverdueOrComingDue, minDevTableTimePeriod);

    const changeControlTableData = getTableData(isAllOrOverdueOrComingDue, minDevTableTimePeriod);

    const maxValues = newMinMaxValue(graphData);

    const qualityDeepDive: any = {
        "Major Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            title: "Trend of Daily Coming Due Major Deviation with Volume",
            subtitle: "In last 7 days",
            stackBar: (
                <StackedBarChart
                    data={graphData}
                    chartType="major-deviation"
                    xAxisData={graphData?.map((item: any) => formatMonthDay(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN MAJOR DEVIATIONS", position: "left", max: maxValues?.Max },
                        { name: "TREND OF DAILY COMING\nDUE MAJOR DEVIATIONS", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            ),
            title5: "Major Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button5: (
                <Button
                    label="Download"
                    className={((!majorDeviationTableData) || (majorDeviationTableData.length === 0)) ? "" : "download-btn"}
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => {
                        if (majorDeviationTableData && majorDeviationColumns) {
                            const csvContent = generateCSV(majorDeviationTableData, majorDeviationColumns);
                            downloadCSV(csvContent, "Major_Deviation_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!majorDeviationTableData) || (majorDeviationTableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={majorDeviationTableData}
                tableColumns={majorDeviationColumns} />,
        },
        "Minor Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            title: "Trend of Daily Extensions Minor Deviations",
            subtitle: "In last 7 days",
            stackBar: (
                <StackedBarChart
                    data={graphData}
                    chartType="minor-deviation"
                    xAxisData={graphData?.map((item: any) => formatMonthDay(item))}
                    yAxisNames={[
                        { name: "TREND OF DAILY COMING\nDUE MINOR DEVIATIONS", position: "left", max: maxValues?.Max },
                        { name: "TOTAL OPEN MINOR DEVIATIONS", position: "right", max: maxValues?.Max, showPercentageLabel: true },
                    ]}
                />
            ),
            title2: "Minor Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button5: (
                <Button
                    label="Download"
                    className={((!majorDeviationTableData) || (minorDeviationTableData.length === 0)) ? "" : "download-btn"}
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => {
                        if (minorDeviationTableData && minorDeviationColumns) {
                            const csvContent = generateCSV(minorDeviationTableData, minorDeviationColumns);
                            downloadCSV(csvContent, "Minor_Deviation_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!majorDeviationTableData) || (minorDeviationTableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={minorDeviationTableData}
                tableColumns={minorDeviationColumns} />,
        },
        "GXP Training": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            heatmapTitle: "Compliance Rate (%)",
            heatmap: (
                <ColorCodedTable
                    data={heatmapData}
                    metricKey="gxp"
                />
            )
        },
        "CAPA Status": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            title: "Trend of Daily Coming Due CAPA's with Volume",
            subtitle: "In last 7 days",
            stackBar:
                <StackedBarChart data={graphData} chartType="capa-status"
                    xAxisData={graphData?.map((item: any) => item.compliance_date)}
                    yAxisNames={[
                        { name: "TOTAL OPEN CAPAS", position: "left", max: maxValues?.Max },
                        { name: "TREND OF DAILY COMING CAPAS", position: "right", max: maxValues?.Max, showPercentageLabel: true },
                    ]}
                />,
            title2: "CAPA",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button5: (
                <Button
                    label="Download"
                    className={((!CapaStatusTableData) || (CapaStatusTableData.length === 0)) ? "" : "download-btn"}
                    icon={
                        <DownloadOutlined style={ ((!CapaStatusTableData) || (CapaStatusTableData.length === 0)) ? { color: "", fontSize: "14px" } : { color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => {
                        if (CapaStatusTableData && CapaStatusColumns) {
                            const csvContent = generateCSV(CapaStatusTableData, CapaStatusColumns);
                            downloadCSV(csvContent, "CAPA_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!CapaStatusTableData) || (CapaStatusTableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={CapaStatusTableData}
                tableColumns={CapaStatusColumns} />,
            title3: "CAPA EV",
            radioFilters3: (
                <RadioFilters
                    timePeriod={minDevTable2TimePeriod}
                    setTimePeriod={setMinDevTable2TimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button3: (
                <Button
                    label="Download"
                    className={((!CapaEVTableData) || (CapaEVTableData.length === 0)) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (CapaEVTableData && CapaEVColumns) {
                            const csvContent = generateCSV(CapaEVTableData, CapaEVColumns);
                            downloadCSV(csvContent, "CapaEV_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!CapaEVTableData) || (CapaEVTableData.length === 0)) ? true : false}
                />
            ),
            table3: <AohTable
                tableData={CapaEVTableData}
                tableColumns={CapaEVColumns} />
        },
        "Change Control": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            title: "Trend of Daily Coming Due Change Controls with Volume",
            subtitle: "In last 7 days",
            stackBar:
                <StackedBarChart
                    data={graphData}
                    chartType="change-control-status"
                    xAxisData={graphData?.map((item: any) => formatDayMonth(item))}
                    yAxisNames={[
                        { name: "TOTAL OPEN CHANGE CONTROLS", position: "left", max: maxValues?.Max },
                        { name: "TREND OF DAILY\nCOMING DUE CHANGE CONTROLS", position: "right", max: maxValues?.Max, showPercentageLabel: true },
                    ]}
                />,
            title2: "Change Control",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button5: (
                <Button
                    label="Download"
                    className={((!changeControlTableData) || (changeControlTableData.length === 0)) ? "" : "download-btn"}
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => {
                        if (changeControlTableData && ChangeControlColumns) {
                            const csvContent = generateCSV(changeControlTableData, ChangeControlColumns);
                            downloadCSV(csvContent, "Change_Control_Status_Data.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!changeControlTableData) || (changeControlTableData.length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={changeControlTableData}
                tableColumns={ChangeControlColumns} />
        },
        "Right First Time": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            component: (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "1em" }}>
                    <div>
                        <h2 className="tier-title">Number Of Pending Exception In Each Line/Batch</h2>
                        <HorizontalBarChart
                            data={(barGraphData?.pendingExceptions || []) as StringKeyDataProps[]}
                            legendItems={rftChartLegend}
                            config={{
                                isConditionalColor: true,
                                conditionalColor: (item: any) => ({
                                    value: item.value || 0,
                                    itemStyle: {
                                        emphasis: {
                                            color: item.value > 10 ? "#E42E44" : "#0063C3",
                                            borderColor: item.value > 10 ? "#E42E44" : "#0063C3",
                                        },
                                        color: item.value > 10 ? "#E42E44" : "#0063C3",
                                    },
                                }),
                                markLine: {
                                    symbol: "none",
                                    lineStyle: {
                                        color: "#E42E44",
                                        type: "solid",
                                    },
                                    data: [
                                        {
                                            name: "TARGET LINE",
                                            xAxis: 10,
                                        },
                                    ],
                                },
                                width: 560,
                                height: 344,
                                xAxisKey: "value",
                                yAxisKey: "name",
                                hideXAxisTicks: true,
                                hideYAxisTicks: true,
                                showYAxisLine: false,
                                xAxisLabel: "NUMBER OF PENDING EXCEPTION",
                                gridBottom: 90,
                                gridLeft: 30,
                                yAxisLabel: "LINES",
                                yAxisMax: 15
                            }} />
                    </div>
                    <div>
                        <h2 className="tier-title">Deviation In Each Line/Batch</h2>
                        <HorizontalBarChart
                            data={(barGraphData?.batchDeviations || []) as StringKeyDataProps[]}
                            legendItems={rftChartLegend}
                            config={{
                                isConditionalColor: true,
                                conditionalColor: (item: any) => ({
                                    value: item.value || 0,
                                    itemStyle: {
                                        emphasis: {
                                            color: item.value > 10 ? "#E42E44" : "#0063C3",
                                            borderColor: item.value > 10 ? "#E42E44" : "#0063C3",
                                        },
                                        color: item.value > 10 ? "#E42E44" : "#0063C3",
                                    },
                                }),
                                markLine: {
                                    symbol: "none",
                                    lineStyle: {
                                        color: "#E42E44",
                                        type: "solid",
                                    },
                                    data: [
                                        {
                                            name: "TARGET LINE",
                                            xAxis: 10,
                                        },
                                    ],
                                },
                                width: 560,
                                height: 344,
                                xAxisKey: "value",
                                yAxisKey: "name",
                                hideXAxisTicks: true,
                                hideYAxisTicks: true,
                                showYAxisLine: false,
                                xAxisLabel: "NUMBER OF DEVIATION",
                                gridBottom: 90,
                                gridLeft: 30,
                                yAxisLabel: "LINES",
                                yAxisMax: 15
                            }} />
                    </div>
                </div>
            ),

            title4: "Exception Pending Acknowledgement",
            lineGraphRadioFilters: (
                <BlockRadioFilters
                    setRadioFilter={setRftGraphViewBy}
                    filterLabel="VIEW BY"
                    radioFilter={rftViewByArr?.map((item) => ({ label: item, value: item }))}
                />
            ),
            lineGraph: (
                <StackedBarChart
                    data={rftLineGraphDataMap?.[rftGraphViewBy] || []}
                    chartType="rft"
                    xAxisData={rftLineGraphDataMap?.[rftGraphViewBy]?.map((item: any) => formatDayMonth(item))}
                    yAxisNames={[
                        { name: "EXCEPTION PENDING ACKNOWLEDGEMENT", position: "left", max: undefined },
                    ]}
                    xAxisNames={activeKeyDeepDiveTab === "Right First Time" ? [{ name: rftGraphViewBy.toUpperCase(), position: 'bottom', nameGap: 35 }] : []}
                />
            ),
            radioFilters: (
                <RadioFilters
                    timePeriod={rftTableTimePeriod}
                    setTimePeriod={setRftTableTimePeriod}
                    tierLabel={tierLabel}
                    customLabel="TIME PERIOD"
                    timePeriodData={rftLineArr}
                />
            ),
            title5: "Exception",
            button5: (
                <Button
                    label="Download"
                    className={((!rftTableDataMap) || (rftTableDataMap?.[rftTableTimePeriod].length === 0)) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    iconPosition="right"
                    onClick={() => {
                        if (rftTableDataMap && rftColumnsTier2) {
                            const csvContent = generateCSV(rftTableDataMap ? rftTableDataMap?.[rftTableTimePeriod] : [], rftColumnsTier2);
                            downloadCSV(csvContent, "Exception.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={((!rftTableDataMap) || (rftTableDataMap?.[rftTableTimePeriod].length === 0)) ? true : false}
                />
            ),
            table: <AohTable
                tableData={rftTableDataMap?.[rftTableTimePeriod] || []}
                tableColumns={rftColumnsTier2} />
        }
    }

    return (
        <div className="quality-tier-main">
            {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {/* Summary card */}
                    {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                </h2>

            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.summary}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                }
            ></div>
            {/* Heatmap Card */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle && (    
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle}
                </h2>
            </div>
            )}
            {/* main heatmap component */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmap}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.heatmap ||
                        qualityDeepDive[activeKeyDeepDiveTab]?.table
                        ? "mr-bottom-20"
                        : ""
                }
            ></div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.title && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title}
                </h2>
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle && ( 
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.stackBar}
            {qualityDeepDive[activeKeyDeepDiveTab]?.component}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title4 && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title4}
                </h2>
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.lineGraphRadioFilters}
            {qualityDeepDive[activeKeyDeepDiveTab]?.lineGraph}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title2 && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title2}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button5}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title5 && ( 
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title5}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button5}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table}
            {qualityDeepDive[activeKeyDeepDiveTab]?.title3 && ( 
            <div className="tier-container mr-top-20" key={'ev'}>
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title3}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button3}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle3 && ( 
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle3}
            </div>
            )}
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters3}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table3}
        </div>
    )
}
export default QualityTier2
