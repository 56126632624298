// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AohTable_tableContainer__8a9yS{
    margin-top: 20px;
}

.ant-table-column-title {
    font-weight: 700 !important;
}

.ant-table-body::-webkit-scrollbar {
  height: 6px !important;
}

.ant-table-cell .ant-typography {
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  color: #1F1F1F !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/table/AohTable.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;EAC1B,4BAA4B;EAC5B,2BAA2B;EAC3B,yBAAyB;AAC3B","sourcesContent":[".tableContainer{\n    margin-top: 20px;\n}\n\n:global(.ant-table-column-title) {\n    font-weight: 700 !important;\n}\n\n:global(.ant-table-body::-webkit-scrollbar) {\n  height: 6px !important;\n}\n\n:global(.ant-table-cell .ant-typography) {\n  font-size: 12px !important;\n  line-height: 18px !important;\n  font-weight: 400 !important;\n  color: #1F1F1F !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `AohTable_tableContainer__8a9yS`
};
export default ___CSS_LOADER_EXPORT___;
