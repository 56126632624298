import APIErrorMessage from "utils/api-error";
import cardCss from "./SummaryCard.module.scss";
import { summaryCardProps } from "utils/wctConstants";
import { formatIntlNumber } from "utils/common-methods";

interface AohSummaryProps {
    data?: any;
    tierLabel?: string;
    activeKeyDeepDiveTab?: string;
}

const SummaryCard: React.FC<AohSummaryProps> = ({ data }) => {
    const { cardDesign, container, upText, downText, mrBtm } = cardCss

    return (
        <div className={container}>
            {data ? (
                summaryCardProps?.map(({ key, label }) => {
                    const value = data?.[key];
                    return (
                        value !== undefined &&
                        value !== "" && (
                            <div key={`${key}`} className={cardDesign}>
                            <div className={`${upText} ${mrBtm}`} title={value !== null ? value.toString() : 'No Data'} // If value is null, show "No Data"
                            >{value !== null ? formatIntlNumber(value) : '0'}
                            </div>
                                <div className={downText}>{label}</div>
                            </div>
                        )
                    );
                })
            ) : (
                <div className={cardDesign}>
                    <APIErrorMessage apierror={"No Data Available"} />
                </div>
            )}
        </div>
    );
};

export default SummaryCard;
