import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, DELIVERY, VERSION, WORK_CENTER_TEAM, BATCH_DISPOSITION } from 'utils/constants';

interface BatchDispositionInfoState {
  batchDispositionInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: BatchDispositionInfoState = {
  batchDispositionInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, DELIVERY, BASE_VIEW, BATCH_DISPOSITION];
const fetchBatchDisposition = fetchApi(segments);

const batchDispositionReducer = createSlice({
  name: 'batchDisposition',
  initialState,
  reducers: {
    clearBatchDispositionData(state) {
      state.batchDispositionInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatchDisposition.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchBatchDisposition.fulfilled, (state, action) => {
        state.loading = false;
        state.batchDispositionInfo = action.payload
      })
      .addCase(fetchBatchDisposition.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

  },
});

export { fetchBatchDisposition };

export const { clearBatchDispositionData } = batchDispositionReducer.actions

export default batchDispositionReducer.reducer;