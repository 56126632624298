import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType, BaseViewCardPropsType } from 'types/wctTypes';
import { formatIntlNumber, getFormattedMonthYearV2, setCapsLabel, setData } from 'utils/common-methods';
import { BASE_VIEW, METRICS, PRODUCTIVITY, SCRAP_URL, VERSION, WORK_CENTER_TEAM } from 'utils/constants';
interface scrapBvInfoState {
  scrapBvInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: scrapBvInfoState = {
  scrapBvInfo: null,
  loading: false,
  error: false,
}

const fetchscrapBv = fetchApi([WORK_CENTER_TEAM, METRICS, VERSION, PRODUCTIVITY, BASE_VIEW, SCRAP_URL]);

const transformData = (scrapBvData: any[]): any[] => {
  return scrapBvData.map((item: any) => {
    const tempData = { ...item };

    if (tempData.isCard) {
      tempData.data = setData(tempData.data);
    }

    if (tempData.isTrendGraph) {
      tempData.trendGraphConfig.yAxis = {
        ...tempData.trendGraphConfig.yAxis,
        formatterKey: 'formatIntlNumber'
      };
    }

    if (tempData.isHorizontalBarGraph) {
      tempData.data = tempData.data?.map((dataItem: any) => {
        return {
          name: setCapsLabel(String(dataItem?.material_subgroup)),
          value: dataItem?.std_scrap_factor
        };
      });
    }

    return tempData;
  });
};

const scrapBvReducer = createSlice({
  name: 'scrapBv',
  initialState,
  reducers: {
    clearscrapBvData(state) {
      state.scrapBvInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchscrapBv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchscrapBv.fulfilled, (state, action) => {
        state.loading = false;
        state.scrapBvInfo = transformData(action.payload as any)
      })
      .addCase(fetchscrapBv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

  },
});

export { fetchscrapBv };

export const { clearscrapBvData } = scrapBvReducer.actions

export default scrapBvReducer.reducer;