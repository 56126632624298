import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, MAJOR_EQ_DOWNTIME, METRICS, PRODUCTIVITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface MajorEqDowntimeBvInfoState {
  majorEqDowntimeBvInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: MajorEqDowntimeBvInfoState = {
  majorEqDowntimeBvInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, PRODUCTIVITY, BASE_VIEW, MAJOR_EQ_DOWNTIME];
const fetchMajorEqDowntimeBv = fetchApi(segments);

const majorEqDowntimeBvReducer = createSlice({
  name: 'majorEqDowntimeBv',
  initialState,
  reducers: {
    clearMajorEqDowntimeBvData(state) {
      state.majorEqDowntimeBvInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMajorEqDowntimeBv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchMajorEqDowntimeBv.fulfilled, (state, action) => {
        state.loading = false;
        state.majorEqDowntimeBvInfo = action.payload
      })
      .addCase(fetchMajorEqDowntimeBv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

  },
})

export { fetchMajorEqDowntimeBv };

export const { clearMajorEqDowntimeBvData } = majorEqDowntimeBvReducer.actions

export default majorEqDowntimeBvReducer.reducer;