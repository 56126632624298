import { LineHeightOutlined } from '@ant-design/icons';
import { Waterfall } from '@ant-design/plots';
import React from 'react';
import { aggregateData } from 'utils/common-methods';

interface WaterfallChartProps {
  waterFallData: { name: string; value: number }[];
  activeKeyDeepDiveTab: string;
  filter: string;
  chartWidth?: string;
  chartHeight?: string;
  yAxisTitle?: string;
}

const wrapText = (text: string, wordLimit: number) => {
  const words = text.split(' ');
  let wrappedText = '';
  let line = '';

  words.forEach((word) => {
    if (line.split(' ').length >= wordLimit) {
      wrappedText += line + '\n';
      line = word; // Start a new line
    } else {
      line += (line ? ' ' : '') + word; // Add word to the current line
    }
  });

  // Add any remaining line
  wrappedText += line;

  return wrappedText;
};

const getConfig = (waterFallData: any, activeKeyDeepDiveTab: any, filter?: any, yAxisTitle?: any) => {
  if (activeKeyDeepDiveTab === 'OEE 1 and OEE2 and OEE3') {
    return {
      data: aggregateData(waterFallData?.data, filter),
      xField: 'name',
      yField: 'value',
      paddingRight: 30,
      linkStyle: {
        lineDash: [4, 2],
        stroke: '#333333',
      },
      style: {
        maxWidth: 25,
        stroke: '#ccc',
        fill: (d: any, idx: any) => {
          return idx === 0 || d.isTotal ? '#0063C3' : d.value > 0 ? '#FDB81E' : '#FDB81E';
        },
      },
      label: {
        text: 'value',
        formatter: '.3s',
        position: (d: any) => (d.value > 0 ? 'top' : 'bottom'),
        textBaseline: (d: any) => (d.value > 0 ? 'bottom' : 'top'),
        fontSize: 10,
        dy: (d: any) => (d.value > 0 ? -4 : 4),
      },
      axis: {
        x: {
          line: true,
          lineStroke: '#333',
          lineStrokeOpacity: 1, 

          tick: false,

          labelFontSize: 10,
          labelFontWeight: 300,
          labelFill: "#000000A6",
          labelStroke: "#000000A6",

          title: yAxisTitle,
          titleFontSize: 10,
          titleFontWeight: 400,
          titleFontFamily: "Inter",
          titleStroke: '#000000A6',
          titleStrokeOpacity: 0.3,
          titlePosition: 'bottom'
        },
        y: {
          line: true,
          lineStroke: '#333',
          lineStrokeOpacity: 1, 

          tick: false,

          title: 'TOTAL TIME (IN MINUTES)',
          titleFontSize: 10,
          titleFontWeight: 400,
          titlePosition: 'left',
          titleFontFamily: "Inter",
          titleStroke: '#000000A6',
          titleStrokeOpacity: 0.3,
          
          labelFontSize: 11,
          labelFormatter: '.3s',
          labelFontWeight: 300,
          labelFill: "#000000A6",
          labelStroke: "#000000A6",
        }
      }      
    }
  };
}

const WaterfallChart: React.FC<WaterfallChartProps> = ({ waterFallData, activeKeyDeepDiveTab, filter, chartWidth, chartHeight, yAxisTitle }) => {
  const config = getConfig(waterFallData, activeKeyDeepDiveTab, filter, yAxisTitle)
  return (
    <div style={{ width: chartWidth ? chartWidth : "100%", marginLeft: "5px", ...(chartHeight ? { height: chartHeight } : { height: "100%" }) }}>
      <Waterfall {...config} />
    </div>)
};

// ReactDOM.render(<Waterfall />, document.getElementById('container'));

export default WaterfallChart
