import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

interface StyledTooltipProps {
    content: React.ReactNode;
    children: React.ReactNode;
    size?: 'sm' | 'md' | 'lg';
    open?: boolean;
    setOpen?: (open: boolean) => void;
    header?: React.ReactNode; // Add header prop
}

const StyledTooltip: React.FC<StyledTooltipProps> = ({ content, children, size = 'md', open, setOpen, header }) => {
    const [visible, setVisible] = useState(open ?? false);
    const [tooltipPosition, setStyledTooltipPosition] = useState({ top: 0, left: 0 });
    const arrowRef = useRef(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = (e: React.MouseEvent) => {
        setVisible(true);
        if (setOpen) setOpen(true);
        const rect = e.currentTarget.getBoundingClientRect();
        setStyledTooltipPosition({
            top: rect.top + window.scrollY + rect.height / 2,
            left: rect.left + window.scrollX + rect.width
        });
    };

    const handleMouseLeave = () => {
        setVisible(false);
        if (setOpen) setOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setVisible(false);
            if (setOpen) setOpen(false);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [setOpen]);

    const TipHeaderDesign = styled.div`
    font-weight: 700;
    font-size: 12px;
    font-family: 'Inter';
    line-height: 18px;
    color: #1F1F1F;
  `;
  
  const TipContentDesign = styled.div`
    font-weight: 400;
    font-size: 12px;
    font-family: 'Inter';
    line-height: 18px;
    color: #1F1F1F;
    white-space: pre-wrap; // Allow line breaks
    b {
        font-weight: 700; // Make <b> tags bold
    }
  `;
    const getSizeStyles = () => {
        switch (size) {
            case 'sm':
                return { maxWidth: '100px' };
            case 'lg':
                return { maxWidth: '300px' };
            case 'md':
            default:
                return { maxWidth: '200px' };
        }
    };

    const tooltipContent = (
        <div ref={tooltipRef} style={{
            position: 'absolute',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            transform: 'translateY(-50%)',
            marginBottom: '10px',
            padding: '12px',
            backgroundColor: '#fff',
            color: '#000',
            borderRadius: '8px',
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
            zIndex: 9999, // Set tooltip z-index higher
            whiteSpace: 'normal', // Allow content to wrap
            ...getSizeStyles(),
            overflow: 'visible',
        }}>
            <div ref={arrowRef}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '-10px',
                    transform: 'translateY(-50%)',
                    width: '0',
                    height: '0',
                    borderTop: '10px solid transparent',
                    borderBottom: '10px solid transparent',
                    borderRight: '10px solid #fff',
                    borderLeft: '1px solid #ccc',
                    boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: '2px 0 0 2px', // Make the arrow curvy
                    clipPath: 'polygon(100% 0, 0 50%, 100% 100%)'
                }}
            />
            {header && <TipHeaderDesign>{header}</TipHeaderDesign>} {/* Use header prop */}
            <TipContentDesign dangerouslySetInnerHTML={{ __html: content as string }} /> {/* Use dangerouslySetInnerHTML */}
        </div>
    );

    return (
        <div style={{ position: 'relative', zIndex: 1 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {children}
            {visible && ReactDOM.createPortal(tooltipContent, document.body)}
        </div>
    );
};

export default StyledTooltip;