import { ConfigProvider, Radio } from 'antd';

interface RadioFilter {
  label: string,
  value: string
}

interface RadioFiltersProps {
  filterLabel: string
  selectedFilter?: string;
  radioFilter: RadioFilter[];
  setRadioFilter: (value: string) => void;
}

const RadioFiltersV2: React.FC<RadioFiltersProps> = ({
  filterLabel,
  radioFilter,
  selectedFilter,
  setRadioFilter
}) => {

  return (
    <div className="radio-wrapper" style={{ marginBottom: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div className="label-container ops-fs-6 ops-fw-bold" style={{ marginRight: "15px" }}>
        {filterLabel} :
      </div>
      <div className="button-container">
        <Radio.Group options={radioFilter} defaultValue={radioFilter?.[0]?.value}
          onChange={(e) => setRadioFilter(e.target.value)} />
      </div>
    </div>
  );
};

export default RadioFiltersV2