import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { DEEP_DIVE_VIEW, ANORMALSCRAP_URL, METRICS, PRODUCTIVITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface abNormalScrapDdvInfoState {
  abNormalScrapDdvInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: abNormalScrapDdvInfoState = {
  abNormalScrapDdvInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, PRODUCTIVITY, DEEP_DIVE_VIEW, ANORMALSCRAP_URL];
const fetchabNormalScrapDdv = fetchApi(segments);

const abNormalScrapDdvReducer = createSlice({
  name: 'abNormalScrapDdv',
  initialState,
  reducers: {
    clearabNormalScrapDdvData(state) {
      state.abNormalScrapDdvInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchabNormalScrapDdv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchabNormalScrapDdv.fulfilled, (state, action) => {
        state.loading = false;
        state.abNormalScrapDdvInfo = action.payload
      })
      .addCase(fetchabNormalScrapDdv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
  }
})

export { fetchabNormalScrapDdv };

export const { clearabNormalScrapDdvData } = abNormalScrapDdvReducer.actions

export default abNormalScrapDdvReducer.reducer;