import { Button, StatusIssueIcon, StatusPositiveIcon } from '@gitlab-rtsensing/component-library';
import { Alert } from 'antd';
import metricsCss from "./MetricsComponent.module.css"
import { MetricNames } from 'utils/constants';
// import { getFormattedMonthYear } from 'utils/common-methods';
import React, { useEffect, useState } from 'react'
import { CardWrapper } from 'dct-component-library';
import { callCustomMetricApiWithCurrentParams } from 'reducers/customMetricParamsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { actionMap } from 'utils/custom-metric-card.helper';

interface CustomAlertCSSProperties extends React.CSSProperties {
    "--alert-top-position"?: string;
}

interface MetricsContainerProps {
    metrics: { key: number; label: string }[];
    sqdipType: string
    metricNames: MetricNames
    calendarInfo: any
    incaInfo?: any;
    seeCardsInfo?: any;
    workOrderInfo?: any;
    ehssInfo?: any;
    capaInfo?: any;
    minorDeviationInfo?: any
    majorDeviationInfo?: any
    gmpTrainingInfo?: any
    qualityChangeControlInfo?: any
    qualityCAPAInfo?: any
    qualityRFTInfo?: any
    agingWorkorderInfo?: any
    pmAdherenceInfo?: any
    deliveryCalibrationInfo?: any
    batchDispositionInfo?: any
    onTimeScheduleAdherenceBVInfo?: any
    turnOverInfo?: any
    kanbanHealthBVInfo?: any
    criticalMfcInfo?: any,
    overallEquipmentEfficiencyAllBvInfo?: any,
    majorEqDowntimeBvInfo?: any,
    scrapBvInfo?: any,
    oeeThreeBvInfo?: any,
    setupClosedownAdherenceBvInfo?: any,
    setIsOpenDeepDive?: (isOpen: boolean) => void;
    setActiveKeyDeepDiveTab?: (key: string) => void;
    tierLabel: string;
}

const MetricsContainer: React.FC<MetricsContainerProps> = ({
    metrics,
    sqdipType,
    metricNames,
    calendarInfo,
    incaInfo,
    seeCardsInfo,
    workOrderInfo,
    ehssInfo,
    capaInfo,
    minorDeviationInfo,
    majorDeviationInfo,
    gmpTrainingInfo,
    qualityChangeControlInfo,
    qualityCAPAInfo,
    qualityRFTInfo,
    agingWorkorderInfo,
    pmAdherenceInfo,
    deliveryCalibrationInfo,
    setIsOpenDeepDive,
    setActiveKeyDeepDiveTab,
    batchDispositionInfo,
    onTimeScheduleAdherenceBVInfo,
    turnOverInfo,
    kanbanHealthBVInfo,
    criticalMfcInfo,
    overallEquipmentEfficiencyAllBvInfo,
    majorEqDowntimeBvInfo,
    scrapBvInfo,
    setupClosedownAdherenceBvInfo,
    tierLabel,
    oeeThreeBvInfo
}) => {
    const [topPosition, setTopPosition] = useState<string>('280px')
    const dispatch = useDispatch<AppDispatch>();
    const customMetricParams = useSelector((state: RootState) => state.customMetricParams);
    const tierNumber = customMetricParams?.customMetricParams?.tierNumber;

    const handleCardAPI = (actionKey: keyof typeof actionMap) => {
        if (customMetricParams && actionKey && tierLabel?.toLocaleLowerCase() === tierNumber) {
            dispatch(callCustomMetricApiWithCurrentParams(actionKey))
        }
    };

    useEffect(() => {
        const metricActions = ["majorEqDowntime", "scrap", "setupClosedownAdherence", "oee3", "seeCards", "inca", "capa", "workorder", "ehss", "batchDisposition", "calClosureAdherence", "pmAdherence", "agingWorkOrders", "capastatus", "changecontrol", "majorDeviation", "minorDeviation", "rft", "gxpTraining", 'onTimeScheduleAdherence', "kanbanHealth", "inventoryTurnOver", "criticalMfc", "overallEquipmentEfficiencyAll"] as const;
        metricActions.forEach(action => {
            const isMetricAvailable = metrics.some(({ label }) => label.toLowerCase() === metricNames?.[action]?.toLowerCase());
            if (isMetricAvailable) {
                handleCardAPI(action);
            }
        });
    }, [customMetricParams, tierLabel])

    useEffect(() => {
        if (tierLabel === 'Tier 3') {
            setTopPosition('236px')
        } else {
            setTopPosition('284px')
        }
    }, [tierLabel])

    const customAlertStyle: CustomAlertCSSProperties = {
        '--alert-top-position': topPosition,
    }

    const successAlert = (sqdipType: string): boolean => {
        return true
    }
    return (
        <div id={`wct-${sqdipType.toLowerCase()}`} className={metricsCss.cardContainer}>
            <Alert
                className={metricsCss.customAlert}
                style={customAlertStyle}
                closable={false}
                showIcon
                icon={
                    successAlert(sqdipType) ? (
                        <StatusPositiveIcon
                            fill={"#2CC84D"}
                            height={"20"}
                            width={"20"}
                        />
                    ) : (
                        <StatusIssueIcon
                            fill={"var(--ops-sem-red-primary)"}
                            height={"20"}
                            width={"20"}
                        />
                    )
                }
                message={sqdipType}
                type={`${successAlert(sqdipType) ? "success" : "error"}`}
                action={
                    <Button
                        className={metricsCss.sqdipBtn}
                        label="View"
                        onClick={() => {
                            setIsOpenDeepDive?.(true);
                        }}
                        type="secondary"
                    />
                }
            />

            {/* <CustomMetricCard
                title={`${getFormattedMonthYear()} Summary`}
                isCalendar
                greenDates={calendarInfo?.greenDates}
                redDates={calendarInfo?.redDates}
                className={metricsCss.customSubCard}
                id="safety-month-summary"
            /> */}
            {metrics &&
                metrics.length > 0 &&
                metrics.map(({ key, label }: { key: number; label: string }) => {
                    if (label.toLowerCase() === metricNames?.inca?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={incaInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() === metricNames?.seeCards?.toLowerCase()
                    ) {
                        return (
                            <CardWrapper
                                key={key}
                                data={seeCardsInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }

                    if (
                        label.toLowerCase() ===
                        metricNames?.workorder?.toLowerCase()
                    ) {
                        return (
                            <CardWrapper
                                key={key}
                                data={workOrderInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }

                    if (label.toLowerCase() === metricNames?.ehss?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={ehssInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }

                    if (label.toLowerCase() === metricNames?.capa?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={capaInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    // quality base view
                    if (label.toLowerCase() === metricNames?.rft?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={qualityRFTInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() ===
                        metricNames?.majorDeviation?.toLowerCase()
                    ) {
                        return (
                            <CardWrapper
                                key={key}
                                data={majorDeviationInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() ===
                        metricNames?.minorDeviation?.toLowerCase()
                    ) {
                        return (
                            <CardWrapper
                                key={key}
                                data={minorDeviationInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() === metricNames?.gxpTraining?.toLowerCase()
                    ) {
                        return (
                            <CardWrapper
                                key={key}
                                data={gmpTrainingInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.capastatus?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={qualityCAPAInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.changecontrol?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={qualityChangeControlInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.agingWorkOrders?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={agingWorkorderInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.pmAdherence?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={pmAdherenceInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.calClosureAdherence?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={deliveryCalibrationInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() ===
                        metricNames?.batchDisposition?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={batchDispositionInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (
                        label.toLowerCase() ===
                        metricNames?.onTimeScheduleAdherence?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={onTimeScheduleAdherenceBVInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`delivery-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.inventoryTurnOver?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={turnOverInfo}
                                setIsOpenDeepDive={label !== "Inventory Turnovers" ? setIsOpenDeepDive : undefined}
                                setActiveKeyDeepDiveTab={label !== "Inventory Turnovers" ? (key: string) => setActiveKeyDeepDiveTab?.(key) : undefined}
                                title={label}
                                id={`inventory-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.kanbanHealth?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={kanbanHealthBVInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`inventory-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }

                    if (label.toLowerCase() === metricNames?.criticalMfc?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={criticalMfcInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`inventory-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.overallEquipmentEfficiencyAll?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={overallEquipmentEfficiencyAllBvInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`productivity-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.scrap?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={scrapBvInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`productivity-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.oee3?.toLowerCase()) {
                        return (
                            <CardWrapper
                                key={key}
                                data={oeeThreeBvInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`productivity-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    if (label.toLowerCase() === metricNames?.majorEqDowntime?.toLowerCase() && tierLabel !== 'Tier 0') {
                        return (
                            <CardWrapper
                                key={'p-majorEqDowntime'}
                                data={majorEqDowntimeBvInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`productivity-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    } else if (label.toLowerCase() === metricNames?.setupClosedownAdherence?.toLowerCase() && setupClosedownAdherenceBvInfo) {
                        return (
                            <CardWrapper
                                key={'p-setupClosedownAdherence'}
                                data={setupClosedownAdherenceBvInfo}
                                setIsOpenDeepDive={setIsOpenDeepDive}
                                setActiveKeyDeepDiveTab={(key: string) => setActiveKeyDeepDiveTab?.(key)}
                                title={label}
                                id={`productivity-${label.toLowerCase().replace(/ /g, "-")}`}
                            />
                        );
                    }
                    return null
                })}
        </div>
    )
}

export default MetricsContainer;