import { Empty, Table } from 'antd';
import TableCss from './AohTable.module.css';
import { color } from 'echarts';

  interface AohTableProps {
    tableColumns?: any[];
    tableData?: any[];
  }

  const AohTable: React.FC<AohTableProps> = ({ tableColumns, tableData }) => {

  const { tableContainer } = TableCss
  const columnsWithWidth = tableColumns?.map(column => ({
    ...column,
    onHeaderCell: () => ({
      style: {
        fontSize: '10px',
        lineHeight: '14px',
        fontWeight: 700,
        color: '#000000E0', 
        whiteSpace: 'nowrap',
        marginInlineStart: '8px'
      },
    }),
    onCell: () => ({
      style: {
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 400,
        color: '#1F1F1F', 
        marginBottom: 0,
        minWidth: '134px'
      },
    }),
  }));
    const showTotal = (total: number, range: [number, number]) => `${range[0]} - ${range[1]} of ${total} items`
    const tableScroll =
      tableData?.length && tableData?.length > 0 ? { x: 1200 } : { x: 1200 };
      const locale = {
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" style={{ 
          textAlign: "left",  
          marginLeft: '550px',
          position: 'sticky',  
          top: '10px',        
          backgroundColor: 'white',
          zIndex: 10
         }} />,
      };
  return (
    <div >
      <Table
        columns={columnsWithWidth}
        dataSource={tableData}
        pagination={{ pageSize: 5, showTotal, showSizeChanger: false }}
        scroll={tableScroll}
        locale={locale}
      />
    </div>
  );
}

export default AohTable
