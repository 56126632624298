import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, MINOR_DEVIATION, QUALITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface MinorDeviationsInfoState {
  minorDeviationInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: MinorDeviationsInfoState = {
  minorDeviationInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, QUALITY, BASE_VIEW, MINOR_DEVIATION];
const fetchMinorDeviation = fetchApi(segments);

const minorDeviationReducer = createSlice({
    name: 'minorDeviation',
    initialState,
    reducers: {
        clearMinorDeviationData(state) {
            state.minorDeviationInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMinorDeviation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchMinorDeviation.fulfilled, (state, action) => {
                state.loading = false;
                state.minorDeviationInfo = action.payload
            })
            .addCase(fetchMinorDeviation.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchMinorDeviation };

export const { clearMinorDeviationData } = minorDeviationReducer.actions

export default minorDeviationReducer.reducer;