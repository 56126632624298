import { InventoryDdvT3TableColumns } from "utils/wctConstants";
import RenderTierWiseComponent from "../delivery-tierwise/RenderTierWiseComponent";

interface InventoryTier3Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const InventoryTier3: React.FC<InventoryTier3Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab
}) => {
  return (<div className="inventory-tier-main">
    {data?.map((
      item: any, index: number | null | undefined) => {
      return <RenderTierWiseComponent activeKeyDeepDiveTab={activeKeyDeepDiveTab} tierLabel={tierLabel} key={index} data={item} deepDiveTableColumns={InventoryDdvT3TableColumns} />
    })}
  </div>)
};
export default InventoryTier3;
