import styled from '@emotion/styled';
import type { MenuProps } from 'antd';
import StyledDropdown from './StyledDropdown';

interface HeaderType {
  items: MenuProps['items']
  handleMenuClick?: MenuProps['onClick']
  title: string
  dropOptions?: string
  activeKey?: string
  wctLogoSrc: string
  aohLogoComponent: React.ReactNode
}

const HeaderContainer = styled.div`
    margin-top: 120px;
    border: 1px solid #D9D9D9;
    width: 100%;
    height: 52px;
    border-radius: 10px;
    padding: 5px 20px 5px 5px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #c2c0c080;
`;

const AohLogo = styled.div`
    margin-top: 4px;
`;

const CoContainer = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;

const SubContainer = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    height: 28px;
    gap: 12px;
    margin-top: 6px;
`;

const WctLogo = styled.img`
    width: 235px;
    height: 45px;
    padding: 5px 0px 5px 0px;
    margin-left: 20px;
`;

const DateText = styled.span`
    font-size: 12px;
`;

const StyledHeader: React.FC<HeaderType> = ({ items, handleMenuClick, title, dropOptions, activeKey, wctLogoSrc, aohLogoComponent }) => {
  const date = new Date();
  const day = date.toLocaleDateString('en-US', { weekday: 'long' });
  const month = date.toLocaleDateString('en-US', { month: 'long' });

  return (
    <HeaderContainer>
      <CoContainer>
        <AohLogo>
          {aohLogoComponent}
        </AohLogo>
        <WctLogo src={wctLogoSrc} alt="WCT" />
      </CoContainer>
      {activeKey !== "tab-5" && (
        <SubContainer>
          <StyledDropdown items={items} handleMenuClick={handleMenuClick} title={title} dropOptions={dropOptions} />
          <DateText>
            {`${day} ${month} ${date.getDate()}, ${date.getFullYear()}`}
          </DateText>
        </SubContainer>
      )}
    </HeaderContainer>
  );
}

export default StyledHeader;
