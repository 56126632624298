import SummaryCard from "components/common/summaryCard/summaryCard";
import "./Delivery.scss";
import HorizontalBarChart from "components/common/HorizontalBar/HorizontalBarChart";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { Key, useContext, useMemo, useState } from "react";
import RenderTierWiseComponent from "./RenderTierWiseComponent";
import { batchDispositionDeepDiveFilter, batchDispositionDeepDiveTableColumns, DataTypeKey, DEEP_DIVE_UI_CONSTANTS } from "utils/wctConstants";
import { APIResponseDataType, HorizontalBarChartType, SummaryType } from "types/wctTypes";
import { filterbyDataType, findAndRoundMaxValue } from "utils/common-methods";
import { StringKeyDataProps } from "utils/data-types";
import { newMinMaxValue, roundMaxValue } from "components/common/StackedBar/utils";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";
import { fetchBatchDispositionFilterData } from "reducers/delivery-deep-dive/batchDispositionDeepDiveReducer";

interface DeliveryTier3Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const DeliveryTier3: React.FC<DeliveryTier3Props> = ({
    data,
    activeKeyDeepDiveTab,
    tierLabel,
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const [filter, setFilter] = useState<any>({})
    const summary: APIResponseDataType = filterbyDataType<SummaryType>(data, DataTypeKey.IsSummary)[0]
    const allHorizontalBarCharts: APIResponseDataType[] = filterbyDataType<HorizontalBarChartType>(data, DataTypeKey.IsHorizontalBarChart)

    const agingHorizontalBarChart = allHorizontalBarCharts?.[0]

    const [filterSelect, setFilterSelect] = useState<{ [key: string]: any }>({
        batchefordispositionWithTarget: 'All',
        avgDLTPerBatch: 'All',
    })

    const handleMenuClick = (selected: any, filterType: string, filterValue?: string) => {
        setFilterSelect(prev => ({ ...prev, [filterType]: selected.key }));
        const params = {
            workStream: "manufacturing",
            site: "AOH",
            persona: "wct",
            tierNumber: "tier 3",
            filterType,
            filterValue,
            line: selected.key

        }
        dispatch(fetchBatchDispositionFilterData(params as any));
    }

    const config = {
        xAxisLabel: agingHorizontalBarChart?.dataParams?.xAxisLabel,
        width: 560,
        height: 240,
        xAxisKey: "number_of_open_workorders",
        yAxisKey: "line",
        hideXAxisTicks: true,
        hideYAxisTicks: true,
        showYAxisLine: false
    }

    const handleRadioFilter = (filterType: any, nextValues: any) => {
        setFilter((prev: any) => ({ ...prev, [filterType]: nextValues }))
        const params = {
            workStream: "manufacturing",
            site: "AOH",
            persona: "wct",
            tierNumber: "tier 3",
            filterType,
            filterValue: nextValues,
            line: filterSelect[filterType] ?? 'All'
        }
        dispatch(fetchBatchDispositionFilterData(params as any));
    }

    // Max Sum logic for On Time Schedule Adherence Actual vs Planned Units for each material
    let maxSum = 100;

    if (activeKeyDeepDiveTab === "On Time Schedule Adherence") {
        const dateSums = data?.[0]?.data?.reduce((acc: { [key: string]: { planned_units: number; actual_units: number } }, entry: any) => {
            const date = entry.date;
            if (!acc[date]) {
                acc[date] = { planned_units: 0, actual_units: 0 };
            }
            Object.keys(entry).forEach((key) => {
                if (key.endsWith('_planned_units')) {
                    acc[date].planned_units += entry[key] as number;
                } else if (key.endsWith('_actual_units')) {
                    acc[date].actual_units += entry[key] as number;
                }
            });
            return acc;
        }, {});
    
        const maxPlannedUnits = Math.max(...Object.values(dateSums ?? {}).map((sums: any) => sums?.planned_units));
        const maxActualUnits = Math.max(...Object.values(dateSums ?? {}).map((sums: any) => sums?.actual_units));
    
        maxSum = Math.max(maxPlannedUnits, maxActualUnits);
    
        if (maxSum === 0) {
            maxSum = 100;
        }
    
        maxSum = roundMaxValue(maxSum);
    }

    const deliveryDeepDive: { [key: string]: any } = useMemo(() => ({
        "% PM Adherence & Upcoming PMs": {
            title: "Ratio of PM vs (CM + GM)",
            subtitle: "MFG",
            verticalChart1: (
                <StackedBarChart
                    data={data[0]?.data}
                    chartType={"ratio-pm"}
                    xAxisData={Array.isArray(data[0]?.data) ? (data[0]?.data).map((item: any) => item.line) : []}
                    yAxisNames={[
                        { name: "RATIO OF PM VS (CM + GM)", position: "left", max: 1 },
                    ]}
                    nameGapCustom={54}
                />
            ),
            title1: "Ratio of PM vs (CM + GM)",
            verticalChart2: (
                <StackedBarChart data={data[1]?.data}
                    chartType={"ratio-pm-vs"}
                    xAxisData={data[1]?.data?.map((item: any) => item.labor_group)}
                    yAxisNames={[
                        { name: "RATIO OF PM VS (CM + GM)", position: "left", max: 1 },
                    ]}
                    nameGapCustom={54}
                />
            ),
            title2: "% Closure Adherence of PM",
            subtitle2: "In Last 6 Months",
            verticalChart3: (
                <StackedBarChart data={data[2]?.data}
                    chartType={"percent-closure-pm"}
                    xAxisData={data[2]?.data?.map((item: any) => item?.date)}
                    yAxisNames={[
                        { name: "TOTAL WORK ORDERS OF PM", position: "left", max: Array.isArray(data[2]?.data) ? newMinMaxValue(data?.[2]?.data)?.Max : undefined },
                        { name: "% CLOSURE ADHERENCE OF PM", position: "right", max: 100, nameRotate: -90, showPercentageLabel: true },
                    ]}
                />
            ),
        },
        "Aging Work Orders": {
            summaryTitle: DEEP_DIVE_UI_CONSTANTS.SUMMARY,
            summary: (<SummaryCard data={summary?.data} />),
            title2: agingHorizontalBarChart?.dataParams?.title,
            horizontalBarChart: (
                <HorizontalBarChart
                    data={agingHorizontalBarChart?.data as StringKeyDataProps[]}
                    config={config} />
            )
        },
        "Calibration Closure Adherence": {
            titleCalibration: "WO Calibration Distribution Across Lines",
            verticalChart: (
                <StackedBarChart
                    data={data[0]?.data}
                    chartType={"wo-calibration-dis"}
                    xAxisData={Array.isArray(data[0]?.data) ? data[0]?.data?.map((item: any) => item.line) : []}
                    yAxisNames={[
                        { name: "WORK ORDER CALIBRATION DISTRIBUTION", position: "left", max: Array.isArray(data[0]?.data) ? newMinMaxValue(data[0]?.data)?.Max : undefined },
                    ]}
                />
            ),
            title2: "% Calibration Closure Adherence",
            subtitle2: "In Last 6 Months",
            verticalChart3: (
                <StackedBarChart
                    data={data[1]?.data}
                    chartType={"per-calibration-closure"}
                    xAxisData={data[1]?.data?.map((item: any) => item?.month)}
                    yAxisNames={[
                        { name: "TOTAL WORK ORDERS", position: "left", max: Array.isArray(data[1]?.data) ? newMinMaxValue(data[1]?.data)?.Max : undefined },
                        { name: "% CALIBRATION CLOSURE ADHERENCE", position: "right", max: 100, showPercentageLabel: true },
                    ]}
                />
            )
        },
        "On Time Schedule Adherence": {
            titleCalibration: "Distribution of Actual and Planned Units by Product",
            verticalChart: (
                <StackedBarChart
                    data={data?.[0]?.data}
                    chartType={"on-time-ddv-actual-and-planned-by-product"}
                    xAxisData={Array.from(new Set(Array.isArray(data[0]?.data) ? data[0]?.data.map((item: any) => item.date) : []))}
                    yAxisNames={[
                        { name: "UNITS BY PRODUCT", position: "left", max: Array.isArray(data[0]?.data) ? maxSum : undefined },
                    ]}
                    xAxisNames={activeKeyDeepDiveTab === "On Time Schedule Adherence" ? [{ name: 'ACTUAL AND PLANNED', position: 'bottom', nameGap: 35 }] : []}
                    xAxisNameStyleConfig={activeKeyDeepDiveTab === "On Time Schedule Adherence" ? { fontSize: 10, fontWeight: 700 } : undefined}
                    nameGapCustom={76}
                />
            ),
            title2: "Distribution of Actual vs Planned Units",
            subtitle2: "Last 6 months",
            verticalChart4: (
                <StackedBarChart
                    data={data[1]?.data}
                    chartType={"on-time-ddv-actual-vs-planned-six-months"}
                    xAxisData={Array.isArray(data[1]?.data) ? (data[1]?.data).map((item: any) => item.month) : []}
                    yAxisNames={[
                        { name: "UNITS BY PRODUCT", position: "left", max: Array.isArray(data[1]?.data) ? findAndRoundMaxValue(data[1]?.data, 'actual_units', 'planned_units') : undefined },
                    ]}
                    nameGapCustom={76}
                />
            )
        },
    }), [data, activeKeyDeepDiveTab]);

    if (activeKeyDeepDiveTab === "Batch Disposition") {
        return (<div className="delivery-tier-main">
            {data?.map((
                item: unknown, index: Key | null | undefined) => {
                return <RenderTierWiseComponent activeFilter={filter} setTableType={handleRadioFilter} activeKeyDeepDiveTab={activeKeyDeepDiveTab} tierLabel={tierLabel} key={index} data={item} handleMenuClick={handleMenuClick} extraFilterselect={filterSelect} deepDiveFilters={batchDispositionDeepDiveFilter} deepDiveTableColumns={batchDispositionDeepDiveTableColumns} />
            })}
        </div>)
    } else {
        return (
            <div className="delivery-tier-main">
                {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle && (
                    <div className="tier-container">
                        <h2 className="tier-title">
                            {/* Summary card */}
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                        </h2>
                    </div>
                )}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.summary}
                <div
                    className={
                        deliveryDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                    }
                ></div>
                {deliveryDeepDive[activeKeyDeepDiveTab]?.titleCalibration && (
                <div className="tier-container">
                    <h2 className="tier-title">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.titleCalibration}
                    </h2>
                </div>
                )}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart}
                <div
                    className={
                        deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart ? "mr-bottom-20" : ""
                    }
                ></div>
                <div className="tier-sections-container">
                    {/* section 1 */}
                    <div className="tier-container section section-left">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.title && (
                            <h2 className="tier-title">
                                {deliveryDeepDive[activeKeyDeepDiveTab]?.title}
                            </h2>)}

                        <div className="tier-subtitle">
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle}
                        </div>
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart1}
                    </div>

                    {/* section 2 */}
                    {(deliveryDeepDive[activeKeyDeepDiveTab]?.title1 || deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart2) && (
                        <div className="tier-container section section-right">
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.title1 && (
                                <h2 className="tier-title">
                                    {deliveryDeepDive[activeKeyDeepDiveTab]?.title1}
                                </h2>
                            )}
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart2}
                        </div>
                    )}
                </div>
                <div
                    className={
                        deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart1 ? "mr-bottom-20" : ""
                    }
                ></div>
                {deliveryDeepDive[activeKeyDeepDiveTab]?.title2 && (
                    <div className="tier-container">
                        <h2 className="tier-title">
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.title2}
                        </h2>
                    </div>
                )}
                <div className="section-left">
                    {deliveryDeepDive[activeKeyDeepDiveTab]?.horizontalBarChart}
                </div>
                {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle2 && (
                <div className="tier-subtitle">
                    {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle2}
                </div>
                )}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart3}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart4}
            </div>
        )
    }
};
export default DeliveryTier3
