import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { FetchDataParams, GraphParamsType } from 'types/wctTypes'
import { filterParams, getMonthName, getMonthNameWithString, getOktaToken, setData, setDataKeys } from 'utils/common-methods'
import { QUALITY_DEEP_DIVE_RFT_URL } from 'utils/constants'
import { StringKeyAnyDataProps, StringKeyDataProps } from 'utils/data-types'

interface RFTType {
    card_data?: StringKeyDataProps
    deviations_over_target?: StringKeyDataProps[]
    pending_exception?: StringKeyDataProps[]
    mtd_graph_data?: StringKeyDataProps[]
    trend_graph_data?: StringKeyDataProps[]
    graph_params?: StringKeyAnyDataProps
}

interface RFTDataType {
    data: any[],
    tableData: any[],
}

interface CardProps {
    alertType: "error" | "success"
}
interface RFTInfoStateType {
    qualityDdRFTInfo: any;
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: boolean
}

const initialState: RFTInfoStateType = {
    qualityDdRFTInfo: null,
    status: 'idle',
    error: false,
}

const fetchQualityDeepdiveRFT = createAsyncThunk<RFTType, FetchDataParams>(
    QUALITY_DEEP_DIVE_RFT_URL,
    async (params) => {
        const filteredParams = filterParams(params)

        const token: string = getOktaToken()
        const response = await fetch(`${process.env.REACT_APP_API_URL}${QUALITY_DEEP_DIVE_RFT_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (!response.ok) {
            throw new Error('Failed to fetch RFT')
        }
        return await response.json()
    }
)

const transformData = (RFT: any, tierNumberValue: string = ''): RFTDataType => {
    if (tierNumberValue.toLowerCase() === 'tier 1') {
        const modData = Array.isArray(RFT) ? RFT.reduce((prevData, currData) => {
            if (currData.isBarChart) {
                if (Array.isArray(currData.data)) {
                    const graphModData = currData.data.map((item: any) => {
                        return {
                            name: item.line,
                            value: item.number_of_pending_exception,
                            avg_pending_exception: item.avg_pending_exception
                        }
                    })
                    currData.data = {
                        avgPendingException: graphModData?.[0]?.avg_pending_exception || 0,
                        graphAllData: graphModData,
                    };
                }
                prevData.data = currData.data;
            } else if (currData.isTableAllLineFilter) {
                prevData.tableData.allLineFilterData = currData?.data || [];
            } else if (currData.isTableAll) {
                prevData.tableData.All = currData?.data || [];
            }

            return prevData;
        }, {
            data: {},
            tableData: {
                allLineFilterData: [],
                All: [],
            },
        }) : [];
        const allTableData = modData.tableData.All || [];
        const lineWiseData = allTableData.reduce((prevData: any, currData: any) => {
            const line = currData.line;
            if (!prevData[line]) {
                prevData[line] = [];
            }
            prevData[line].push(currData);
            return prevData;
        }, {});

        if (lineWiseData) {
            const tableData = {
                ...modData.tableData,
                ...lineWiseData
            };

            modData.tableData = tableData;
        }

        return {
            tableData: modData.tableData,
            data: modData.data,
        };
    }

    if (tierNumberValue.toLowerCase() === 'tier 2') {
        const modData = Array.isArray(RFT) ? RFT.reduce((prevData, currData) => {
            if (currData.isCard) {
                prevData.summary = currData?.data?.[0] || {};
            } else if (currData.isBarChart) {
                if (currData.pendingExceptions) {
                    const pendingExceptionsData = (currData.data || [])?.map((item: any) => {
                        return {
                            name: `${item.line}`,
                            value: item.number_of_pending_exception
                        }
                    });
                    prevData.barGraphData.pendingExceptions = pendingExceptionsData || [];
                };

                if (currData.batchDeviations) {
                    const batchDeviationsData = (currData.data || [])?.map((item: any) => {
                        return {
                            name: `${item.line}`,
                            value: item.number_of_deviations
                        }
                    });
                    prevData.barGraphData.batchDeviations = batchDeviationsData || [];
                };
            } else if (currData.isLineChart) {
                const lineAvgData = (currData.data || [])?.map((item: any) => {
                    return {
                        name: item.date,
                        value: item.avg
                    }
                });
                const lineOpenExceptData = (currData.data || [])?.map((item: any) => {
                    return {
                        name: item.date,
                        value: item.open_lines_excep_count
                    }
                });
                prevData.lineGraphData.avg = lineAvgData || [];
                prevData.lineGraphData.lines = lineOpenExceptData || [];
            } else if (currData.isTableAll) {
                prevData.tableDataAll = currData?.data || [];
            } else if (currData.isTablePending) {
                prevData.tableDataPending = currData?.data || [];
            } else if (currData.isTableAcknowledge) {
                prevData.tableDataAcknowledge = currData?.data || [];
            } else {
                prevData.data = currData;
            }
            return prevData;
        }, {
            summary: {},
            barGraphData: {
                pendingExceptions: [],
                batchDeviations: [],
            },
            lineGraphData: {
                avg: [],
                lines: [],
            },
            tableDataAll: [],
            tableDataPending: [],
            tableDataAcknowledge: [],
        }) : [];
        return modData;
    }

    if (tierNumberValue.toLowerCase() === 'tier 3') {
        const modData = Array.isArray(RFT) ? RFT.reduce((prevData, currData) => {
            if (currData.isLine) {
                prevData.lines = currData?.extraFilterData || [];
            } else if (currData.isCard) {
                prevData.summary = currData?.data?.[0] || {};
            } else if (currData.isExceptionsLineMtd) {
                prevData.exceptionGraphData.mtdAll = (currData.data || []).map((item: any) => ({
                    name: item.mtd_date ?? '',
                    dateValue: item.mtd_date ?? '',
                    minMax: [item.min_exp ?? 0, item.max_exp ?? 0],
                    lineValue: item.avg_excep ?? 0,
                }));
            } else if (currData.isExceptionsLineMtdLine) {
                prevData.exceptionGraphData.mtdLine = (currData.data || []).map((item: any) => ({
                    name: item.line ?? 0,
                    dateValue: item.mtd_date ?? '',
                    minMax: [item.min_exp ?? 0, item.max_exp ?? 0],
                    lineValue: item.avg_excep ?? 0,
                }));
            } else if (currData.isExceptionsLine6m) {
                prevData.exceptionGraphData.sixMonthsAll = (currData.data || []).map((item: any) => ({
                    name: item.month ?? '',
                    dateValue: item.month ?? '',
                    minMax: [item.min_exp ?? 0, item.max_exp ?? 0],
                    lineValue: item.avg_excep ?? 0,
                }));
            } else if (currData.isExceptionsLine6mLine) {
                prevData.exceptionGraphData.sixMonthsLine = (currData.data || []).map((item: any) => ({
                    name: item.line ?? 0,
                    dateValue: item.month ?? '',
                    minMax: [item.min_exp ?? 0, item.max_exp ?? 0],
                    lineValue: item.avg_excep ?? 0,
                }));
            } else if (currData.isDeviationsMtd) {
                prevData.deviationGraphData.mtdAll = (currData.data || []).map((item: any) => ({
                    name: item.mtd_date ?? '',
                    dateValue: item.mtd_date ?? '',
                    minMax: [item.min_devi ?? 0, item.max_devi ?? 0],
                    lineValue: item.avg_devi ?? 0,
                }));
            } else if (currData.isDeviationsMtdLine) {
                prevData.deviationGraphData.mtdLine = (currData.data || []).map((item: any) => ({
                    name: item.line ?? 0,
                    dateValue: item.mtd_date ?? '',
                    minMax: [item.min_devi ?? 0, item.max_devi ?? 0],
                    lineValue: item.avg_devi ?? 0,
                }));
            } else if (currData.isDeviations6m) {
                prevData.deviationGraphData.sixMonthsAll = (currData.data || []).map((item: any) => ({
                    name: item.month ?? '',
                    dateValue: item.month ?? '',
                    minMax: [item.min_devi ?? 0, item.max_devi ?? 0],
                    lineValue: item.avg_devi ?? 0,
                }));
            } else if (currData.isDeviations6mLine) {
                prevData.deviationGraphData.sixMonthsLine = (currData.data || []).map((item: any) => ({
                    name: item.line ?? 0,
                    dateValue: item.month ?? '',
                    minMax: [item.min_devi ?? 0, item.max_devi ?? 0],
                    lineValue: item.avg_devi ?? 0,
                }));
            } else {
                prevData.data = currData;
            }
    
            return prevData;
        }, {
            lines: [],
            summary: {},
            exceptionGraphData: {
                mtdAll: [],
                mtdLine: [],
                sixMonthsAll: [],
                sixMonthsLine: [],
            },
            deviationGraphData: {
                mtdAll: [],
                mtdLine: [],
                sixMonthsAll: [],
                sixMonthsLine: [],
            },
        }) : [];
    
        return modData;
    }
    
    return RFT;
}

const qualityDdRFTSlice = createSlice({
    name: 'qualityDeepdiveRFT',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchQualityDeepdiveRFT.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchQualityDeepdiveRFT.fulfilled, (state, action: PayloadAction<RFTType>) => {
                state.status = 'succeeded'
                state.qualityDdRFTInfo = transformData(action.payload, ((action as any)?.meta?.arg?.tierNumber) ?? '')
            })
            .addCase(fetchQualityDeepdiveRFT.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchQualityDeepdiveRFT }

export default qualityDdRFTSlice.reducer