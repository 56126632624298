/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import styled from '@emotion/styled';

const CleanProgressContainer = styled.div<{ config?: any }>`
  width: ${({ config }) => config?.containerWidth || '100%'};
  height: ${({ config }) => config?.containerHeight || '11px'};
  position: relative;
  background-color: ${({ config }) => config?.containerBgColor || '#E3E3E3'};
  border-radius: ${({ config }) => config?.containerBorderRadius || '2px'};
  margin-bottom: ${({ config }) => config?.containerMarginBottom || '28px'};
  padding: ${({ config }) => config?.containerPadding || '0'};
  box-sizing: border-box;
`;

const ChildDivRedContainer = styled.div<{ config?: any }>`
  width: 100%;
  height: ${({ config }) => config?.barHeight || '11px'};
  background-color: ${({ config }) => config?.redBarColor || 'var(--ops-sem-red-primary)'};
  border-radius: ${({ config }) => config?.barBorderRadius || '2px'};
`;

const ChildDivBlueContainer = styled.div<{ config?: any }>`
  width: 100%;
  height: ${({ config }) => config?.barHeight || '11px'};
  background-color: ${({ config }) => config?.blueBarColor || '#0063C3'};
  border-radius: ${({ config }) => config?.barBorderRadius || '2px'};
`;

const ProgressBarWrapper = styled.div<{ config?: any }>`
  display: flex;
  height: ${({ config }) => config?.wrapperHeight || '20px'};
  width: ${({ config }) => config?.wrapperWidth || '100%'};
`;

const ProgressLabels = styled.div<{ config?: any }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ config }) => config?.labelsMarginTop || '2px'};
  font-size: ${({ config }) => config?.labelsFontSize || '9px'};
  font-weight: ${({ config }) => config?.labelsFontWeight || '700'};
  color: ${({ config }) => config?.labelsColor || '#595959'};
`;

const StartLabel = styled.span<{ config?: any }>`
  text-align: left;
`;

const EndLabel = styled.span<{ config?: any }>`
  text-align: right;
`;

const BlackLineContainer = styled.div<{ config?: any }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transform: translateX(-50%);
  &:hover .tooltip {
    display: block;
  }
`;

const ProgressBlackLine = styled.div<{ config?: any }>`
  position: absolute;
  top: ${({ config }) => config?.lineTop || '-26.5px'};
  height: ${({ config }) => config?.lineHeight || '21px'};
  width: ${({ config }) => config?.lineWidth || '2.8px'};
  background-color: ${({ config }) => config?.lineColor || '#1F1F1F'};
  border-radius: ${({ config }) => config?.lineBorderRadius || '4px !important'};
`;

const Tooltip = styled.span<{ config?: any }>`
  display: none;
  position: absolute;
  bottom: ${({ config }) => config?.tooltipBottom || '120%'};
  background-color: ${({ config }) => config?.tooltipBgColor || '#ffff'};
  color: ${({ config }) => config?.tooltipColor || '#595959'};
  padding: ${({ config }) => config?.tooltipPadding || '5px 8px'};
  border-radius: ${({ config }) => config?.tooltipBorderRadius || '4px'};
  font-size: ${({ config }) => config?.tooltipFontSize || '9px'};
  font-weight: ${({ config }) => config?.tooltipFontWeight || '700'};
  white-space: nowrap;
  z-index: 1;
`;

const ProgressBarContainer = styled.div`
  padding: 12px 0;
`;

interface percentDetail {
  percentage: number;
  flag: string;
  passedHour: string;
  totalHour: string;
}

interface ProgressType {
  data: percentDetail[];
  config?: {
    startLabel?: string;
    endLabel?: string;
    containerWidth?: string;
    containerHeight?: string;
    containerBgColor?: string;
    containerBorderRadius?: string;
    containerMarginBottom?: string;
    containerPadding?: string;
    barHeight?: string;
    redBarColor?: string;
    blueBarColor?: string;
    barBorderRadius?: string;
    wrapperHeight?: string;
    wrapperWidth?: string;
    labelsMarginTop?: string;
    labelsFontSize?: string;
    labelsFontWeight?: string;
    labelsColor?: string;
    lineTop?: string;
    lineHeight?: string;
    lineWidth?: string;
    lineColor?: string;
    lineBorderRadius?: string;
    tooltipBottom?: string;
    tooltipBgColor?: string;
    tooltipColor?: string;
    tooltipPadding?: string;
    tooltipBorderRadius?: string;
    tooltipFontSize?: string;
    tooltipFontWeight?: string;
  };
}

const CleanProgressBarStyledComponent: React.FC<ProgressType> = ({ data, config }) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth((prev) => currentWidth ?? prev);
  }, [containerRef]);

  return (
    <ProgressBarContainer>
      <CleanProgressContainer ref={containerRef} config={config}>
        {data.length > 0 && (
          <>
            <ProgressBarWrapper config={config}>
              {data.map((data: percentDetail, i: number) => (
                data.flag === "RED" ? (
                  <ChildDivRedContainer key={i} style={{ width: `${data.percentage}%` }} config={config} />
                ) : data.flag === "BLUE" ? (
                  <ChildDivBlueContainer key={i} style={{ width: `${data.percentage}%` }} config={config} />
                ) : (
                  <div key={i} style={{ width: `${data.percentage}%` }} />
                )
              ))}
            </ProgressBarWrapper>
            <ProgressLabels config={config}>
              <StartLabel config={config}>{config?.startLabel ?? "0 HR"}</StartLabel>
              {data.map((data: percentDetail, i: number) => (
                <BlackLineContainer
                  key={i}
                  style={{ left: `${Math.min(data.percentage, 100)}%` }} // Ensure it does not exceed 100%
                  config={config}
                >
                  <ProgressBlackLine config={config} />
                  <Tooltip className="tooltip" config={config}>{data.passedHour}</Tooltip>
                </BlackLineContainer>
              ))}
              <EndLabel config={config}>{config?.endLabel ?? data[0]?.totalHour}</EndLabel>
            </ProgressLabels>
          </>
        )}
      </CleanProgressContainer>
    </ProgressBarContainer>
  );
};

export default CleanProgressBarStyledComponent;
