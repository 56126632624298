import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, EHSS, METRICS, SAFETY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface EhssInfoState {
  ehssInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: EhssInfoState = {
  ehssInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, SAFETY, BASE_VIEW, EHSS];
const fetchEhss = fetchApi(segments);

const ehssReducer = createSlice({
    name: 'ehss',
    initialState,
    reducers: {
        clearEhssData(state) {
            state.ehssInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEhss.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchEhss.fulfilled, (state, action) => {
                state.loading = false;
                state.ehssInfo = action.payload
            })
            .addCase(fetchEhss.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
 
    },
});
 
export { fetchEhss };

export const { clearEhssData } = ehssReducer.actions
 
export default ehssReducer.reducer;