import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, DELIVERY, VERSION, WORK_CENTER_TEAM, ON_TIME_SCHEDULE_ADHERENCE } from 'utils/constants';

interface BatchDispositionInfoState {
  onTimeScheduleAdherenceBVInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: BatchDispositionInfoState = {
  onTimeScheduleAdherenceBVInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, DELIVERY, BASE_VIEW, ON_TIME_SCHEDULE_ADHERENCE];
const fetchOnTimeScheduleAdherenceBV = fetchApi(segments);

const OnTimeScheduleAdherenceBVReducer = createSlice({
    name: 'OnTimeScheduleAdherenceBV',
    initialState,
    reducers: {
        clearOnTimeScheduleAdherenceBVData(state) {
            state.onTimeScheduleAdherenceBVInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOnTimeScheduleAdherenceBV.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchOnTimeScheduleAdherenceBV.fulfilled, (state, action) => {
                state.loading = false;
                state.onTimeScheduleAdherenceBVInfo = action.payload;
            })
            .addCase(fetchOnTimeScheduleAdherenceBV.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchOnTimeScheduleAdherenceBV };

export const { clearOnTimeScheduleAdherenceBVData } = OnTimeScheduleAdherenceBVReducer.actions

export default OnTimeScheduleAdherenceBVReducer.reducer;