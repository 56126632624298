import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, METRICS, SAFETY, VERSION, WORK_CENTER_TEAM, WORK_ORDERS } from 'utils/constants';

interface WorkOrdersInfoState {
  workOrderInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: WorkOrdersInfoState = {
  workOrderInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, SAFETY, BASE_VIEW, WORK_ORDERS];
const fetchWorkOrder = fetchApi(segments);

const workOrderReducer = createSlice({
    name: 'workOrder',
    initialState,
    reducers: {
        clearworkOrderData(state) {
            state.workOrderInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkOrder.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchWorkOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.workOrderInfo = action.payload;
            })
            .addCase(fetchWorkOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchWorkOrder };

export const { clearworkOrderData } = workOrderReducer.actions

export default workOrderReducer.reducer;