/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import styled from '@emotion/styled';
import StyledTooltip from '../metric-card/StyledTooltip';

const MultiProgressContainer = styled.div<{ config?: any }>`
  width: ${({ config }) => config?.containerWidth || '100%'};
  padding: ${({ config }) => config?.containerPadding || '0'};
  box-sizing: border-box;
`;

const ProgressBarContainer = styled.div`
  position: relative; // Add relative positioning to contain the target line
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  white-space: nowrap;
`;

const ProgressBarWrapper = styled.div<{ config?: any }>`
  position: relative;
  display: flex;
  height: ${({ config }) => config?.barHeight || '11px'};
  width: ${({ config }) => config?.barWidth || '100%'};
  background-color: ${({ config }) => config?.barBgColor || '#E3E3E3'};
  border-radius: ${({ config }) => config?.barBorderRadius || '2px'};
  overflow: hidden;
  margin-left: 10px;
`;

const ProgressBar = styled.div<{ percentage: number; color: string }>`
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ color }) => color};
`;

const TargetLine = styled.div<{ target: number; config?: any }>`
  position: absolute;
  left: ${({ target }) => `calc(${target}% - 1px)`}; // Adjust for line width
  top: 0; // Align with the top of the container
  height: 100%; // Extend to the full height of the container
  border-left: ${({ config }) => config?.targetLineWidth || '2px'} dashed ${({ config }) => config?.targetLineColor || '#000000'};
  z-index: 1; // Ensure it is above other elements
`;

const ProgressLabels = styled.div<{ config?: any }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ config }) => config?.labelsMarginTop || '2px'};
  font-size: ${({ config }) => config?.labelsFontSize || '10px'};
  font-weight: ${({ config }) => config?.labelsFontWeight || '700'};
  line-height: ${({ config }) => config?.labelsLineHeight || '14px'};
  color: ${({ config }) => config?.labelsColor || '#595959'};
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: #8c8c8c;
`;

const LegendColorBox = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color};
  margin-right: 5px;
`;

const LegendLine = styled.div<{ config?: any }>`
  width: 20px;
  height: 0;
  border-top: ${({ config }) => config?.targetLineWidth || '2px'} dashed ${({ config }) => config?.targetLineColor || '#000000'};
  margin-right: 5px;
`;

interface ProgressData {
  line: string;
  completed: number;
  delta: number;
  target: number;
}

interface MultiProgressType {
  data: ProgressData[];
  config?: {
    containerWidth?: string;
    containerPadding?: string;
    barHeight?: string;
    barWidth?: string;
    barBgColor?: string;
    barBorderRadius?: string;
    labelsMarginTop?: string;
    labelsFontSize?: string;
    labelsFontWeight?: string;
    labelsColor?: string;
    targetLineWidth?: string;
    targetLineColor?: string;
  };
}

const StyledMultiProgressBarChart: React.FC<MultiProgressType> = ({ data, config }) => {
  return (
    <MultiProgressContainer config={config}>
      {data.map((item, index) => (
        <StyledTooltip
          key={index}
          content={`<b>Progress Chart</b>\nThis chart shows the progress and target for each line.\n<b>Line</b>: ${item.line}\n<b>Completed</b>: ${item.completed}%\n<b>Delta</b>: ${item.delta}%\n<b>Target</b>: ${item.target}%\n`}
        >
          <ProgressBarContainer>
            <ProgressLabels config={config}>
              <span>{item.line}</span>
            </ProgressLabels>
            <ProgressBarWrapper config={config}>
              <ProgressBar percentage={item.completed} color="#0063C3" />
            </ProgressBarWrapper>
            <TargetLine target={item.target} config={config} />
          </ProgressBarContainer>
        </StyledTooltip>
      ))}
      <LegendContainer>
        <LegendItem>
          <LegendColorBox color="#0063C3" />
          <span>Completed</span>
        </LegendItem>
        <LegendItem>
          <LegendLine config={config} />
          <span>Target</span>
        </LegendItem>
      </LegendContainer>
    </MultiProgressContainer>
  );
};

export default StyledMultiProgressBarChart;
