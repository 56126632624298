import { createSlice } from '@reduxjs/toolkit'
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes'
import { DEEP_DIVE_VIEW, DELIVERY, METRICS, PREVENTIVE_MAINTENANCE, WORK_CENTER_TEAM } from 'utils/constants'

interface PMAdherenceDeepDiveStateProps {
    pmAdherenceDeepDiveInfo: APIResponseDataType[] | null;
    loading: boolean;
    error: boolean;
}

const initialState: PMAdherenceDeepDiveStateProps = {
    pmAdherenceDeepDiveInfo: null,
    loading: false,
    error: false
}

const segments = [WORK_CENTER_TEAM, METRICS, DELIVERY, DEEP_DIVE_VIEW, PREVENTIVE_MAINTENANCE];
const fetchPmAdherenceDeepDive = fetchApi(segments);

const pmAdherenceDeepDiveReducer = createSlice({
    name: 'pmAdherenceDeepDive',
    initialState,
    reducers: {
        clearPmAdherenceDeepDiveData(state) {
            state.pmAdherenceDeepDiveInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPmAdherenceDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchPmAdherenceDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.pmAdherenceDeepDiveInfo = action.payload
            })
            .addCase(fetchPmAdherenceDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
})

export { fetchPmAdherenceDeepDive }

export const { clearPmAdherenceDeepDiveData } = pmAdherenceDeepDiveReducer.actions

export default pmAdherenceDeepDiveReducer.reducer
