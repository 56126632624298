import { normalScarpDdvColumns, ProductivityDdvT1TableColumns } from "utils/wctConstants";
import RenderTierWiseComponent from "../delivery-tierwise/RenderTierWiseComponent";
import "./Productivity.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { useEffect, useState } from "react";
import { clearOeeThreeDdvData, fetchOeeThreeDdv } from "reducers/productivity-deep-dive/oee3DdvReducer";
import { clearnormalScrapDdvData, fetchnormalScrapDdv } from "reducers/productivity-deep-dive/normalScarpDdvReducer";
import { clearMajorEqDowntimeDdvData, fetchmajorEqDowntimeDdv } from "reducers/productivity-deep-dive/majorEqDowntimeDdvReducer";
import { fetchSetupClosedownAdherenceDdv } from "reducers/productivity-deep-dive/setupClosedownAdherenceDdvReducer";

interface ProductivityTier1Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const ProductivityTier1: React.FC<ProductivityTier1Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const [filter, setFilter] = useState<any>({})

  const [filteSelect, setFilterSelect] = useState<{ [key: string]: any }>({
    oee3Line: 'All',
    scrap: 'All',
    majorEqDowntime: 'All',
    setupClosedownAdherence: 'All'
  })

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // Reset states when activeKeyDeepDiveTab changes
    setFilter({});
    setFilterSelect({
      oee3Line: 'All',
      scrap: 'All',
      majorEqDowntime: 'All',
      setupClosedownAdherence: 'All'
    });
    const params = {
      workStream: "manufacturing",
      site: "AOH",
      persona: "wct",
      tierNumber: "tier 1",
      line: "All"
    }
    dispatch(fetchOeeThreeDdv(params as any));
    dispatch(fetchnormalScrapDdv(params as any));
    dispatch(fetchmajorEqDowntimeDdv(params as any));
    dispatch(fetchSetupClosedownAdherenceDdv(params as any));
  }, [activeKeyDeepDiveTab, dispatch]);

  const handleMenuClick = (selected: any, filterType: string, filterValue?: string) => {

    setFilterSelect(prev => ({ ...prev, [filterType]: selected.key }));
    const params = {
      workStream: "manufacturing",
      site: "AOH",
      persona: "wct",
      tierNumber: "tier 1",
      filterType,
      filterValue,
      line: selected.key
    }
    dispatch(fetchOeeThreeDdv(params as any));
    dispatch(fetchnormalScrapDdv(params as any));
    dispatch(fetchmajorEqDowntimeDdv(params as any));
    dispatch(fetchSetupClosedownAdherenceDdv(params as any));
  }

  return (
    <div className="productivity-tier-main">
      {data?.map((item: any, index: number | null | undefined) => {
        return (
          <RenderTierWiseComponent
            activeKeyDeepDiveTab={activeKeyDeepDiveTab}
            tierLabel={tierLabel}
            key={index}
            data={item}
            deepDiveTableColumns={ProductivityDdvT1TableColumns}
            // deepDiveFilters={[item?.filterType,item?.extraFilterLabel]}
            handleMenuClick={handleMenuClick}
            extraFilterselect={filteSelect}
            activeFilter={filter}
          />
        )
      })}
    </div>
  );
};
export default ProductivityTier1;