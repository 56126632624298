import React from 'react';
import { Alert, Typography } from 'antd';
import { ExpandArrowsIcon } from "@gitlab-rtsensing/component-library";
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'; // Import the filled error icon

const { Paragraph } = Typography;

interface StyledCardHeaderProps {
  title: string;
  setIsOpenDeepDive?: (isOpen: boolean) => void;
  setActiveKeyDeepDiveTab?: (key: string) => void;
  style?: React.CSSProperties; // Add style prop
  alertType?: 'success' | 'info' | 'warning' | 'error'; // Add alertType prop
  bgColor?: string; // Add bgColor prop
}

const StyledCardHeader: React.FC<StyledCardHeaderProps> = ({ title, setIsOpenDeepDive, setActiveKeyDeepDiveTab, style, alertType = 'success', bgColor = '#F0FFF0' }) => (
  <Alert
    type={alertType}
    style={{ minHeight: '39px', padding: '8px 16px', backgroundColor: bgColor }} // Add padding and background color
    message={<Paragraph style={{ marginBottom: '0em', marginRight: '48px', textWrap: 'wrap', fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}>{title}</Paragraph>}
    showIcon
    icon={
      alertType === 'success' ? <CheckCircleFilled style={{ color: '#2CC84D' }} /> : alertType === 'error' ? <CloseCircleFilled style={{ color: '#E01029' }} /> : undefined
    } // Set custom color for the filled success and error icons
    action={
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ExpandArrowsIcon
          style={style}
          onClick={() => {
            setIsOpenDeepDive?.(true);
            setActiveKeyDeepDiveTab?.(title);
          }}
          display={'flex'}
          height={16}
          width={16}
        />
      </div>
    }
  />
);

export default StyledCardHeader;
