import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { BASE_VIEW, OEE_THREE, METRICS, PRODUCTIVITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface oeeThreeBvInfoState {
  oeeThreeBvInfo: OeeThreeBvPayloadType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: oeeThreeBvInfoState = {
  oeeThreeBvInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, PRODUCTIVITY, BASE_VIEW, OEE_THREE];
const fetchOeeThreeBv = fetchApi(segments);

interface OeeThreeBvPayloadType {
  isCardTag?: boolean;
  isCardTable?: boolean;
  data: { oee?: number | string; cardTag?: string }[] | { cardTag: string };
  tableKey?: string;
  tableValue?: string;
  dataParams?: { alertType: string };
  queryParams?: { tierLabel: string };
}

// Transformation function to process the action.payload
const transformPayload = (payload: OeeThreeBvPayloadType[]) => {
  return payload.map(item => {
    if (item.isCardTable && Array.isArray(item.data)) {
      item.data = item.data.map(row => {
        if (row.oee !== undefined) {
          if (typeof row.oee === 'number') {
            row.oee = `${row.oee}%`;
          } else if (typeof row.oee === 'string') {
            const numericValue = parseFloat(row.oee);
            if (!isNaN(numericValue)) {
              row.oee = `${numericValue}%`;
            }
          }
        }
        return row;
      });
    }
    return item;
  });
};

const oeeThreeBvReducer = createSlice({
  name: 'oeeThreeBv',
  initialState,
  reducers: {
    clearOeeThreeBvData(state) {
      state.oeeThreeBvInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOeeThreeBv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchOeeThreeBv.fulfilled, (state, action) => {
        state.loading = false;
        state.oeeThreeBvInfo = transformPayload(action.payload as OeeThreeBvPayloadType[]); // Apply transformation
      })
      .addCase(fetchOeeThreeBv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

  },
})

export { fetchOeeThreeBv };

export const { clearOeeThreeBvData } = oeeThreeBvReducer.actions

export default oeeThreeBvReducer.reducer;