import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType, BaseViewCardPropsType } from 'types/wctTypes';
import { setData } from 'utils/common-methods';
import { BASE_VIEW, METRICS, OEE_123, PRODUCTIVITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';
interface overallEquipmentEfficiencyAllBvInfoState {
  overallEquipmentEfficiencyAllBvInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: overallEquipmentEfficiencyAllBvInfoState = {
  overallEquipmentEfficiencyAllBvInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, PRODUCTIVITY, BASE_VIEW, OEE_123];
const fetchoverallEquipmentEfficiencyAllBv = fetchApi(segments);

const overallEquipmentEfficiencyAllBvReducer = createSlice({
  name: 'overallEquipmentEfficiencyAllBv',
  initialState,
  reducers: {
    clearoverallEquipmentEfficiencyAllBvData(state) {
      state.overallEquipmentEfficiencyAllBvInfo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchoverallEquipmentEfficiencyAllBv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchoverallEquipmentEfficiencyAllBv.fulfilled, (state, action) => {
        state.loading = false;
        state.overallEquipmentEfficiencyAllBvInfo = action.payload
      })
      .addCase(fetchoverallEquipmentEfficiencyAllBv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

  },
});

export { fetchoverallEquipmentEfficiencyAllBv };

export const { clearoverallEquipmentEfficiencyAllBvData } = overallEquipmentEfficiencyAllBvReducer.actions

export default overallEquipmentEfficiencyAllBvReducer.reducer;