import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Radio } from "@gitlab-rtsensing/component-library";
import { useEffect } from "react";

type TimePeriod = string

interface RadioFiltersProps {
    customLabel?: string;
    timePeriod: TimePeriod;
    setTimePeriod: (value: TimePeriod) => void;
    tierLabel: string;
    timePeriodData?: string[]
    activeKeyDeepDiveTab?: string;
}
export const RadioFilters: React.FC<RadioFiltersProps> = ({
    customLabel,
    timePeriod,
    setTimePeriod,
    tierLabel,
    timePeriodData,
    activeKeyDeepDiveTab
}) => {
    let timePeriods: TimePeriod[] = []
    if (tierLabel === "Tier 2") {
        timePeriods = ["All", "Overdue", "Coming Due", "Extensions", "Open"];
    } else if ((tierLabel === "Tier 3") && (activeKeyDeepDiveTab === "Change Control" ||activeKeyDeepDiveTab === "Major Deviation" || activeKeyDeepDiveTab === "Minor Deviation")) {
        timePeriods = ["All", "Adherence", "Extensions", "Open"];
    } else if ((tierLabel === "Tier 1") && (activeKeyDeepDiveTab === "Minor Deviation")) {
        timePeriods = ["All", "Adherence", "Aging", "Open"];
    } else {
        timePeriods = ["All", "Overdue", "Coming Due", "Extensions", "Open"];
    }
    if (!timePeriods.includes(timePeriod) && !timePeriodData) {
        timePeriod = "All";
    }
    if (timePeriodData) timePeriods = [...timePeriodData]

    return (
        <div className="d-flex" style={{ marginBottom: "10px" }}>
            <div className="ops-fs-5 ops-fw-bold" style={{ marginRight: "15px" }}>
                {customLabel ? customLabel : (timePeriodData?.length ? "FILTER BY" : "TIME PERIOD")}
            </div>
            {timePeriods.map((period) => (
                <div key={period} style={{ marginRight: "8px" }}>
                    <Radio
                        name="timePeriod"
                        value={period}
                        checked={timePeriod === period}
                        onChange={() => setTimePeriod(period)}
                        variant="primary"
                    />
                </div>
            ))}
        </div>
    );
};
