import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResponseDataType, FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { SETUP_CLOSEDOWN_EQ_URL } from 'utils/constants';
import { StringKeyAnyDataProps } from 'utils/data-types';

interface SetupClosedownAdherenceDdvInfoState {
  setupClosedownAdherenceDdvInfo: APIResponseDataType[] | null;
  setupClosedownAdherenceFilterData: StringKeyAnyDataProps[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: SetupClosedownAdherenceDdvInfoState = {
  setupClosedownAdherenceDdvInfo: null,
  setupClosedownAdherenceFilterData: null,
  loading: false,
  error: false,
}

const fetchSetupClosedownAdherenceDdv = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
  SETUP_CLOSEDOWN_EQ_URL,
  async (params) => {
    const filteredParams = filterParams(params);
    const token: string = getOktaToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}${SETUP_CLOSEDOWN_EQ_URL}?${new URLSearchParams(filteredParams).toString()}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch Setup and closedown');
    }
    const data = await response.json();
    data.forEach((filter: any) => {
      if (filter.extraFilterData) {
        filter.extraFilterData = [{ line: 'All' }, ...filter.extraFilterData];
      }
    });
    return data;
  }
);

const fetchSetupClosedownAdherenceFilterData = createAsyncThunk<APIResponseDataType[], FetchDataParams>(
  `${SETUP_CLOSEDOWN_EQ_URL}filter`,
  async (params) => {
    const filteredParams = filterParams(params);
    const token: string = getOktaToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}${SETUP_CLOSEDOWN_EQ_URL}?${new URLSearchParams(filteredParams).toString()}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch  Major Eq Downtime');
    }
    const data = await response.json();

    data.forEach((filter: any) => {
      if (filter.extraFilterData) {
        filter.extraFilterData = [{ line: 'All' }, ...filter.extraFilterData];
      }
    });
    return data;
  }
);

const handleSetupClosedownAdherenceFilterData = (oldState: { setupClosedownAdherenceDdvInfo: any[]; }, filerData: { filterType: any; data: any; }) => {
  const state = oldState.setupClosedownAdherenceDdvInfo
  state?.map((item: { filterType: any; data: any; }) => {
    if (filerData?.filterType === item.filterType) {
      item.data = filerData.data
    }
    return item
  });
  return state

}

const setupClosedownAdherenceDdvReducer = createSlice({
  name: 'setupClosedownAdherenceDdv',
  initialState,
  reducers: {
    clearSetupClosedownAdherenceDdvData(state) {
      state.setupClosedownAdherenceDdvInfo = null
      state.setupClosedownAdherenceFilterData = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSetupClosedownAdherenceDdv.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchSetupClosedownAdherenceDdv.fulfilled, (state, action) => {
        state.loading = false;
        state.setupClosedownAdherenceDdvInfo = action.payload
      })
      .addCase(fetchSetupClosedownAdherenceDdv.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(fetchSetupClosedownAdherenceFilterData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchSetupClosedownAdherenceFilterData.fulfilled, (state, action) => {
        state.loading = false;
        state.setupClosedownAdherenceDdvInfo = handleSetupClosedownAdherenceFilterData(state as any, action.payload as any);
      })
      .addCase(fetchSetupClosedownAdherenceFilterData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
  }
})

export { fetchSetupClosedownAdherenceDdv };

export const { clearSetupClosedownAdherenceDdvData } = setupClosedownAdherenceDdvReducer.actions

export default setupClosedownAdherenceDdvReducer.reducer;