import React from 'react';
import styled from '@emotion/styled';
export interface CalendarProps {
    options: {
        title: string;
        greenDates?: number[];
        redDates?: number[];
        greenColor?: string;
        redColor?: string;
    };
}
function getDaysInCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // getMonth() returns 0-11, so add 1 for 1-12
    return new Date(year, month, 0).getDate();
}
const Calendar: React.FC<CalendarProps> = (props) => {
    const { options: { title, greenDates, redDates, greenColor = '#F0FFF0', redColor = '#FFE7E6' } } = props;
    const daysInMonth = getDaysInCurrentMonth(); // Get the number of days in the current month
    const currentDate = parseInt(`${new Date().getDate()}`)
    const DayDiv = styled.div<{ isGreen: boolean; isRed: boolean; isActive: boolean; isDisabled: boolean; backgroundColor: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    background-color: ${props => props.backgroundColor};
    color: ${props => (props.isGreen || props.isRed) ? '#1F1F1F' : 'inherit'};
    
    ${props => props.isGreen && `
      background-color: ${props.backgroundColor};
    `}
    ${props => props.isRed && `
      background-color: ${props.backgroundColor};
    `}
    ${props => props.isActive && `
      color: #0063C3;
      border: 1px solid #0063C3;
      font-weight: 700;
      background-color: transparent;
    `}
    ${props => props.isDisabled && `
      color: #BFBFBF;
      background-color: transparent;
    `}
    &:hover {
      color: #0063C3;
      border-color: #0063C3;
      cursor: default;
    }
  `;
    const CalendarDiv = styled.div`
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
    `;
    const renderDays = () => {
        const days = [];
        for (let i = 1; i <= daysInMonth; i++) {
            const isGreen = greenDates?.includes(i) ?? false;
            const isRed = redDates?.includes(i) ?? false;
            const isActive = i === currentDate;
            const isDisabled = i > currentDate;
            const backgroundColor = isGreen ? greenColor : (!isGreen && redColor) ? redColor : '';
            days.push(
                <DayDiv
                    key={i}
                    isGreen={isGreen}
                    isRed={isRed}
                    isActive={isActive}
                    isDisabled={isDisabled}
                    backgroundColor={backgroundColor}
                >
                    {i}
                </DayDiv>
            );
        }
        return days;
    };
    return <>
        <>
            <CalendarDiv>
                {renderDays()}
            </CalendarDiv>
        </>
    </>
};
export default Calendar;
