import { createSlice } from '@reduxjs/toolkit';
import { fetchApi } from 'api/fetchApi';
import { APIResponseDataType } from 'types/wctTypes';
import { BASE_VIEW, GXP_TRAINING, METRICS, QUALITY, VERSION, WORK_CENTER_TEAM } from 'utils/constants';

interface GxpTrainingInfoState {
  gmpTrainingInfo: APIResponseDataType[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: GxpTrainingInfoState = {
  gmpTrainingInfo: null,
  loading: false,
  error: false,
}

const segments = [WORK_CENTER_TEAM, METRICS, VERSION, QUALITY, BASE_VIEW, GXP_TRAINING];
const fetchGmpTraining = fetchApi(segments);

const gmpTrainingReducer = createSlice({
    name: 'gmpTraining',
    initialState,
    reducers: {
        clearGmpTrainingData(state) {
            state.gmpTrainingInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGmpTraining.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchGmpTraining.fulfilled, (state, action) => {
                state.loading = false;
                state.gmpTrainingInfo = action.payload;
            })
            .addCase(fetchGmpTraining.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchGmpTraining };

export const { clearGmpTrainingData } = gmpTrainingReducer.actions

export default gmpTrainingReducer.reducer;