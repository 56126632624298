import AohTable from "../../common/table/AohTable";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Delivery.scss";
import { batchDispositionDeepDiveFilter, batchDispositionDeepDiveTableColumns, DataTypeKey, DEEP_DIVE_UI_CONSTANTS, DeliveryAgingTableColumnsType } from "utils/wctConstants";
import { APIResponseDataType, SummaryType, TableType } from "types/wctTypes";
import { downloadCSV, filterbyDataType, findAndRoundMaxValue, generateCSV } from "utils/common-methods";
import { BlockRadioFilters } from "components/resuable/radio-filters/BlockRadioFilters";
import { useContext, useEffect, useState } from "react";
import { clearAgingWoViewByData, fetchAgingWoViewByData } from "reducers/delivery-deep-dive/agingWoDeepDiveReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { StringKeyDataProps } from "utils/data-types";
import ErrorHandler from "utils/error-handler";
import RenderTierWiseComponent from "./RenderTierWiseComponent";
import { fetchBatchDispositionFilterData } from "reducers/delivery-deep-dive/batchDispositionDeepDiveReducer";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { formatDayMonth, formatMonthDay, newMinMaxValue } from "components/common/StackedBar/utils";
import AohDropdown from "components/common/dropdown/aohdropdown";
import { fetchLineInfo } from "reducers/lineInfoReducer";
import { MenuProps } from "antd";
import { HeaderDropDownType } from "page/work-center-team";
import { AppContext } from "context/app-context";
import { clearonTimeScheduleAdherenceDeepDiveData, fetchonTimeScheduleAdherenceDeepDive } from "reducers/delivery-deep-dive/onTimeScheduleAdherenceDeepDiveReducer";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";

interface DeliveryTier1Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const DeliveryTier1: React.FC<DeliveryTier1Props> = ({
    data,
    tierLabel,
    activeKeyDeepDiveTab
}) => {
    const dispatch = useDispatch<AppDispatch>()
  const { appState } = useContext(AppContext);
    const { agingWoViewByData, loading, error } = useSelector((state: RootState) => state.agingWoDeepDive)
    const { lineInfo } = useSelector((state: RootState) => state.lineInfo);
    const [cmFilterType, setCmFilterType] = useState<string>("cm")
    const [scheduledFilterType, setScheduledFilterType] = useState<string>("schedule")
    const [filter, setFilter] = useState<any>({})

    const summary: APIResponseDataType = filterbyDataType<SummaryType>(data, DataTypeKey.IsSummary)[0]
    const allTables: APIResponseDataType[] = filterbyDataType<TableType>(data, DataTypeKey.IsTable)
    const agingWoTable = allTables[0]

    const [tableData, setTableData] = useState<StringKeyDataProps[]>(agingWoTable?.data as StringKeyDataProps[])

    const cmFilter = agingWoTable?.dataParams?.dataFilter.filter((filter: any) => filter.filterType === "cm-gm")?.[0]
    const scheduledFilter = agingWoTable?.dataParams?.dataFilter.filter((filter: any) => filter.filterType === "schedule-unschedule")?.[0]

    const [currentLine, setCurrentLine] = useState(appState.lineInfo ? appState.lineInfo?.[0]?.line : "");

    useEffect(() => {
        // Update currentLine only if lineInfo has lines and currentLine is empty or undefined
        if (appState.lineInfo && (!currentLine || currentLine === "")) {
            setCurrentLine(appState.lineInfo?.[0]?.line);
        }
    }, [appState]);

    const lineDropDownData: HeaderDropDownType[] =
    appState.lineInfo?.map((line: any) => ({
        key: line?.line ?? "",
        label: line?.line ?? "",
    })) ?? [];

    const handleLineClick: MenuProps["onClick"] = (e) => {
        const latestLineInfo = appState.lineInfo?.find(
            (line: any) => line?.line === e?.key
        );
        if (latestLineInfo) {
            setCurrentLine(latestLineInfo.line);
        }
    };
    
    useEffect(() => {
    const newparams = {
        workStream: "manufacturing",
        site: "AOH",
        persona: "wct",
        tierNumber: "tier 1",
        line: currentLine,
    }

     dispatch(fetchonTimeScheduleAdherenceDeepDive(newparams));
    }, [currentLine]);

    const deliveryDeepDive: any = {
        "Aging Work Orders": {
            summaryTitle: DEEP_DIVE_UI_CONSTANTS.SUMMARY,
            summary: (<SummaryCard data={summary?.data} />),
            title: agingWoTable?.dataParams?.title,
            subtitle: agingWoTable?.dataParams?.subtitle,
            blockRadioFilters1: (
                <BlockRadioFilters
                    filterLabel={cmFilter?.filterLabel}
                    radioFilter={cmFilter?.filters}
                    setRadioFilter={setCmFilterType}
                />
            ),
            blockRadioFilters2: (
                <BlockRadioFilters
                    filterLabel={scheduledFilter?.filterLabel}
                    radioFilter={scheduledFilter?.filters}
                    setRadioFilter={setScheduledFilterType}
                />
            ),
            button: (
                <Button
                    className={(!tableData || tableData.length === 0) ? "" : "download-btn"}
                    icon={<DownloadOutlined />}
                    label="Download"
                    iconPosition="right"
                    onClick={() => {
                        if (tableData && DeliveryAgingTableColumnsType) {
                            const csvContent = generateCSV(tableData, DeliveryAgingTableColumnsType);
                            downloadCSV(csvContent, "Aging_work_orders.csv");
                        } else {
                            console.error("No data or columns available for download");
                        }
                    }}
                    type="secondary"
                    disabled={(!tableData || tableData.length === 0) ? true : false}
                />
            ),
            table: <AohTable tableData={tableData} tableColumns={DeliveryAgingTableColumnsType} />,
        },
        "On Time Schedule Adherence": {
            summaryTitle: DEEP_DIVE_UI_CONSTANTS.SUMMARY,
            summary: (<SummaryCard data={data[0]?.data} />),
            title1: "Distribution of units per day",
            subtitle1: "Last week",
            lineDropdown: (
                <AohDropdown
                    items={lineDropDownData}
                    title="LINES"
                    dropOptions={currentLine}
                    handleMenuClick={handleLineClick}
                />
            ),
            verticalChart1: (
                <StackedBarChart
                    data={data[1]?.data}
                    chartType={"on-time-ddv-actual-vs-planned-weekly"}
                    xAxisData={Array.isArray(data[1]?.data) ? (data[1]?.data).map((item: any) => formatMonthDay(item)) : []}
                    yAxisNames={[
                        { name: "UNITS", position: "left", max: Array.isArray(data[1]?.data) ? findAndRoundMaxValue(data[1]?.data, 'actual_units', 'planned_units') : undefined },
                    ]}
                    nameGapCustom={90}
                />
            ),
            subtitle2: "Current week",
            verticalChart2: (
                <StackedBarChart
                    data={data[2]?.data}
                    chartType={"on-time-ddv-actual-vs-planned-weekly-current"}
                    xAxisData={Array.isArray(data[2]?.data) ? (data[2]?.data).map((item: any) => formatMonthDay(item)) : []}
                    yAxisNames={[
                        { name: "UNITS", position: "left", max: Array.isArray(data[2]?.data) ? findAndRoundMaxValue(data[2]?.data, 'actual_units', 'planned_units') : undefined },
                    ]}
                    nameGapCustom={90}
                />
            )
        },
    }

    useEffect(() => {
        const viewBy = `${scheduledFilterType}_${cmFilterType}` === "unschedule_gm" ? "schedule_cm" : `${scheduledFilterType}_${cmFilterType}`
        const params = {
            workStream: "manufacturing",
            site: "AOH",
            persona: "wct",
            tierNumber: "tier 1",
            viewBy
        }

        dispatch(clearAgingWoViewByData())
        dispatch(fetchAgingWoViewByData(params));
    }, [dispatch,cmFilterType, scheduledFilterType]);

    useEffect(() => {
        if (agingWoViewByData) {
            setTableData(agingWoViewByData[0].data as StringKeyDataProps[])
        }
    }, [agingWoViewByData])

    const handleRadioFilter = (filterType: any, nextValues: any) => {
        setFilter((prev: any) => ({ ...prev, [filterType]: nextValues }))
        const params = {
            workStream: "manufacturing",
            site: "AOH",
            persona: "wct",
            tierNumber: "tier 1",
            filterType,
            filterValue: nextValues

        }
        dispatch(fetchBatchDispositionFilterData(params as any));
    }

    if (activeKeyDeepDiveTab === "Batch Disposition") {
        return (<div className="delivery-tier-main">
            {data?.map((
                item: any, index: number | null | undefined) => {
                return <RenderTierWiseComponent setTableType={handleRadioFilter}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    tierLabel={tierLabel} key={index} data={item} activeFilter={filter}
                    deepDiveFilters={batchDispositionDeepDiveFilter}
                    deepDiveTableColumns={batchDispositionDeepDiveTableColumns} />
            })}
        </div>)
    } else {
        return (
            <div className="delivery-tier-main">
                {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle && (
                <div className="tier-container">
                    <h2 className="tier-title">
                        {/* Summary card */}
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                    </h2>
                </div>
                )}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.summary}
                {deliveryDeepDive[activeKeyDeepDiveTab]?.lineDropdown && (
                    <div className="line-dropdown">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.lineDropdown}
                    </div>)}
                <div
                    className={
                        deliveryDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                    }
                ></div>
                <div className="tier-sections-container">
                    {/* section 1 */}
                    <div className="tier-container section section-left">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.title1 && (
                            <h2 className="tier-title">
                                {deliveryDeepDive[activeKeyDeepDiveTab]?.title1}
                            </h2>)}
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle1 && (
                        <div className="tier-subtitle">
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle1}
                        </div>
                        )}
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart1}
                    </div>

                    {/* section 2 */}
                    {(deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart2) && (
                        <div className="tier-container section section-right">
                            <div className="tier-subtitle" style={{ marginTop: "39px" }}>
                                {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle2}
                            </div>
                            {deliveryDeepDive[activeKeyDeepDiveTab]?.verticalChart2}
                        </div>
                    )}
                </div>
                {(deliveryDeepDive[activeKeyDeepDiveTab]?.title) && (
                <div className="tier-container">
                    <h2 className="tier-title">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.title}
                    </h2>
                    {deliveryDeepDive[activeKeyDeepDiveTab]?.button}
                </div>
                )}
                {(deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle) && (
                <div className="tier-subtitle">
                    {deliveryDeepDive[activeKeyDeepDiveTab]?.subtitle}
                </div>
                )}
                {(deliveryDeepDive[activeKeyDeepDiveTab]?.blockRadioFilters1 || deliveryDeepDive[activeKeyDeepDiveTab]?.blockRadioFilters2) && (
                <div className="tier-buttons-container">
                    <div className="tier-buttons-left">
                        {deliveryDeepDive[activeKeyDeepDiveTab]?.blockRadioFilters1}
                    </div>
                    <div className="tier-buttons-right">
                        {cmFilterType !== "gm" && deliveryDeepDive[activeKeyDeepDiveTab]?.blockRadioFilters2}
                    </div>
                </div>
                )}
                {loading || error ? (
                    <div className="loader">
                        <ErrorHandler isLoading={loading} isError={error} loaderColor={'BLUE'} />
                    </div>
                ) : (
                    deliveryDeepDive[activeKeyDeepDiveTab]?.table
                )}
            </div>
        );
    }
};
export default DeliveryTier1;
