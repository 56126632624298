import { excludedKeys } from "components/common/StackedBar/constants";
import { DataItem } from "types/wctTypes";

export const hasDecimalValues = (data: { value: number }[]): boolean => {
  return data.some((item) => typeof item.value === 'number' && item.value % 1 !== 0);
};

export const calculateMaxValue = (data: { value: number }[]): number => {
  return Math.max(
    ...data
      .map((obj) => (obj.value !== undefined ? parseFloat(String(obj.value)) ?? 0 : 0))
      .filter((value): value is number => typeof value === 'number')
  );
};

export const computeYEndLabel = (data: { value: number }[], isDeci: boolean): number => {
  const maxValue = calculateMaxValue(data);
  let yEndLabel = Math.ceil((maxValue ?? 5) / 5) * 5;

  if (yEndLabel === 0) {
    yEndLabel = 5;
  }

  if (isDeci) {
    yEndLabel = 100;
  }

  return yEndLabel;
};

export function formatIntlNumber(num: number): string {
  if (num >= 1e9) {
    // Format as billions (round to nearest billion)
    return Math.floor(num / 1e9).toString() + 'B';
  } else if (num >= 1e6) {
    // Format as millions (round to nearest million)
    return Math.floor(num / 1e6).toString() + 'M';
  } else if (num >= 1e3) {
    // Format as thousands (round to nearest thousand)
    return Math.floor(num / 1e3).toString() + 'K';
  } else {
    // If the number is less than 1000, return it as is
    return num.toString();
  }
}

export function newMinMaxValue(items: DataItem[]) {
    if (!items || items.length === 0) {
        return undefined;
    }

    const numericValues: number[] = items.flatMap(item =>
        Object.entries(item)
            .filter(
                ([key, value]) =>
                    !excludedKeys.includes(key) &&
                    typeof value === 'number' &&
                    value !== null &&
                    value !== undefined
            )
            .reduce((acc, [_, value]) => acc + (value as number), 0)
    )

    const minValue = Math.min(...numericValues);
    let maxValue = Math.max(...numericValues);
    if (numericValues.length === 0) {
        return { Min: 0, Max: 100 };
    } else if (maxValue === 0) {
        return { Min: minValue, Max: 100 }
    }

    maxValue = Math.ceil(maxValue / 5) * 5

    return { Min: minValue, Max: maxValue };
}
